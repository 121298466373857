.my-calendar {
  width: 540px;
  margin-top: 20px;
  margin-bottom: 15px;
}

#isMobile .my-calendar {
  width: 100%;
}

.ctr-box {
  padding: 0 16px;
  margin-bottom: 20px;
  font-size: 20px;
}
.ctr-box .btn-cal {
  margin: 0 31px;
  cursor: pointer;
}
.ctr-box .btn-cal:after {
  content: "<";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 25px;
  font-weight: bold;
  font-size: 20px;
}

.ctr-box .btn-cal.next:after {
  content: ">";
}

.cal-table {
  width: 100%;
}
.cal-table th {
  width: 14.28%;
  font-size: 16px;
  font-weight: 500;
  line-height: 3.13;
  letter-spacing: -0.8px;
  text-align: center;
}

#isMobile .cal-table th,
#isMobile .cal-table td div {
  font-size: 14px;
}

.cal-table td {
  font-size: 16px;
  font-weight: 500;
  line-height: 3.13;
  letter-spacing: -0.8px;
  text-align: center;
}

.cal-table td.day {
  position: relative;
  cursor: pointer;
}

.cal-table th.sunday,
.cal-table td.sunday div {
  color: #ee0000;
}

.cal-table th.saturday,
.cal-table td.saturday div {
  color: #1681e5;
}

.cal-table td.today div {
  background: #056fb8;
  border-radius: 50%;
  color: #fff;
  width: 40px;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cal-table td.today div {
  width: 30px;
  height: 30px;
}

*/ .cal-table td.has-event:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: #ffc107;
}

/* 추가 */

.my-calendar .ctr-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-calendar .ctr-box,
.my-calendar .ctr-box span {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
  color: #000000;
}

.my-calendar .ctr-box,
.my-calendar .ctr-box span {
  font-size: 18px;
}

/* 신규 달력 */

.react-calendar {
  width: 540px;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
}

.react-calendar__navigation {
  margin-bottom: 0;
}

.react-calendar__navigation__arrow {
  font-size: 28px;
  line-height: 0.5;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.react-calendar__navigation__label {
  flex-grow: initial !important;
}

.react-calendar__navigation__label span {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
  color: #000000;
  margin: 0 24px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  font-size: 16px;
  font-weight: 500;
  line-height: 3.13;
  letter-spacing: -0.8px;
  text-align: center;
  text-decoration: none;
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: #757575 !important;
}

.react-calendar__month-view__days__day--weekend:nth-child(7n + 1) abbr {
  color: #ee0000;
}

.react-calendar__month-view__days__day--weekend:nth-child(7n) abbr {
  color: #1681e5;
}

.react-calendar__tile {
  padding: 0.5em 0.5em;
}

.react-calendar__tile abbr {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.8px;
  text-align: center;
  width: 30px;
  height: 30px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__tile--active,
.react-calendar__tile--now {
  background: none;
}

.react-calendar__tile--now abbr {
  border-radius: 50%;
  background: #ffff76;
}

.react-calendar__tile--active abbr {
  border-radius: 50%;
  background: #006edc;
  color: #fff !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: none;
}
