@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
* {
  color: #000000;
}

.main {
  flex: auto;
}

.center_main {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#isMobile .center_main {
  padding: 0 24px;
  max-width: 450px;
  width: 100vw;
  margin: auto;
  /* justify-content: center; */
  padding-bottom: 50px;
}

#isMobileHome,
#isMobile.root_container {
  padding-bottom: 50px;
}

#isMobile #joinAdd.center_main {
  padding: 0;
}

#isMobile .isMobile_joinAdd {
  padding: 0 24px;
}

#isMobile #joinAdd.center_main {
  align-items: flex-start;
}

#isMobile .join_user {
  align-items: flex-start;
}

/* 체크박스 */
.checkbox_wrap input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.checkbox_wrap input[type="checkbox"] + label {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  height: 18px;
}

#isMobile .checkbox_wrap input[type="checkbox"] + label {
  padding-left: 17px;
  font-size: 14px;
}

#isMobile .caution_column .checkbox_wrap input[type="checkbox"] + label {
  font-size: 13px;
}

.checkbox_wrap input[type="checkbox"] + label:before {
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAACaADAAQAAAABAAAABwAAAACzugX6AAAAVUlEQVQYGWNkwAP+//+/ACi9AacSkAIgvgDEAgxAQgFdJYoCkCRQ4AAQz4cpxFAAlGAECgoA6QNAfB6IQcAQiB0YGRk/gHkwAqQQiEH2Q9wAkyCFBgBcVUHfisRtMwAAAABJRU5ErkJggg==) center no-repeat;
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  color: #ffffff;
  text-align: center;
  background-color: #dddddd;
  box-sizing: border-box;
  border-radius: 5px;
}

#isMobile .checkbox_wrap input[type="checkbox"] + label:before,
#isMobile .checkbox_wrap input[type="checkbox"]:checked + label:after {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  top: 5px;
}

.checkbox_wrap input[type="checkbox"]:checked + label:after {
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAHCAYAAADam2dgAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAACaADAAQAAAABAAAABwAAAACzugX6AAAAVUlEQVQYGWNkwAP+//+/ACi9AacSkAIgvgDEAgxAQgFdJYoCkCRQ4AAQz4cpxFAAlGAECgoA6QNAfB6IQcAQiB0YGRk/gHkwAqQQiEH2Q9wAkyCFBgBcVUHfisRtMwAAAABJRU5ErkJggg==) center no-repeat;
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: #0d6fb8;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
}

/* 체크박스 End */

.join_title,
.main_title {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.9px;
  margin-top: 108px;
  text-align: center;
}

#isMobile .join_title,
#isMobile .main_title {
  font-size: 22px;
  margin-top: 0;
  margin-right: auto;
  text-align: left;
}

#isMobile #joinAdd .main_title {
  margin-top: 16px;
}

.sns_login .column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#isMobile .sns_login .column {
  flex-direction: row;
  margin-top: 16px;
}

.sns_login .column img {
  margin-bottom: 10px;
}

#isMobile .sns_login .column img {
  width: 40px;
  margin-bottom: 4px;
}

#isMobile .join_user .sns_login .column img {
  margin-right: 6px;
  margin-bottom: 0;
}

form .column.manage_adress .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login_form .input_name {
  font-size: 18px;
  letter-spacing: -0.45px;
  margin-bottom: 8px;
}

#isMobile .login_form .input_name {
  font-size: 14px;
}

form input {
  width: 400px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.45px;
  padding: 0 24px;
}

#isMobile form input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  padding: 0 16px;
}

form input.wide {
  width: 100%;
}

form .input_column {
  display: flex;
}

#isMobile form .input_column {
  width: 100%;
}

form .check_btn,
.add_content .check_btn {
  width: 100px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #056fb8;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #056fb8;
  cursor: pointer;
  margin-left: 10px;
}

#isMobile form .check_btn,
#isMobile .add_content .check_btn {
  width: 80px;
  height: 40px;
  font-size: 13px;
  border-radius: 4px;
}
#isMobile #findPw form .check_btn,
#isMobile #findId form .check_btn {
  width: 100px;
  min-width: 100px;
}

#isMobile #mypageAddress .add_content .check_btn {
  width: 100px;
  height: 40px;
}

form .warning {
  font-size: 14px;
  letter-spacing: -0.35px;
  color: #e30d0d;
  height: 14px;
  margin: 4px 0;
}

#isMobile form .warning {
  font-size: 12px;
  height: auto;
}

#isMobile form .caution_column .warning {
  margin-bottom: 0;
}

.login_form .warning.pass {
  color: #0d6fb8;
}

.login_form .warning.age {
  margin-bottom: 6px;
}

form .info {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.35px;
}

#isMobile form .info {
  font-size: 12px;
}

#isMobile #mypageModify form .info {
  font-size: 11px;
}

/* radio 커스텀 */
.radio_wrap input[type="radio"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.radio_wrap input[type="radio"] + label {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  height: 20px;
}

#isMobile .radio_wrap input[type="radio"] + label {
  padding-left: 18px;
}

#isMobile #mypageModify .radio_wrap input[type="radio"] + label {
  font-size: 14px;
}

.radio_wrap input[type="radio"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  background: #fff;
  border: 7px solid #dddddd;
  box-sizing: border-box;
}

#isMobile .radio_wrap input[type="radio"] + label:before,
#isMobile .radio_wrap input[type="radio"]:checked + label:after {
  width: 12px;
  height: 12px;
  border-width: 4px;
  top: 4px;
}

#isMobile #mypageModify .radio_wrap input[type="radio"] + label:before,
#isMobile #mypageModify .radio_wrap input[type="radio"]:checked + label:after {
  top: 1px;
}

.radio_wrap input[type="radio"]:checked + label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #fff;
  border: 7px solid #056fb8;
  box-sizing: border-box;
}

/* ON/OFF 스위치 */
.column__on-off {
  width: 300px;
  text-align: left;
}
.column__on-off {
  width: auto;
}

.input__on-off {
  display: none;
}

.label__on-off {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
  border-radius: 13px;
  background-color: #999999;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s;
}

#isMobile .label__on-off {
  height: 22px;
}

#isMobile .label__on-off .marble {
  width: 20px;
  height: 20px;
}

#isMobile .label__on-off .off,
#isMobile .label__on-off .on {
  line-height: 22px;
}

#isMobile .input__on-off:checked + .label__on-off .marble {
  left: 39px;
}

.label__on-off > * {
  vertical-align: middle;
  transition: all 0.3s;
  font-size: 14px;
}
.label__on-off .marble {
  position: absolute;
  top: 1px;
  left: 1px;
  display: block;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.label__on-off .on {
  display: none;
  padding-left: 9px;
  line-height: 23px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: #ffffff;
}
.label__on-off .off {
  padding-left: 29px;
  line-height: 23px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: #ffffff;
}
.input__on-off:checked + .label__on-off {
  background-color: #056fb8;
}
.input__on-off:checked + .label__on-off .on {
  display: inline-block;
}
.input__on-off:checked + .label__on-off .off {
  display: none;
}
.input__on-off:checked + .label__on-off .marble {
  left: 37px;
}

.blue_btn,
.next_btn,
.red_btn {
  width: 160px;
  height: 50px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile .blue_btn,
#isMobile .next_btn,
#isMobile .red_btn {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-size: 15px;
}

#isMobile #mypageAddress .blue_btn {
  width: 155px;
}

#isMobile .counseling_write_form {
  margin-top: 24px;
}

#isMobile .counseling_write_form .blue_btn {
  width: 160px;
}

#isMobile .modal_ct .blue_btn,
#isMobile .modal_ct .next_btn,
#isMobile .modal_ct .red_btn {
  width: 100px;
}

.blue_btn {
  letter-spacing: -0.9px;
}

.next_btn {
  background-color: #cccccc;
  letter-spacing: -0.45px;
  margin: auto;
}

.next_btn.active,
.blue_btn {
  background-color: #0d6fb8 !important;
}

.red_btn {
  background-color: #d61616 !important;
}

.go_btn_column .go_btn {
  width: 220px;
  height: 50px;
  border-radius: 25px;
  background-color: #003867;
  font-family: "NanumSquare";
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.9px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px 0 28px;
}

#isMobile .go_btn_column .go_btn {
  width: 113px;
  height: 33px;
  font-size: 12px;
  padding: 0 10px 0 18px;
  margin-right: 20px;
}

#isMobile .go_btn_column .go_btn img {
  height: 11px;
}

#isMobileHome .go_btn_column .go_btn {
  width: 140px;
  height: 30px;
  font-size: 13px;
  padding: 0 10px 0 16px;
}

#isMobileHome .go_btn_column .go_btn img {
  width: 5px;
}

#map {
  width: 600px;
  min-width: 500px;
  max-width: 600px;
  width: 40vw;
  text-align: center;
}

#map.addr_list {
  width: 300px;
  height: 300px;
  min-width: 300px;
  max-width: 300px;
}

#isMobile #map {
  min-width: auto;
  max-width: initial;
  width: auto;
}

#isMobile #map.addr_list {
  width: 100%;
}

.ie_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#isMobile .checkbox_wrap {
  position: relative;
}

.isMobile_bottom_nav {
  position: fixed;
  background-color: #ffffff;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #dddddd;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.isMobile_bottom_nav img {
  height: 18px;
}

.isMobile_bottom_menu {
  width: 25%;
  max-width: 100px;
}

.isMobile_bottom_menu a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.isMobile_bottom_menu_name {
  font-size: 12px;
  margin-top: 4px;
  color: #999999;
}

.isMobile_bottom_menu_name.active {
  color: #0d6fb8;
  font-weight: bold;
}

#unauthorized {

}

#unauthorized .horizontal_line{
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.categoryArea {
  display: flex;
  justify-content: center;
}

.categoryArea .category {
  background: #fafafa;
  border-bottom: 4px solid #ffffff;
  font-size: 20px;
  padding: 20px 30px;
  font-weight: 500;
  cursor: pointer;
}

.categoryArea .on {
  color:#fff;
  background: #056fb8;
  border-bottom: 4px solid #056fb8;
}

.categoryArea .category:hover {
  border-bottom: 4px solid #056fb8;
}

.categoryAreaM {
  display: flex;
  justify-content: center;

}

.categoryAreaM .category {
  background: #fafafa;
  border-bottom: 4px solid #ffffff;
  font-size: 16px;
  padding: 18px 30px;
  font-weight: 500;
  cursor: pointer;
}

.categoryAreaM .category:hover {
  border-bottom: 4px solid #056fb8;
}

.categoryAreaM .on {
  color:#fff;
  background: #056fb8;
  border-bottom: 4px solid #056fb8;
}

.password_input {
  width: 350px;
  height: 50px;
  margin-top: 25px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.45px;
  padding: 0 24px;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@font-face {
  font-family: "NanumSquare";
  font-weight: 300;
  src: url(/static/media/NanumSquareL.5c6a24ab.eot);
  src: url(/static/media/NanumSquareL.5c6a24ab.eot?#iefix) format("embedded-opentype"),
    url(/static/media/NanumSquareL.3f3fd18b.woff) format("woff"),
    url(/static/media/NanumSquareL.ace03365.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquare";
  font-weight: 400;
  src: url(/static/media/NanumSquareR.a113ef7b.eot);
  src: url(/static/media/NanumSquareR.a113ef7b.eot?#iefix) format("embedded-opentype"),
    url(/static/media/NanumSquareR.94ee92ff.woff) format("woff"),
    url(/static/media/NanumSquareR.924c33f6.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquare";
  font-weight: 700;
  src: url(/static/media/NanumSquareB.6e5f5d75.eot);
  src: url(/static/media/NanumSquareB.6e5f5d75.eot?#iefix) format("embedded-opentype"),
    url(/static/media/NanumSquareB.5d53333f.woff) format("woff"),
    url(/static/media/NanumSquareB.ebe0aa17.ttf) format("truetype");
}

@font-face {
  font-family: "NanumSquare";
  font-weight: 800;
  src: url(/static/media/NanumSquareEB.87e8b5f9.eot);
  src: url(/static/media/NanumSquareEB.87e8b5f9.eot?#iefix) format("embedded-opentype"),
    url(/static/media/NanumSquareEB.7f267188.woff) format("woff"),
    url(/static/media/NanumSquareEB.e5471443.ttf) format("truetype");
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  font-family: "Noto Sans KR", sans-serif;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  margin: 0;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: #000000;
  text-decoration: none;
  outline: none;
}

input {
  border: none;
  background: none;
}

input:focus {
  outline: none;
}

button {
  border: none;
  background: none;
}

.btn {
  cursor: pointer;
}

select,
textarea {
  font-family: "Noto Sans KR", sans-serif;
  outline: none;
}

textarea {
  resize: none;
  border: solid 1px #dddddd;
  border-radius: 6px;
  box-sizing: border-box;
}

select {
  -webkit-appearance: none; /* for chrome */ /*for firefox*/
  appearance: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAYAAADwdn+XAAAABHNCSVQICAgIfAhkiAAAAOJJREFUKFNj1NTUnPL79++qO3fufGIgAWhras65ev16CiNQD6OWpuZzBkZGs2vXrj0ixgwtLa1N////n3/9+vX1IAPAAGjIjT9//4bfunXrIj5DtDQ0jvxnZGwAat4DUgc3AGrI8X///1fcuHHjIDZDgJZc+c/AEAfUfA4mj2IA2BANje1AG2YCFW1ANgSo+envP39sb9++fQ9ZHMMAkCQwYJcx/vu3+9rNm/Pl5OQEebm5n37++lX60aNH79FdhtUAqCFTgQG1j4mRcdK169elcYULTgNAGjQ0NNqB4VGJL1ABvRReCRJb2cEAAAAASUVORK5CYII=) no-repeat 264px 50%;
  cursor: pointer;
  padding: 0 45px 0 26px;
}

#isMobile select {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAYAAADwdn+XAAAABHNCSVQICAgIfAhkiAAAAOJJREFUKFNj1NTUnPL79++qO3fufGIgAWhras65ev16CiNQD6OWpuZzBkZGs2vXrj0ixgwtLa1N////n3/9+vX1IAPAAGjIjT9//4bfunXrIj5DtDQ0jvxnZGwAat4DUgc3AGrI8X///1fcuHHjIDZDgJZc+c/AEAfUfA4mj2IA2BANje1AG2YCFW1ANgSo+envP39sb9++fQ9ZHMMAkCQwYJcx/vu3+9rNm/Pl5OQEebm5n37++lX60aNH79FdhtUAqCFTgQG1j4mRcdK169elcYULTgNAGjQ0NNqB4VGJL1ABvRReCRJb2cEAAAAASUVORK5CYII=) no-repeat 95% 50%;
  border-radius: 4px;
}

select::-ms-expand {
  display: none; /*for IE10,11*/
}

div,
span {
  word-break: keep-all;
}

td,
th {
  padding: 0 6px !important;
}

/* .txt_line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

/* html,
body {
  height: 100%;
  max-width: 1920px;
  margin: auto;
  min-width: 1430px;
  overflow-x: auto;
}

#root {
 
} */

.root_container {
  max-width: 1920px;
  min-width: 1430px;
  min-height: 100vh;
  height: 100%;
  margin: auto;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

#isMobile.root_container {
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
  max-width: 100vw;
  min-width: 100vw;
}

#isMobile input {
  -webkit-appearance: none;
}

#isMobileHome.root_container {
  width: 100vw;
  height: auto;
  overflow-x: hidden;
  max-width: 100vw;
  min-width: 100vw;
}

.search_wrapper {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

.search.section_1 {
  width: 62.4%;
  display: flex;
  padding: 45px 0;
}

#isMobile .search.section_1 {
  width: 100%;
  padding: 20px;
  padding-bottom: 0 !important;
}

#isMobileHome .search.section_1 {
  width: 100%;
  flex-direction: column;
  padding: 20px 20px;
  max-width: 450px;
}

#isMobile #searchResult.center_main {
  padding: 0 0 60px;
}

#searchResult .search.section_1 {
  justify-content: center;
  padding-bottom: 19px;
}

.search .search_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
  height: 70px;
  border-radius: 35px;
  border: solid 4px #056fb8;
  padding-right: 34px;
}

#isMobile .search .search_column {
  height: 40px;
  width: 100%;
  padding-right: 10px;
}

#isMobileHome .search .search_column {
  width: 100%;
  height: 40px;
  border-radius: 35px;
  border: solid 2px #056fb8;
  padding-right: 14px;
}

.search .search_column input {
  width: 90%;
  font-size: 20px;
  letter-spacing: -0.5px;
  padding: 0 40px;
}

#isMobileHome .search .search_column input,
#isMobile .search .search_column input {
  font-size: 14px;
  padding: 0 14px;
}

#home {
  background-color: #fff;
  overflow-x: hidden;
}

#home .search_word_column {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.9px;
  margin-left: 26px;
}

#isMobileHome #home .search_word_column {
  font-size: 13px;
  margin-left: 16px;
  margin-top: 4px;
  display: flex;
}

#isMobileHome #home .search_word_column .search_word_desc {
  font-size: 12px;
}

#home .search_word_title {
  font-weight: bold;
}

#isMobileHome #home .search_word_title {
  margin-right: 8px;
}

#home .search_word_desc {
  word-break: keep-all;
}

#home .section_2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobileHome #home .section_2 {
  width: 100vw;
}

#home .slide_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobileHome #home .slide_container {
  width: 100%;
  padding: 0;
}

#home .slide_prev {
  margin-right: 86px;
  cursor: pointer;
}

#isMobileHome #home .slide_prev,
#isMobileHome #home .slide_next {
  display: none;
}

#home .slide_next {
  margin-left: 86px;
  cursor: pointer;
}

#home .slide_column {
  width: 100%;
  height: 100%;
  padding-top: 78px;
  position: relative;
}

#isMobileHome #home .slide_column {
  padding-top: 27px;
  max-width: 450px;
  margin: auto;
}

#home .slide_column h3 {
  font-family: "NanumSquare";
  font-weight: 400;
  font-size: 24px;
  line-height: 1.42;
  letter-spacing: -1.2px;
  color: #ffffff;
}

#isMobileHome #home .slide_column h3 {
  font-size: 14px;
}

#home .slide_column h2 {
  font-family: "NanumSquare";
  font-weight: 800;
  font-size: 40px;
  line-height: 0.85;
  letter-spacing: -2px;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 33px;
}

#isMobileHome #home .slide_column h2 {
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 24px;
}

#home .go_btn_column {
  display: flex;
  align-items: center;
}

#isMobileHome #home .go_btn_column {
  flex-direction: column;
  align-items: flex-start;
}

#home .go_btn_column .go_btn_info {
  line-height: 1.75;
  letter-spacing: -0.4px;
  color: #ffffff;
  margin-left: 14px;
}

#isMobileHome #home .go_btn_column .go_btn_info {
  font-size: 10px;
  margin-left: 14px;
}

#home .page_column {
  position: absolute;
  bottom: 28px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  height: 30px;
  padding: 0 16px;
  border-radius: 15px;
  background-color: #286fa8;
}

#isMobileHome #home .page_column {
  position: absolute;
  bottom: 16px;
  left: 100%;
  transform: translate(-100%, 0);
  width: 60px;
  height: 20px;
  padding: 0 6px;
}

#isMobileHome #home .event_column .page_column {
  left: 95%;
  transform: translate(-95%, 0);
}

#home .page_column_move img {
  cursor: pointer;
}

#isMobileHome #home .page_column_move img {
  height: 8px;
}

#home .page_column_num {
  font-size: 14px;
  letter-spacing: -0.35px;
  color: #ffffff;
}

#isMobileHome #home .page_column_num {
  font-size: 8px;
  margin-left: 4px;
}

#home .slide_img_1 {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-100%, -50%);
}

#isMobileHome #home .slide_img_1 {
  width: 130px;
}

#home .section_3 {
  width: 62.4%;
  padding: 56px 0 0 0;
}

#isMobileHome #home .section_3 {
  width: 100%;
  padding: 18px 0 37px 0;
  max-width: 450px;
}

#home .section_3 .service_title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
  margin-bottom: 14px;
}

#isMobileHome #home .section_3 .service_title {
  font-size: 30px;
  padding: 0 20px;
}

#home .section_3 .service_column h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
  margin-top: 56px;
  margin-bottom: 14px;
}

#home .section_4 {
  width: 62.4%;
  padding: 56px 0 0 0;
}

#isMobileHome #home .section_4 {
  width: 100%;
  padding: 18px 0 37px 0;
  max-width: 450px;
}

#home .section_4 .service_title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
  margin-bottom: 14px;
}

#isMobileHome #home .section_4 .service_title {
  font-size: 30px;
  padding: 0 20px;
}

#home .section_4 .service_column h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
  margin-top: 56px;
  margin-bottom: 14px;
}

#home .service_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#isMobileHome #home .service_container {
  flex-direction: column;
  padding: 0 20px;
}

#home .service_column {
  width: 380px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: normal;
}

#home .service_column2 {
  width: 33.33333333%;
  flex: 0 0 auto;
}

#home .service_column2 img {
  width: 100%;
}

#home .service_column3 {
  width: 50%;
  flex: 0 0 auto; 
}

#home .service_column3 iframe {
  width: 100%;
  height: 370px;
}

#home .column-50 {
  flex: 0 0 auto;
  width: 50%;
}
#home .column-25 {
  flex: 0 0 auto;
  width: 25%;
}

#home .column-25 img {
  width: 100%;
}

#home .column-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#isMobileHome #home .isMobile_service_column a {
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: space-around;
}

#isMobileHome #home .isMobile_service_column2 {
  width: 100%;
}

#isMobileHome #home .isMobile_service_column2 img {
  width: 100%;
}

#home .service_column:not(:last-child) {
  margin-right: 16px;
}

#isMobileHome #home .service_column:not(:last-child) {
  margin-bottom: 9px;
}

#home .service_icon {
  margin-top: 48px;
  margin-bottom: 20px;
  height: 73px;
}

#isMobileHome #home .service_icon {
  margin: 0;
  height: auto;
}

#isMobileHome #home .service_icon img {
  width: 40px;
}

#home .service_column h3 {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: -0.65px;
  margin: 22px 0;
  text-align: center;
}

#isMobileHome #home .service_column h3 {
  font-size: 16px;
  margin: 0;
  margin-bottom: 4px;
}

#home .service_column .service_desc {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.45px;
  text-align: center;
}

#isMobileHome #home .service_column .service_desc {
  font-size: 13px;
  text-align: left;
}

#home .service_column .go_btn,
#home .notice_column .go_btn,
.recent_notice .go_btn {
  width: 140px;
  height: 40px;
  padding: 0 16px 0 24px;
  border-radius: 20px;
  border: solid 1px #dddddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;
  margin-bottom: 39px;
}

#isMobileHome #home .service_column .go_btn,
#isMobileHome .recent_notice .go_btn {
  width: auto;
  border: none;
  margin: 0;
}

#isMobileHome #home .service_column .go_btn span,
#isMobileHome #home .notice_column .go_btn span,
#isMobileHome .recent_notice .go_btn span {
  display: none;
}

#home .event_column {
  width: 100%;
  height: 240px;
  background: url(/static/media/event_bg.c95c5dde.png) no-repeat;
  background-size: 100% 100%;
  margin-top: 40px;
  padding: 40px 70px 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

#isMobileHome #home .event_column {
  height: 120px;
  padding: 18px 20px 10px;
  margin-top: 20px;
}

#home .event_column h3 {
  font-family: "NanumSquare";
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: -1.2px;
  color: #ffffff;
}

#isMobileHome #home .event_column h3 {
  font-size: 12px;
}

#home .event_column h2 {
  font-family: "NanumSquare";
  font-size: 40px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -2px;
  color: #ffffff;
}

#isMobileHome #home .event_column h2 {
  font-size: 16px;
}

#home .event_column .go_btn {
  background-color: #ffffff;
  color: #000000;
}

#home .event_column .page_column {
  bottom: 18px;
  background-color: #0e0b08;
}

#home .notice_column {
  margin-top: 49px;
}

#home .notice_left_wrapper {
  display: flex;
  justify-content: space-between;
}

#isMobileHome #home .notice_column {
  padding: 0 20px;
  margin-top: 21px;
}

#home .notice_column .go_btn {
  margin: 0;
}

#isMobileHome #home .notice_column .go_btn {
  margin: 0;
  width: 80px;
  height: 26px;
  font-size: 12px;
  padding: 0 8px 0 10px;
}

#isMobileHome #home .notice_column .go_btn img {
  width: 6px;
}

#home .notice_column .notice_title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: -0.5px;
  color: #999999;
}

#isMobileHome #home .notice_column .notice_title {
  font-size: 14px;
}

#home .notice_column .notice_title img {
  margin-right: 6px;
}

#isMobileHome #home .notice_column .notice_title img {
  width: 18px;
}

#home .notice_info {
  font-size: 18px;
  line-height: 2;
  letter-spacing: -0.45px;
  margin-top: 7px;
}

#home .notice_info span {
  color: #797979;
}

#home .notice_info .btn-pointer {
  cursor: pointer;
}

#isMobileHome #home .notice_info {
  font-size: 14px;
  line-height: 1;
}

#isMobileHome #home .notice_info span {
  display: block;
  color: #797979;
}

.condition_column {
  min-width: 200px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #056fb8;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  position: relative;
  cursor: pointer;
}

#isMobile .condition_column {
  min-width: 86px;
  height: 40px;
}

.condition_column.show {
  border-bottom-left-radius: 0;
}

.condition_column .select {
  font-size: 20px;
  line-height: 3;
  letter-spacing: -0.5px;
  color: #ffffff;
  width: 100%;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile .condition_column .select {
  font-size: 13px;
  padding-left: 4px;
  padding: 0 0 0 10px;
}

.condition_column .condition_lists {
  position: absolute;
  background-color: #056fb8;
  top: 70px;
  left: 0;
  width: 200px;
  display: none;
}

#isMobile .condition_column .condition_lists {
  width: 86px;
  top: 40px;
  padding-left: 10px;
}

.condition_column .condition_lists.show {
  display: block;
}

.condition_column .condition_lists li {
  font-size: 20px;
  line-height: 2.5;
  letter-spacing: -0.5px;
  color: #ffffff;
  padding-left: 32px;
}

#isMobile .condition_column .condition_lists li {
  font-size: 14px;
  padding-left: 4px;
}

.condition_column .condition_lists li:hover {
  background-color: #0b5a90;
}

.condition_column img {
  margin-left: 26px;
}

#isMobile .condition_column img {
  margin-left: 6px;
  width: 12px;
}

#searchResult .search .search_column {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

#isMobile #searchResult .search .search_column img {
  width: 18px;
}

#searchResult .search_word_column {
  display: flex;
  align-items: center;
}

#searchResult .search_word_title {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.9px;
  font-weight: bold;
}

#isMobile #searchResult .search_word_title,
#isMobile #searchResult .search_word_desc {
  font-size: 11px;
}

#searchResult .search_word_desc {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.9px;
}

#searchResult .search_word_column .bar {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.9px;
  font-weight: bold;
  color: #999999;
}

.tab_column {
  width: 100%;
  height: 80px;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  margin: 39px 0 43px;
}

#isMobile .tab_column {
  border: none;
  margin-top: 24px;
  margin: 24px 0 30px;
}

.tab_column ul {
  width: 62.4%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

#isMobile .tab_column ul {
  width: 100%;
  flex-wrap: wrap;
}

.tab_column ul li {
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 6px solid transparent;
  font-size: 20px;
  line-height: 1.75;
  letter-spacing: -1px;
  color: #999999;
  cursor: pointer;
}

#isMobile .tab_column ul li {
  font-size: 14px;
  width: 33.33vw;
  max-width: 150px;
  height: 50%;
  justify-content: center;
  border: 1px solid #ffffff;
  background-color: #eeeeee;
}

.tab_column ul li.active {
  border-bottom: 6px solid #056fb8;
  color: #056fb8;
}

#isMobile .tab_column ul li.active {
  background-color: #0d6fb8;
  color: #ffffff;
}

.search_result_column {
  width: 62.4%;
}

#isMobile .search_result_column {
  width: 100%;
  padding: 0 20px;
}

.total_search_coumn {
  font-size: 18px;
  letter-spacing: -0.45px;
  color: #000000;
}

#isMobile .total_search_coumn {
  font-size: 13px;
}

.total_search_coumn strong {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
}

#isMobile .total_search_coumn strong {
  font-size: 16px;
}

.sub_result_column h2 {
  font-size: 24px;
  line-height: 1.75;
  letter-spacing: -0.6px;
  display: flex;
}

#isMobile .sub_result_column h2 {
  font-size: 13px;
}

.sub_result_column {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  padding: 40px 0 42px;
  margin: 20px 0 41px;
}

#isMobile .sub_result_column {
  margin: 10px 0 41px;
  padding: 24px 0 24px;
}

.sub_result_column .count {
  font-weight: 500;
}

#searchResult .pagenation {
  margin-bottom: 160px;
}

.sub_result_column li {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 24px 0;
}

#isMobile .sub_result_column li {
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
}

.sub_result_column .link {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
  color: #056fb8;
  text-decoration: underline;
  margin-right: 18px;
}

#isMobile .sub_result_column .link {
  font-size: 14px;
}

.sub_result_column .link_info {
  display: flex;
}

.sub_result_column .link_info div {
  font-size: 16px;
  letter-spacing: -0.4px;
  color: #666666;
}

#isMobile .sub_result_column .link_info div {
  font-size: 11px;
  margin-top: 8px;
}

.sub_result_column .link_info .bar {
  height: 16px;
  border-right: solid 1px #707070;
  margin: 0 8px;
}

.login_section_2 {
  width: 100%;
  background: linear-gradient(to top, #fafafa 70%, #ffffff 30%);
}

#isMobileHome .login_section_2 {
  padding: 0 20px;
}

.slide_section {
  width: 62.4%;
  margin: auto;
  border: solid 1px #dddddd;
  padding: 49px 20px 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}

#isMobileHome .slide_section {
  width: 100%;
  max-width: 450px;
  padding: 15px 20px 8px;
}

#isMobileHome .isMobile_prev,
#isMobileHome .isMobile_next {
  display: none;
}

.slide_content {
  width: 100%;
  margin: 0 46px 50px;
}

#isMobileHome .slide_content {
  margin: 0 0 30px;
}

.slide_top_content {
  display: flex;
  justify-content: space-between;
}

#isMobileHome .slide_top_content {
  flex-direction: column;
}

.login_section_2 .slide_top_content .left {
  height: 340px;
  display: flex;
  flex-direction: column;
}

#isMobileHome .login_section_2 .slide_top_content .left {
  height: auto;
}

.login_section_2 .slide_top_content .right .slider_map {
  max-width: 560px;
  width: 30vw;
  min-width: 455px;
  height: 360px;
}

#isMobileHome .login_section_2 .slide_top_content .right .slider_map {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 200px;
}

.slide_top_content .title {
  font-size: 30px;
  letter-spacing: -0.75px;
  text-align: left;
  margin: 21px 0 25px;
}

#isMobileHome .slide_top_content .title {
  font-size: 18px;
  text-align: left;
  margin: 16px 0 8px;
}

#isMobileHome .slide_top_content .title img {
  width: 12px;
}

.slide_top_content strong {
  font-weight: bold;
  margin-left: 11px;
}

#isMobileHome .slide_top_content .isMobile_strong {
  margin-left: 6px;
}

.address_name {
  display: flex;
  align-items: center;
}

.address_name span {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -1.2px;
  background: linear-gradient(to top, #8eebef 50%, transparent 50%);
}

#isMobileHome .address_name span {
  font-size: 18px;
}

.admin_state {
  width: 110px;
  height: 24px;
  border-radius: 12px;
  background-color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

#isMobileHome .admin_state {
  width: 70px;
  height: 15px;
  font-size: 10px;
  -webkit-transform: scale(0.9);
}

.slide_top_content .address {
  font-size: 24px;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -1.2px;
  margin: 7px 0 13px;
}

#isMobileHome .slide_top_content .address {
  font-size: 14px;
  margin: 5px 0 0;
}

#isMobileHome .slide_top_content .address div {
  display: inline-block;
  margin-right: 4px;
}

.serial_number {
  font-size: 20px;
  line-height: 1.75;
  letter-spacing: -1px;
  color: #999999;
  text-align: left;
}

#isMobileHome .serial_number {
  font-size: 12px;
}

.slide_top_content .btn_column {
  display: flex;
  margin-top: auto;
}

#isMobileHome .slide_top_content .btn_column {
  margin: 8px 0 16px;
}

.slide_top_content .set_btn,
.slide_top_content .add_btn {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobileHome .slide_top_content .set_btn,
#isMobileHome .slide_top_content .add_btn {
  width: 66px;
  height: 30px;
  font-size: 12px;
}

#isMobileHome .slide_top_content .add_btn {
  width: 80px;
}

.slide_top_content .add_btn {
  width: 120px;
  margin-left: 10px;
}

.slide_top_content .set_btn img,
.slide_top_content .add_btn img {
  margin-right: 10px;
}

#isMobileHome .slide_top_content .set_btn img,
#isMobileHome .slide_top_content .add_btn img {
  width: 13px;
  margin-right: 6px;
}

.slide_pagenation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 59px;
}

#isMobileHome .slide_pagenation {
  margin-top: 24px;
}

.slide_pagenation div {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #dddddd;
}

#isMobileHome .slide_pagenation div {
  width: 4px;
  height: 4px;
}

.slide_pagenation div:not(:last-child) {
  margin-right: 20px;
}

#isMobileHome .slide_pagenation div:not(:last-child) {
  margin-right: 10px;
}

.slide_pagenation .active {
  width: 60px;
  border-radius: 4px;
  background-color: #056fb8;
}

#isMobileHome .slide_pagenation .active {
  width: 30px;
  height: 4px;
}

.day_column {
  width: 100%;
  margin-top: 20px;
}

#isMobileHome .day_column {
  padding: 0 20px;
}

.day_column .day_text {
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: -1.2px;
}

#isMobileHome .day_column .day_text {
  font-size: 15px;
}

.day_column .day_text strong {
  font-weight: bold;
  margin-left: 0;
  word-break: normal;
}

.day_column .total_per {
  max-width: 500px;
  width: 26vw;
  height: 50px;
  border-radius: 25px;
  background-color: #dddddd;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#isMobileHome .day_column .total_per {
  max-width: 100%;
  width: 100%;
  height: 26px;
  margin-top: 20px;
}

.day_column .remain_per {
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
  height: 50px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -1.2px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day_column .total_per span {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.9px;
  color: #999999;
}

.day_column .pass_per {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 30px;
  height: 50px;
  border-radius: 25px;
  background-image: linear-gradient(to right, #1c8ee7, #056fb8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -1.2px;
  color: #ffffff;
}

#isMobileHome .day_column .pass_per {
  min-width: 20px;
}

#isMobileHome .day_column .pass_per,
#isMobileHome .day_column .remain_per {
  height: 26px;
  font-size: 16px;
}

.day_column .pass_per span {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.9px;
  color: #ffffff;
}

#isMobileHome .day_column .pass_per span,
#isMobileHome .day_column .total_per span {
  font-size: 12px;
}

.isMobil_recent_notice {
  width: 100%;
}

#isMobileHome .isMobil_recent_notice {
  padding: 0 20px;
}

.recent_notice {
  width: 62.4%;
  background-color: #ffffff;
  border: solid 1px #dddddd;
  margin: 20px auto 0;
  padding: 32px 40px 44px 53px;
}

#isMobileHome .recent_notice {
  width: 100%;
  max-width: 450px;
  padding: 20px 12px 20px 20px;
}

.recent_notice .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recent_notice .header h2 {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.6px;
}

#isMobileHome .recent_notice .header h2 {
  font-size: 17px;
}

.recent_notice .go_btn {
  margin: 0;
}

#isMobileHome .recent_notice .go_btn {
  display: none;
}

.recent_notice_lists {
  width: 100%;
  height: 180px;
  overflow-y: auto;
  margin-top: 38px;
}

#isMobileHome .recent_notice_lists {
  height: 125px;
  margin-top: 24px;
}

.recent_notice_lists .list_column {
  display: flex;
  align-items: center;
}

.recent_notice_lists .list_dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #dddddd;
  margin-right: 18px;
}

#isMobileHome .recent_notice_lists .list_dot {
  width: 8px;
  height: 8px;
}

.recent_notice_lists .active .list_dot {
  background-color: #056fb8;
}

.recent_notice_lists .list_desc {
  font-size: 18px;

  letter-spacing: -0.45px;
  font-weight: normal;
  color: #cccccc;
}

#isMobileHome .recent_notice_lists .list_desc {
  font-size: 13px;
}

.recent_notice_lists .active .list_desc {
  font-weight: 500;
  color: #000000;
}

.recent_notice_lists .bar {
  width: 2px;
  height: 18px;
  background-color: #dddddd;
  margin: 3px 0 3px 5px;
}

#isMobileHome .recent_notice_lists .bar {
  height: 13px;
  margin: 1px 0 1px 3px;
}

.recent_notice_lists .bar.active {
  background-color: #056fb8;
}

/* 스크롤바 */
.recent_notice ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

#isMobileHome .recent_notice ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.recent_notice ::-webkit-scrollbar-track {
  background-color: #dddddd;
}
.recent_notice ::-webkit-scrollbar-thumb {
  background: #999999;
}
.recent_notice ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

#isMobileHome .search.btn {
  width: 18px;
}

#isMobileHome .isMobile_service_column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 16px;
}

.result_bg {
  width: 100%;
  height: 100%;
  position: fixed;
}

.service_title_inline_block {
  display: inline-block;
  padding: 0px !important;
}

.service_title_float_right {
  float: right;
  line-height: 50px;
}

.service_title_float_right .go_btn {
  color: #797979;
  text-decoration: underline;
  -webkit-text-decoration-color: #c6c6c6;
          text-decoration-color: #c6c6c6;
  text-underline-position: under;
}

.row-link {
  width: 100%;
  margin: 10px 0;
}

.row-link img {
  width: 100%;
}

.ps-10 {
  padding-left: 10px;
}

.pe-10 {
  padding-right: 10px;
}

.pe-15 {
  padding-right: 15px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.mb-30 {
  margin-bottom: 30px;
}

.top-white-space {
  margin-top: 50px;
}

.bottom-white-space {
  margin-bottom: 100px;
}

.service_row {
  width: 100%;
}

.service_row iframe {
  width: 100%;
}

.service_row img {
  width: 100%;
}

.dp-flex {
  display: flex;
}
#footer {
  width: 100%;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 50px 0;
}

#footer .container {
  width: 62.4%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  /* padding: 57px 0; */
  position: relative;
}

#isMobileHome #footer .container {
  width: 100%;
  max-width: 510px;
}

#footer .left_wrapper {
  display: flex;
  width: 100%;
}

#isMobileHome #footer .left_wrapper {
  flex-direction: column;
}

#footer .center_column {
  width: 100%;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #000000;
}

#footer .center_column .center_column_wrapper {
  display: flex;
  justify-content: space-between;
}

#footer .company {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
}

#isMobileHome #footer .company {
  font-size: 16px;
}

#footer .company_info {
  display: flex;
  flex-wrap: wrap;
}

#isMobileHome #footer .company_info,
#isMobileHome #footer .copyright {
  font-size: 12px;
  line-height: 1.8;
}

#footer .right_column {
  font-size: 18px;
  letter-spacing: -0.45px;
  color: #000000;
  min-width: 270px;
}

#isMobileHome #footer .right_column {
  display: none;
}

#footer .isMobile_right_column {
  display: none;
}

#isMobileHome #footer .isMobile_right_column {
  display: block;
  font-size: 12px;
  text-align: right;
}

#footer .move_top {
  position: absolute;
  top: -90px;
  right: 0;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  box-shadow: 5px 8.7px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #056fb8;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobileHome #footer .move_top {
  width: 40px;
  height: 40px;
  top: -45px;
}

#isMobileHome #footer .move_top img {
  width: 11px;
}

#isMobileHome #footer {
  padding: 16px 20px 16px 12px;
}

#isMobileHome #footer img {
  width: 65px;
}
@media screen and (max-width: 530px) {
  #isMobileHome #footer .isMobile_right_column {
    display: none;
  }
}

.modal_bg {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.modal_ct {
  display: none;
  position: fixed;
  height: auto;
  top: 180px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 101;
  border-radius: 20px;
  box-shadow: 5px 8.7px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

#isMobile .modal_ct {
  width: 90vw !important;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
}

.modal_inner_ct {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.openModal.modal_bg {
  display: flex;
  animation: modal-bg-show 0.3s;
}

.openModal.modal_ct {
  display: flex;
  animation: modal-show 0.3s;
}

.center_modal.modal_ct {
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.age_limit {
  padding: 46px 46px 40px 43px;
}

.age_limit h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.6px;
}

#isMobile .age_limit h2 {
  font-size: 18px;
}

.age_limit .modal_info {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.45px;
  letter-spacing: -0.6px;
  margin-top: 18px;
  margin-bottom: 33px;
}

#isMobile .age_limit .modal_info {
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 24px;
}

.wide_modal {
  padding: 20px 0 20px;
}

.wide_modal .close {
  margin-left: auto;
  margin-right: 28px;
}

#isMobile .wide_modal .close {
  width: 14px;
}

#isMobile .wide_modal.change_modal .close.blue_btn,
#isMobile .wide_modal.withdrawal_modal .close.blue_btn {
  width: 100px;
}

#isMobile .wide_modal.withdrawal_modal .close.blue_btn {
  margin: 0;
}

.wide_modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
  margin-top: 15px;
  margin-bottom: 28px;
}

#isMobile .wide_modal h2 {
  font-size: 18px;
}

.wide_modal .article_column {
  max-height: 380px;
  padding: 0 36px;
  width: 100%;
}

#isMobile .wide_modal .article_column {
  padding: 0 20px;
}

.wide_modal .article_column textarea {
  font-size: 16px;
  width: 100%;
  height: 380px;
  max-height: 380px;
  border: none;
}

.wide_modal ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.wide_modal ::-webkit-scrollbar-track {
  background-color: transparent;
}
.wide_modal ::-webkit-scrollbar-thumb {
  border-radius: 23px;
  background: #878787;
}
.wide_modal ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

.wide_modal .article_title {
  width: 100%;
  line-height: 1.5;
  letter-spacing: -0.4px;
  text-align: left;
  font-weight: 500;
  color: #000000;
}

#isMobile .wide_modal .article_title {
  font-size: 15px;
}

.wide_modal .article_info {
  line-height: 1.5;
  letter-spacing: -0.4px;
  text-align: left;
  color: #666666;
}

.wide_modal .article_info:not(:last-child) {
  margin-bottom: 16px;
}

#isMobile .wide_modal .article_info:not(:last-child) {
  font-size: 15px;
  word-break: normal;
}

#isMobile #mypageAddress {
  padding: 0;
}

#joinAdd .modal_400,
#mypageAddress .modal_400 {
  padding: 35px;
}

#isMobile #mypageAddress .modal_400 {
  padding: 35px !important;
}

#isMobile #joinAdd .modal_400 {
  padding: 35px 24px !important;
}

#joinAdd .modal_400 h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.6px;
  text-align: center;
}

#isMobile #joinAdd .modal_400 h2 {
  font-size: 17px;
  padding: 0 30px;
  max-width: 300px;
}

#joinAdd .modal_400 .modal_info {
  margin-top: 12px;
}

#isMobile #joinAdd .modal_400 .modal_info {
  font-size: 15px;
  text-align: center;
}

#joinAdd .modal_400 .modal_warning {
  line-height: 1.67;
  font-size: 14px;
  letter-spacing: -0.35px;
  text-align: center;
  margin-top: 16px;
}

#joinAdd .modal_400 .blue_btn {
  margin-top: 28px;
}

.register_address .search_address_column {
  width: 500px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-right: 24px;
}

#isMobile .register_address .search_address_column {
  width: 90%;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 15px;
  padding-right: 17px;
}

#isMobile .register_address .search_address_column img {
  width: 17px;
}

.register_address .input_search_address {
  width: -webkit-fill-available;
  padding: 0 26px;
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .register_address .input_search_address {
  width: 100%;
  padding: 0 16px;
  font-size: 14px;
}

.register_address .map {
  margin-bottom: 28px;
}

#isMobile .register_address .map {
  margin-bottom: 16px;
}

.register_address .map .map_img {
  min-width: 500px;
  max-width: 600px;
  width: 40vw;
}

#isMobile .register_address .map img {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}

.register_address .address_result_column {
  width: 100%;
  padding: 0 49px;
}

#isMobile .register_address .address_result_column {
  width: 90%;
  padding: 0;
}

.register_address .address_result_column .main_address {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.45px;
  text-align: left;
}

#isMobile .register_address .address_result_column .main_address,
#isMobile .register_address .address_result_column .zip_code {
  font-size: 15px;
}

.register_address .address_result_column .zip_code {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.45px;
  text-align: left;
  color: #999999;
}

.register_address .address_result_column .detail_address {
  font-size: 18px;
  letter-spacing: -0.45px;
  width: 100%;
  height: 50px;
  padding: 0 26px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin: 16px 0;
}

#isMobile .register_address .address_result_column .detail_address {
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.45px;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border-radius: 4px;
}

.exit_modal .btn_column {
  margin: 0 !important;
}

/* 갤럭시 S8에서는 다음 버튼이 안보여서 추가 */
.provision_bottom_div {
  margin-bottom: 20px;
}
#joinAdd .exit_modal .cancel {
  margin-top: 28px;
}

.notice_alram .notice_list {
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  line-height: 2.78;
  letter-spacing: -0.45px;
}

#isMobile .notice_alram .notice_list {
  width: 100%;
  height: 40px;
  font-size: 15px;
}

#isMobile .notice_alram .notice_list {
  width: 100%;
  max-width: 300px;
  height: 40px;
  font-size: 15px;
}

.notice_alram .notice_list.active {
  border-radius: 6px;
  border: solid 1px #056fb8;
}

#isMobile .notice_alram .notice_list.active {
  border-radius: 4px;
}

.modal_400 h2.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
}

#isMobile .modal_400 h2.title {
  font-size: 18px;
}

#joinAdd .blue_btn.close {
  background-color: #056fb8 !important;
}

.blue_btn.close {
  background-color: #999999 !important;
}

.is_login_modal {
  padding: 51px;
}

#isMobile .is_login_modal {
  padding: 24px;
}

.is_login_modal .btn_column {
  display: flex;
  align-items: center;
  margin-top: 47px;
}

#isMobile .is_login_modal .btn_column {
  margin-top: 20px;
}

.is_login_modal .blue_btn.close {
  margin-right: 20px;
}

#mypageAddress .exit_modal .blue_btn.close {
  margin-right: 20px;
}

#mypageAddress .exit_modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.6px;
}

#isMobile #mypageAddress .exit_modal h2 {
  font-size: 130%;
  text-align: center;
  margin-bottom: 10px;
}

#mypageAddress .exit_modal .info {
  line-height: 1.88;
  letter-spacing: -0.4px;
}

#isMobile #mypageAddress .exit_modal .info {
  font-size: 14px;
}

#mypageAddress .exit_modal .warning {
  line-height: 1.88;
  letter-spacing: -0.4px;
  color: #d90d0d;
  margin-bottom: 16px;
}

#isMobile #mypageAddress .exit_modal .warning {
  font-size: 12px;
}

#mypageAddress .exit_modal .blue_btn {
  width: 140px;
}

#mypageAddress .exit_modal .error_info {
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: -0.45px;
  margin-bottom: 33px;
}

.recommend_modal {
  padding: 24px 28px 50px;
}

.recommend_modal .close {
  align-self: flex-end;
}

.recommend_modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
}

#isMobile .recommend_modal h2 {
  font-size: 18px;
}

.recommend_modal .grid_column {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 37px 0 36px;
  grid-row-gap: 28px;
  row-gap: 28px;
}

#isMobile .recommend_modal .grid_column {
  grid-row-gap: 20px;
  row-gap: 20px;
}

.recommend_modal .grid_icon {
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  cursor: pointer;
}

#isMobile .recommend_modal .grid_icon_name {
  font-size: 12px;
}

.recommend_modal .grid_icon img {
  margin-bottom: 6px;
}

#isMobile .recommend_modal .grid_icon img {
  width: 40px;
}

.change_modal .save_btn_column {
  display: flex;
  margin: 40px 0 20px;
}

.change_modal form {
  width: 100%;
  padding: 0 65px;
}

#isMobile .change_modal form {
  padding: 0 20px;
}

.change_modal form .column {
  display: flex;
  align-items: center;
}

#isMobile .change_modal form .column {
  flex-direction: column;
  align-items: flex-start;
}

.change_modal form .column.warning_column {
  margin-bottom: 0;
}

.change_modal form .input_name {
  width: 169px;
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .change_modal form .input_name {
  font-size: 14px;
  margin-bottom: 6px;
}

.change_modal form input {
  width: 300px;
}

.change_modal form .info {
  font-size: 12px;
  letter-spacing: -0.3px;
  color: #666666;
}

.agree_ad {
  padding: 33px 0 37px;
}

.agree_ad h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
}

#isMobile .agree_ad h2 {
  font-size: 20px;
}

.agree_ad .info_column {
  margin: 12px 0 20px;
  text-align: center;
}

.agree_ad .info {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
  color: #000000;
}

#isMobile .agree_ad .info {
  font-size: 16px;
}

.agree_ad .date {
  line-height: 1.5;
  color: #666666;
}

#isMobile .agree_ad .date {
  font-size: 14px;
}

.withdrawal_modal form {
  padding: 0 53px;
}

#isMobile .withdrawal_modal form {
  padding: 0 20px;
}

.withdrawal_modal .question h2 {
  margin: 0;
}

#isMobile .withdrawal_modal .question h2 {
  font-size: 14px;
}

.withdrawal_modal .question_info {
  line-height: 1.5;
  letter-spacing: -0.4px;
}

#isMobile .withdrawal_modal .question_info {
  font-size: 13px;
}

#isMobile .withdrawal_modal .question_info.bottom {
  font-size: 10px;
}

.withdrawal_modal .question_info strong {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
}

#isMobile .withdrawal_modal .question_info strong {
  font-size: 14px;
}

.withdrawal_modal .checkbox_coloumn {
  margin: 40px 0 33px;
}

#isMobile .withdrawal_modal .checkbox_coloumn {
  margin: 24px 0 24px;
}

.withdrawal_modal .checkbox_wrap {
  margin-bottom: 20px;
}

#isMobile .withdrawal_modal .checkbox_wrap {
  margin-bottom: 10px;
}

.withdrawal_reason {
  width: 100%;
  padding: 4px;
}

.withdrawal_modal .withdrawal_btn_column {
  display: flex;
  margin-top: 59px;
}

#isMobile .withdrawal_modal .withdrawal_btn_column {
  margin-top: 24px;
}

.withdrawal_modal .withdrawal_btn_column .red_btn {
  margin-right: 20px;
}

.modal_400 .modal_title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
}

#isMobile .modal_400 .modal_title {
  font-size: 17px;
}

.modal_400.withdrawal_modal {
  padding: 55px 0 51px;
}

.withdrawal_modal .withdrawal_btn_column.really {
  margin-top: 47px;
}

.withdrawal_modal .withdrawal_btn_column.really .blue_btn {
  margin-right: 20px;
}

.withdrawal_modal .withdrawal_btn_column.really .red_btn {
  margin-right: 0;
}

/* 주소 API 전용*/
.pop-address-search {min-width:100%; min-height:400px}
.pop-address-search .pop-address-search-inner {width:100%;position:relative; text-align:center; background-color:transparent; background-repeat:no-repeat; background-position:100% 100%}
.searchContentBox{min-height: 480px;}
.pop-address-search .pop-address-search-inner .search-wrap{width:100%;display:block; position:relative; border-bottom:2px solid #e9e9e9; margin: 0 auto; padding: 15px 0;}
.pop-address-search .pop-address-search-inner .wrap {display:block; width:90%; position:relative; box-sizing:border-box; border:2px solid #186bb9; margin:0 auto; padding:0 39px 0 0;background-color: #fff}
.pop-address-search .pop-address-search-inner .wrap input[type="text"] {width:100%; padding-left:10px; border:o none; line-height:40px; font-size: 14px; font-weight: normal; ime-mode:active;}						
.pop-address-search .pop-address-search-inner .wrap input[type="button"] {position:absolute; right:0; top:10px; width:39px; height:20px; background: url(https://www.juso.go.kr/juso_support_center/img_addrlink/btn-search.png) 50% 50% no-repeat}	
.pop-address-search .pop-address-search-inner .list-roadAddr{font-size:13px;}
.pop-address-search .pop-address-search-inner .list-jibunAddr{font-size:11px; color:#186bb9;}
.pop-address-search .pop-address-search-inner .result {position:relative; margin:20px 20px 5px 20px;}
.pop-address-search .pop-address-search-inner .result table{line-height:1.57em; font-size:12px;}
.pop-address-search .pop-address-search-inner .result table tbody tr td {line-height:1.57em}
.pop-address-search .pop-address-search-inner .result table thead tr th,
.pop-address-search .pop-address-search-inner .result table tbody tr td {height:40px; padding:1px 0}
.pop-address-search .pop-address-search-inner .result table tbody tr:nth-child(odd) td {background:#f9f9f9}
.pop-address-search .pop-address-search-inner .result table tbody tr.nodata td {background:#fff}
.pop-address-search .pop-address-search-inner .detail {margin:20px}
.pop-address-search .pop-address-search-inner .detail table{line-height:1.57em; font-size:12px;}
.pop-address-search .pop-address-search-inner .detail select{ text-indent: 6px; box-sizing: border-box; height: 30px; width:75px; color: #3f3f3f; line-height: 15px; font-size: 12px; border-radius: 2px; border: 1px solid #ddd; padding: 0 15px; background: #fff url(https://www.juso.go.kr/juso_support_center/img_addrlink/arrow_select.png) no-repeat 95% 50%;}
.pop-address-search .pop-address-search-inner .detail input[type="text"]{ text-indent: 6px; box-sizing: border-box; width:100%; height: 30px; color: #3f3f3f; line-height: 15px; font-size: 12px; border-radius: 2px; border: 1px solid #ddd; padding: 0 15px; background: #fff;}
.pop-address-search .pop-address-search-inner .detail .titleTd{background-color:#e9e9e9; height:30px; width:120px; vertical-align: middle;}
.pop-address-search .pop-address-search-inner .detail .btn-area{width:100%; margin-top:20px; align-items: center;}
#header {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile #header,
#isMobileHome #header {
  min-height: 53px;
  border-bottom: none;
  padding: 0 12px;
}

#header .container {
  width: 62.4%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile #header .container,
#isMobileHome #header .container {
  width: 100vw;
  max-width: 450px;
}

#header .main_logo {
  width: 60px;
  height: 38px;
}

#isMobile #header .main_logo,
#isMobileHome #header .main_logo {
  width: 42px;
}

#header .wraaper {
  display: flex;
  align-items: center;
}

#header .hello_user {
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: #000000;
  margin-right: 12px;
}

#header .notice_column {
  position: relative;
  margin: 0 40px 0 30px;
}

#header .notice_count {
  position: absolute;
  top: -4px;
  right: -18px;
  width: 27px;
  height: 18px;
  border-radius: 9px;
  background-color: #000000;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -0.3px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#header .wraaper .login_btn {
  width: 90px;
  height: 36px;
  border-radius: 18px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  letter-spacing: -0.4px;
  margin-right: 26px;
}

#isMobile #header .wraaper .login_btn,
#isMobileHome #header .wraaper .login_btn {
  width: 73px;
  height: 30px;
  font-size: 14px;
  margin-right: 13px;
}

#header .wraaper .join_btn {
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: #0d6fb8;
}

#isMobile #header .wraaper .join_btn {
  font-size: 14px;
}

#isMobileHome #header .isMobile_set_icon,
#isMobile #header .isMobile_set_icon {
  display: none;
}

#isMobileHome .isMobile_notice_icon,
#isMobile .isMobile_notice_icon {
  width: 16px;
}

#isMobileHome .isMobile_profile_icon,
#isMobile .isMobile_profile_icon {
  width: 33.3px;
}

#isMobileHome #header .notice_count,
#isMobile #header .notice_count {
  width: 20px;
  height: 13px;
  top: -2px;
  right: -14px;
  font-size: 10px;
}

#isMobileHome #header .notice_column,
#isMobile #header .notice_column {
  margin: 0 15px 0 12px;
}

#isMobileHome #header .hello_user,
#isMobile #header .hello_user {
  display: none;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin-left: 0;
}

#isMobileHome .swiper-pagination-bullet {
  background-color: #dddddd;
  opacity: 1;
}

#isMobileHome .swiper-pagination-bullet {
  width: 4px;
  height: 4px;
}

.swiper-pagination-bullet-active {
  width: 60px;
  border-radius: 4px;
  background-color: #056fb8;
}

#isMobileHome .swiper-pagination-bullet-active {
  width: 30px;
  height: 4px;
  background-color: #056fb8;
}

.swiper-pagination-bullet:not(:last-child) {
  margin-right: 20px !important;
}

#isMobileHome .swiper-pagination-bullet:not(:last-child) {
  margin-right: 10px !important;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAZCAYAAAABmx/yAAAABHNCSVQICAgIfAhkiAAAAONJREFUOE+d1LENgzAQAECeArcZgRGSCaJskA0SV0AVMUEyQjqgMpskG4RR0kIBeVsyMgGbN5as/+JPb1lvQ1VV5yAIjkmS5BjJCxA+sPo+DEOdpimnSpCFiGsMFx+s4BY8Ql88gT54Bql4Ef5jxljOOf+aN26FJsa8iaLoZGIndOFVKHFZlk8AuJmdSVDioiiuYRgKjclQCLFr2/aDnWPcBxKUqOu6F3bb933PsyyrV+ESkkd3QhtyQheywjW0CCloBqloAn3QCH2RgluQgvqj0hNhvjlXDji8MRbEOEZvKpJ1P1Os0wVXqh4kAAAAAElFTkSuQmCC);
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAZCAYAAAABmx/yAAAABHNCSVQICAgIfAhkiAAAAMdJREFUOE+d1DsSwiAQgGFJQ6meRI5gbiINj9LTMNDgUTyC3iRlqiAwMZNMeCyhSfURin8XnRqOUurRdd2Nc/5EUDcj65z7CiEICK4RxvhOKR2qMIXCK4swh4qwhLKwhpIQgnYQijawBS2wFUV4BEWotR789zxNUy+lfEMTRMYY4vsLwCGEesbYB4JjOUfwklwr3rTagneRQ3FyOiA4O1Y1XJzHEq5ugBUOkVAfyau6Af4hpHD1jwns/Hq8gmG4wFp7GceRhKZ/IPLbGtM2MOwAAAAASUVORK5CYII=);
}

.mySwiper.single .slide_content {
  margin-bottom: 0;
}

.mySwiper.single .swiper-pagination {
  display: none !important;
}

.mySwiper.single .swiper-button-next:after,
.mySwiper.single .swiper-button-prev:after,
#isMobileHome .slide_section .swiper-button-next:after,
#isMobileHome .slide_section .swiper-button-prev:after {
  content: "";
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100% !important;
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin-left: 0;
}

#isMobileHome .swiper-pagination-bullet {
  background-color: #dddddd;
  opacity: 1;
}

#isMobileHome .swiper-pagination-bullet {
  width: 4px;
  height: 4px;
}

.swiper-pagination-bullet-active {
  width: 60px;
  border-radius: 4px;
  background-color: #056fb8;
}

#isMobileHome .swiper-pagination-bullet-active {
  width: 30px;
  height: 4px;
  background-color: #056fb8;
}

.swiper-pagination-bullet:not(:last-child) {
  margin-right: 20px !important;
}

#isMobileHome .swiper-pagination-bullet:not(:last-child) {
  margin-right: 10px !important;
}

#sliderMainBanner .swiper-button-next:after,
#sliderMainBanner .swiper-container-rtl .swiper-button-prev:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAZCAYAAAABmx/yAAAABHNCSVQICAgIfAhkiAAAANFJREFUOE+d1IENgjAQBdDeBo7ACDqBcQM2UCYwTCAjuAHdRDfQUdyg/mtKQ4G2d5Bcrk3u5Uq5QM651hhzJqIeWfwQ4IDqB8ICd1JJXAhska4a7OEeHKEWJ1CDV1CKN+ESY9/jxn/zG8/CBf5if5njIizhKgz4iXxHxM4iGPANeYxYOmKYrgNqP4gGcRJ1DOgFcER0uCRbhVuIT1n7HHy8pNP0aqUByKJsx9zxipMjQauOUpRADYpQizzcgyZoseAflZ8I6QhyR569BugtRVz3B/YJflSNEI9VAAAAAElFTkSuQmCC);
}

#sliderMainBanner .swiper-button-prev:after,
#sliderMainBanner .swiper-container-rtl .swiper-button-next:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAZCAYAAAABmx/yAAAABHNCSVQICAgIfAhkiAAAALRJREFUOE+d1NENwjAMBFA8ATAJGQE2gRGYCEZhBNikG5hzlUStmsTnRqrSDz85Us6RQ2Cp6h3lFxF5CusyeqH+B5gouESAV8DJhS1kpxzCHhrCEepCDzUhgzaQRSsYQRVG0Qz3oAIn/Bzx3ZCIDxtB65hQbEAz/jJ4Ts4eXCMXxausRvAm5CxuTgeDu2PlYW8eu1fFvAAFW0geCMnbfQFKEBbHrtjt2MCKrmca5oSdsCfL9B/LNY0ai4MxrAAAAABJRU5ErkJggg==);
}

#isMobileHome #sliderMainBanner .swiper-button-next:after,
#isMobileHome
  #sliderMainBanner
  .swiper-container-rtl
  .swiper-button-prev:after {
  content: "";
}

#isMobileHome #sliderMainBanner .swiper-button-prev:after,
#isMobileHome
  #sliderMainBanner
  .swiper-container-rtl
  .swiper-button-next:after {
  content: "";
}

.slide_section.single {
  padding: 44px 20px;
}

.slide_section.single .slide_content {
  margin-bottom: 0;
}

.slide_section.single .swiper-pagination {
  display: none !important;
}

.slide_section.single .swiper-button-next:after,
.slide_section.single .swiper-button-prev:after,
#isMobileHome .slide_section .swiper-button-next:after,
#isMobileHome .slide_section .swiper-button-prev:after {
  content: "";
}

.swiper-pagination,
.swiper-pagination span {
  font-size: 14px;
  letter-spacing: -0.35px;
  color: #ffffff;
}

#home .page_column_move {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 30px;
  border-radius: 15px;
  background-color: #286fa8;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

#home #sliderEventBanner .page_column_move {
  background-color: #0e0b08;
}

#isMobileHome #home #sliderEventBanner .page_column_move {
  left: 95%;
}

#isMobileHome #home .page_column_move {
  width: 60px;
  height: 20px;
}

#home .page_column_move img {
  margin-right: 6px;
}

.swiper-pagination-1,
.swiper-pagination-1 span {
  width: auto !important;
  font-size: 14px !important;
  letter-spacing: -0.35px !important;
  color: #ffffff !important;
}

#isMobileHome .swiper-pagination-1,
#isMobileHome .swiper-pagination-1 span {
  font-size: 8px !important;
}

#isMobileHome .banner {
  width: 100%;
}

#sliderEventBanner {
  margin-top: 10px;
}

#isMobileHome #sliderEventBanner {
  margin-top: 20px;
}

#sliderEventBanner img.banner {
  width: 100%;
  height: 240px;
}

#isMobileHome #sliderEventBanner img.banner {
  height: 120px;
}

#isMobileHome #sliderMainBanner img.banner {
  width: 100%;
  height: auto;
}

#sliderMainBanner.single .page_column_move,
#sliderMainBanner.single .swiper-button-disabled,
#sliderEventBanner .swiper-button-prev,
#sliderEventBanner .swiper-button-next,
#sliderEventBanner .swiper-button-disabled {
  display: none;
}

.sub_banner_accordion {
  width: 100%;
}

.sub_banner_accordion_item_header {
  border-bottom: 1px solid #cacaca;
}

.sub_banner_accordion_item_body img {
  width: 100%;
}

.sub_banner_accordion_item_body {
  display: none;
}

.sub_banner_accordion_item_body.active {
  display: block;
  padding: 30px 0;
  border-bottom: 1px solid #cacaca;
}

#home .sub_banner_row {
  display: flex;
  flex-direction: row;
}

#home .sub_banner_column_1 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

#home .sub_banner_column_1 img {
  width: 100%;
}

#home .sub_banner_column_2 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

#home .sub_banner_column_2 img {
  width: 100%;
}
#login .join_title {
  margin-bottom: 2px;
}

#isMobile #login {
  justify-content: center;
}

#login h2 {
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.45px;
  color: #666666;
}

#isMobile #login h2 {
  font-size: 15px;
  margin-right: auto;
}

#login .login_form {
  margin-top: 38px;
}

#isMobile #login .login_form {
  width: 100%;
  margin-top: 16px;
}

#login .login_form .column.top {
  margin-bottom: 20px;
}

#login .auto_login {
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;
}

#isMobile #login .auto_login {
  margin-top: 8px;
  margin-bottom: 8px;
}

#login .auto_login label {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.35px;
}

#login .login_form .login_btn {
  width: 400px;
  height: 50px;
  border-radius: 10px;
  background-color: #0d6fb8;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: -0.45px;
  color: #ffffff;
}

#isMobile #login .login_form .login_btn {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  font-size: 15px;
}

#login .horizontal_line {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

#isMobile #login .horizontal_line {
  width: 100%;
  margin-top: 20px;
}

#login .horizontal_line .hr {
  width: 160px;
  border-bottom: 2px solid #f1f1f1;
}

#isMobile #login .horizontal_line span {
  font-size: 14px;
}

#login .horizontal_line span {
  display: inline-block;
  line-height: 1.75;
  letter-spacing: -0.4px;
  margin: 0 30px;
}

#login .sns_login {
  display: flex;
  margin-top: 51px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 400px;
  justify-content: space-evenly;
}

#isMobile #login .sns_login {
  margin-top: 20px;
  margin-bottom: 15px;
}

#login .sns_login .column {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

#login .sns_login .column.left {
  margin-right: 81px;
}

#isMobile #login .sns_login .column.left {
  margin-right: 50px;
}

#login .sns_login .column span {
  letter-spacing: -0.4px;
}

#isMobile #login .sns_login .column span {
  font-size: 14px;
}

#login .sub_links {
  display: flex;
  line-height: 1.75;
  letter-spacing: -0.4px;
  text-align: center;
  margin-bottom: 104px;
}

#login .sub_links div {
  cursor: pointer;
}

#isMobile #login .sub_links {
  font-size: 14px;
  margin-bottom: 0;
}

.isMobile {
  display: none;
}

#isMobile .isMobile {
  display: block;
  text-align: left;
}

#appleid-signin {
  display: none;
}

.sns_login_title {
  margin-top: 10px;
}

#JoinUser .join_title {
  margin-bottom: 38px;
}

.join_user .join_title {
  margin-bottom: 27px;
}

#isMobile .join_user .join_title {
  margin-bottom: 0;
  margin-top: 24px;
}

#isMobile #JoinUser.join_user .join_title {
  margin-bottom: 22px;
}

#isMobile .login_form {
  width: 100%;
  max-width: 450px;
}

.join_user .sns_login .column h2 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
  text-align: center;
}

#isMobile .join_user .sns_login .column h2 {
  font-size: 16px;
}

.join_user .sns_login {
  margin-bottom: 44px;
}

.join_user .checkbox_wrap.main input[type="checkbox"] + label {
  font-size: 18px;
  letter-spacing: -0.45px;
}

.join_user .checkbox_wrap input[type="checkbox"] + label:before,
.join_user .checkbox_wrap input[type="checkbox"]:checked + label:after {
  top: 2px;
}

.join_user .provision {
  margin-top: 14px;
  width: 510px;
  padding: 16px 24px 22px 20px;
  background-color: #f1f1f1;
}

#isMobile .join_user .provision {
  width: auto;
}

.join_user .provision .column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.join_user .provision .column img {
  cursor: pointer;
}

.join_user .provision .column:not(:last-child) {
  margin-bottom: 17px;
}

.join_user .checkbox_wrap input[type="checkbox"] + label {
  letter-spacing: -0.4px;
}

.join_user .checkbox_wrap.sub input[type="checkbox"] + label:before,
.join_user .checkbox_wrap.sub input[type="checkbox"]:checked + label:after {
  top: 2px;
}

.join_user .btn_column {
  text-align: center;
  margin-top: 26px;
  margin-bottom: 124px;
}

#isMobile .join_user .btn_column {
  margin-bottom: 0;
}

#joinAdd .sub_title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: -0.6px;
  text-align: center;
  margin-top: 24px;
}

#joinAdd form {
  max-width: 500px;
  width: 100%;
}

#joinAdd form input {
  width: 100%;
}

#isMobile #joinAdd .sub_title,
#isMobile #joinAdd .main_title_info {
  font-size: 12px;
  justify-content: flex-start;
  text-align: left;
  height: auto;
  font-weight: normal;
  margin-top: 4px;
}

#joinAdd .main_title_info {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.45px;
  margin-bottom: 47px;
}

#joinAdd .input_name {
  font-size: 18px;
  letter-spacing: -0.45px;
  margin-bottom: 8px;
}

#isMobile #joinAdd .input_name {
  font-size: 13px;
  margin-bottom: 0;
}

#isMobile #joinAdd .isMobile.input_name {
  margin-bottom: 6px;
}

form .check_btn.adree {
  margin-left: 0;
  width: 100%;
  margin-bottom: 20px;
}

#isMobile form .check_btn.adree {
  width: 130px;
  height: 40px;
  font-size: 13px;
  border-radius: 4px;
  margin-bottom: 0;
}

#joinAdd .help {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: -0.35px;
  color: #0d6fb8;
  cursor: pointer;
}

#isMobile #joinAdd .help {
  font-size: 13px;
}

#joinAdd .own_radio_container {
  margin-top: 12px;
}

#joinAdd .own_radio_container .input_name {
  margin-bottom: 15px;
}

#joinAdd .own_radio_container .own_radio_column {
  display: flex;
  margin-bottom: 6px;
}

#joinAdd .own_radio_column {
  margin-bottom: 6px;
}

#joinAdd .radio_wrap.left {
  margin-right: 28px;
}

#joinAdd .add_rental_contract {
  border-radius: 4px;
  background-color: #f1f1f1;
  padding: 28px 40px 14px 38px;
  margin-top: 18px;
}

#isMobile #joinAdd .add_rental_contract {
  padding: 30px 24px 4px;
}

#joinAdd .add_rental_contract h3 {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
}

#isMobile #joinAdd .add_rental_contract h3 {
  font-size: 18px;
}

#joinAdd .add_rental_contract .title_info {
  font-size: 16px;
  line-height: 1.88;
  letter-spacing: -0.4px;
  color: #666666;
  margin-bottom: 25px;
}

#isMobile #joinAdd .add_rental_contract .title_info {
  font-size: 14px;
}

#joinAdd .add_rental_contract .sub_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

#isMobile #joinAdd .add_rental_contract .sub_column {
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

#isMobile #joinAdd .add_rental_contract .isMobile.sub_column {
  flex-direction: row;
  align-items: flex-start;
}

#joinAdd .add_rental_contract .sub_column input {
  width: 300px;
}

#isMobile #joinAdd .add_rental_contract .sub_column input {
  width: 100%;
}

#joinAdd .add_rental_contract .sub_column input.calendar {
  background-color: #ffffff !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABHNCSVQICAgIfAhkiAAAAeVJREFUSEvtlkFWwkAMQJPSPtmJJxBvwBHoRtudngC4AJYb6A0GPID1BOKqPDfgDbgBeAOW+tpOzAwMYl+R4cFz5ew6k+Qn6UwShJLldpM73m5mg7BZdl7cc6OkiYRjQvIzEU6K5/inkEqUtNmbFlOtvN8WIQEs+GwCSH0TlY7E6yYxILaI4AWApipVfFBnhdgmXUpW6QPRE+vM+bvN3+cSqZOLMEYVgUP4KBFvcnE1VEZ1VBIb2SCIbCAQvTZcmYvMqUQgLpWTa8dTpAv0uiPepGk6CNtWBm2FonHNlZ9zti3Qux0RSOilD4Gw1beV41vKNw0XSwjnngiZetyFSG0iejeQ41rfsMaQNw3Z9ogOJS/TxQn7h2ymcvWYKe0Hnc39o6XLVAvzuo8O+Q2gYNaRqLKfOVUBwleFb712AewhUVJ3JU4RYZbiiW9ANgB7iJLUxU9ODMiTH0JV3LJ/UHxX1unSiisQIBCX8ZoNYL9IjHsM8qSMpUNC9QibavAzEqJ77ud3Nor7yCgIRz5HtzsachpOs37g72NgpyxfGo9wplKLZtJgpZhvUK94VXcaKxNYAp65HZ+lTrWhe7xpwashQLfPQ5YeRriPpE7lWrXj75FIvwkeAA6cVtQ/kA5McqgOTVa+AO9UWFutVmQKAAAAAElFTkSuQmCC) no-repeat 255px 50%;
  cursor: pointer;
}

#isMobile #joinAdd .add_rental_contract .sub_column input.calendar {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABHNCSVQICAgIfAhkiAAAAeVJREFUSEvtlkFWwkAMQJPSPtmJJxBvwBHoRtudngC4AJYb6A0GPID1BOKqPDfgDbgBeAOW+tpOzAwMYl+R4cFz5ew6k+Qn6UwShJLldpM73m5mg7BZdl7cc6OkiYRjQvIzEU6K5/inkEqUtNmbFlOtvN8WIQEs+GwCSH0TlY7E6yYxILaI4AWApipVfFBnhdgmXUpW6QPRE+vM+bvN3+cSqZOLMEYVgUP4KBFvcnE1VEZ1VBIb2SCIbCAQvTZcmYvMqUQgLpWTa8dTpAv0uiPepGk6CNtWBm2FonHNlZ9zti3Qux0RSOilD4Gw1beV41vKNw0XSwjnngiZetyFSG0iejeQ41rfsMaQNw3Z9ogOJS/TxQn7h2ymcvWYKe0Hnc39o6XLVAvzuo8O+Q2gYNaRqLKfOVUBwleFb712AewhUVJ3JU4RYZbiiW9ANgB7iJLUxU9ODMiTH0JV3LJ/UHxX1unSiisQIBCX8ZoNYL9IjHsM8qSMpUNC9QibavAzEqJ77ud3Nor7yCgIRz5HtzsachpOs37g72NgpyxfGo9wplKLZtJgpZhvUK94VXcaKxNYAp65HZ+lTrWhe7xpwashQLfPQ5YeRriPpE7lWrXj75FIvwkeAA6cVtQ/kA5McqgOTVa+AO9UWFutVmQKAAAAAElFTkSuQmCC) no-repeat 95% 50%;
  background-size: 18px;
}

#joinAdd .add_rental_contract .sub_column .notice_cycle {
  width: 300px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.45px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile #joinAdd .add_rental_contract .sub_column .notice_cycle {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 16px;
}

#joinAdd .add_rental_contract .sub_column .sub_name {
  font-size: 18px;
  letter-spacing: -0.45px;
  text-align: left;
}

#isMobile #joinAdd .add_rental_contract .sub_column .sub_name {
  font-size: 13px;
  margin-bottom: 6px;
}

#isMobile #joinAdd .add_rental_contract .isMobile.sub_column .sub_name {
  margin-bottom: 0;
  margin-right: 24px;
}

#joinAdd .warning_column {
  display: flex;
  align-items: flex-start;
  margin-top: 21px;
}

#joinAdd .warning_column .warning_icon {
  margin-right: 6px;
  margin-top: 6px;
}

#isMobile #joinAdd .warning_column .warning_icon {
  margin-right: 4px;
  margin-top: 4px;
  width: 14px;
}

#joinAdd .warning_column .warning_message {
  font-size: 14px;
  line-height: 1.75;
  letter-spacing: -0.35px;
}

#isMobile #joinAdd .warning_column .warning_message {
  font-size: 12px;
}

#joinAdd .btn_column {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 94px;
}

#isMobile #joinAdd .btn_column {
  margin-top: 24px;
  padding: 0 24px;
}

#joinAdd .btn_column .btn {
  width: 160px;
  height: 50px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: -0.9px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile #joinAdd .btn_column .btn {
  width: 50%;
  height: 40px;
  border-radius: 6px;
  font-size: 15px;
}

#isMobile .exit_modal .btn_column .btn {
  width: 100px !important;
}

#joinAdd .btn_column .cancel {
  background-color: #999999 !important;
}

#joinAdd .btn_column .left {
  margin-right: 20px;
}

#joinAdd .btn_column .register {
  background-color: #0d6fb8 !important;
}

#isMobile #joinFinish .main_title,
#isMobile #joinAuth .main_title,
#isMobile #findId .main_title,
#isMobile #dormantUser .main_title {
  margin-top: 10vw;
  margin-bottom: 26px;
}

#isMobile #findPw .main_title {
  margin-top: 10vw;
}

#joinFinish .title_info,
#joinAuth .title_info {
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.6px;
  text-align: center;
}

#isMobile #joinFinish .title_info,
#isMobile #joinAuth .title_info {
  font-size: 16px;
  text-align: left;
  width: 100%;
}

#joinFinish .blue_btn,
#joinAuth .blue_btn {
  margin: auto;
  margin-top: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile #joinFinish,
#isMobile #joinAuth,
#isMobile #joinAuth,
#isMobile #findPw,
#isMobile #dormantUser {
  display: block;
}

#joinFinish a {
  width: 100%;
}

#isMobile .isMobile_search_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.email_input {
  margin-top: 24px;
  padding: 0 8px;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.2rem;
  border: solid 1px #dddddd;
  width: 200px;
  height: 50px;
  border-radius: 6px;
}

.email_info {
  text-align: center;
  margin-top: 24px;
}

.email_info {
  text-align: center;
  margin-top: 24px;
}

#isMobile .email_info {
  text-align: left;
  margin-top: 24px;
  display: block;
  font-size: 12px;
}

.login_form .sns_join_name_cloumn {
  margin-bottom: 5px;
}

.joinPath .own_radio_column .radio_wrap{
  display: block;
  margin-bottom: 15px;
}

.joinPath .own_radio_column .left{
  width:150px;
}

.joinPath .own_radio_column ul li {
  display: flex;
}
.my-calendar {
  width: 540px;
  margin-top: 20px;
  margin-bottom: 15px;
}

#isMobile .my-calendar {
  width: 100%;
}

.ctr-box {
  padding: 0 16px;
  margin-bottom: 20px;
  font-size: 20px;
}
.ctr-box .btn-cal {
  margin: 0 31px;
  cursor: pointer;
}
.ctr-box .btn-cal:after {
  content: "<";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 25px;
  font-weight: bold;
  font-size: 20px;
}

.ctr-box .btn-cal.next:after {
  content: ">";
}

.cal-table {
  width: 100%;
}
.cal-table th {
  width: 14.28%;
  font-size: 16px;
  font-weight: 500;
  line-height: 3.13;
  letter-spacing: -0.8px;
  text-align: center;
}

#isMobile .cal-table th,
#isMobile .cal-table td div {
  font-size: 14px;
}

.cal-table td {
  font-size: 16px;
  font-weight: 500;
  line-height: 3.13;
  letter-spacing: -0.8px;
  text-align: center;
}

.cal-table td.day {
  position: relative;
  cursor: pointer;
}

.cal-table th.sunday,
.cal-table td.sunday div {
  color: #ee0000;
}

.cal-table th.saturday,
.cal-table td.saturday div {
  color: #1681e5;
}

.cal-table td.today div {
  background: #056fb8;
  border-radius: 50%;
  color: #fff;
  width: 40px;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cal-table td.today div {
  width: 30px;
  height: 30px;
}

*/ .cal-table td.has-event:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: #ffc107;
}

/* 추가 */

.my-calendar .ctr-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-calendar .ctr-box,
.my-calendar .ctr-box span {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
  color: #000000;
}

.my-calendar .ctr-box,
.my-calendar .ctr-box span {
  font-size: 18px;
}

/* 신규 달력 */

.react-calendar {
  width: 540px;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
}

.react-calendar__navigation {
  margin-bottom: 0;
}

.react-calendar__navigation__arrow {
  font-size: 28px;
  line-height: 0.5;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.react-calendar__navigation__label {
  flex-grow: initial !important;
}

.react-calendar__navigation__label span {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
  color: #000000;
  margin: 0 24px;
}

.react-calendar__month-view__weekdays__weekday abbr {
  font-size: 16px;
  font-weight: 500;
  line-height: 3.13;
  letter-spacing: -0.8px;
  text-align: center;
  text-decoration: none;
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: #757575 !important;
}

.react-calendar__month-view__days__day--weekend:nth-child(7n + 1) abbr {
  color: #ee0000;
}

.react-calendar__month-view__days__day--weekend:nth-child(7n) abbr {
  color: #1681e5;
}

.react-calendar__tile {
  padding: 0.5em 0.5em;
}

.react-calendar__tile abbr {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.8px;
  text-align: center;
  width: 30px;
  height: 30px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-calendar__tile--active,
.react-calendar__tile--now {
  background: none;
}

.react-calendar__tile--now abbr {
  border-radius: 50%;
  background: #ffff76;
}

.react-calendar__tile--active abbr {
  border-radius: 50%;
  background: #006edc;
  color: #fff !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: none;
}

.find_user .title_info {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.45px;
  text-align: center;
  margin-top: 33px;
  margin-bottom: 53px;
}

#isMobile .find_user .title_info {
  width: 100%;
  font-size: 14px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}

#isMobile #findId.find_user .title_info,
#isMobile #findPw.find_user .title_info {
  margin-bottom: 20px;
}

.find_user .title_info.confirm {
  margin-bottom: 4px;
}

.find_user form input {
  width: 360px;
}

.find_user form input.wide {
  width: 100%;
}

.find_user form .input_column.auth_num_column {
  align-items: center;
  border-radius: 6px;
  border: solid 1px #dddddd;
  padding-right: 24px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.find_user form input.auth_num {
  border: none;
  width: -webkit-fill-available;
}

.find_user form .auth_num_column .counter {
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
}

#isMobile .find_user form .auth_num_column .counter {
  font-size: 12px;
}

.find_user form .check_btn {
  width: 140px;
}
.find_user .btn_column .nice_btn {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.find_user form .info {
  line-height: 1.2;
  color: #666666;
}

.find_user .btn_column {
  text-align: center;
}

.find_user form .info.btn_bottom {
  line-height: 2.14;
  text-align: center;
  margin-bottom: 100px;
}

.find_user form .warning {
  margin-bottom: 20px;
}

#isMobile .find_user form .warning {
  margin-bottom: 4px;
}

.find_user .user_id {
  font-size: 24px;
  line-height: 1.75;
  letter-spacing: -0.6px;
}

#isMobile .find_user .user_id {
  font-size: 18px;
  font-weight: bold;
}

#isMobile #findId form {
  width: 100%;
}

.find_user .login_btn {
  margin: 76px auto 0;
}

.find_user .new_pw {
  margin-top: 38px;
}

.find_user .new_pw input {
  width: 510px;
}

.find_user form .new_pw .input_column {
  flex-direction: column;
}

.find_user form .input_name {
  font-size: 18px;
  letter-spacing: -0.45px;
  margin-bottom: 8px;
}

.find_user .new_pw .warning {
  margin-bottom: 4px;
}

.find_user .new_pw .login_btn {
  margin-top: 18px;
}

#dormantUser .title_info {
  font-size: 24px;
  line-height: 2.25;
  letter-spacing: -0.6px;
  color: #000000;
  margin-bottom: 30px;
}

#isMobile #dormantUser .title_info,
#isMobile #dormantUser .title_info span {
  font-size: 14px;
  line-height: 1.5;
}

#isMobile #dormantUser .title_info {
  margin: 16px 0;
}

#dormantUser .title_info span {
  font-size: 16px;
  line-height: 2.25;
  letter-spacing: -0.4px;
  color: #666666;
  display: block;
}

#dormantUser .title_info span.inner_span {
  display: inline-block;
}

#dormantUser .title_info strong {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #000000;
}

#isMobile #findPw form {
  width: 100%;
}

.board_main .section_1 {
  width: 100%;
  height: 300px;
  background-color: #3795e1;
  display: flex;
  justify-content: center;
}

#isMobile .board_main .section_1 {
  height: 120px;
}

#information,
#counseling {
  padding-bottom: 60px;
}

#information .section_1 {
  background-color: #9695da;
}

.board_main .section_1 .container {
  width: 1000px;
  position: relative;
}

#isMobile .board_main .section_1 .container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  max-width: 450px;
}

.board_main .section_1 .title {
  font-family: "NanumSquare";
  font-weight: 800;
  font-size: 40px;
  line-height: 0.85;
  letter-spacing: -2px;
  color: #ffffff;
  margin-top: 50px;
  margin-bottom: 15px;
}

#isMobile .board_main .section_1 .title {
  font-size: 20px;
  margin-top: 0;
}

#information .section_1 .title {
  margin-top: 80px;
}

.board_main .section_1 .desc {
  font-family: "NanumSquare";
  font-weight: 400;
  font-size: 24px;
  line-height: 1.42;
  letter-spacing: -1.2px;
  color: #ffffff;
}

#isMobile .board_main .section_1 .desc {
  font-size: 14px;
}

.board_main .go_btn_column .go_btn {
  width: 180px;
  margin-top: 29px;
}

#isMobile .board_main .go_btn_column:not(.apply_counseling) .go_btn {
  margin-top: 5px;
  height: 28px;
}

.board_main .board_main_img {
  position: absolute;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

#isMobile .board_main .board_main_img {
  width: 116px;
  right: 8px;
}

.board_main .section_2 {
  width: 62.4%;
  display: flex;
  flex-direction: column;
  margin: auto;
}

#isMobile .board_main .section_2 {
  width: 100%;
}

.depth_column {
  display: flex;
  align-items: center;
  margin-top: 58px;
  margin-bottom: 18px;
}

#isMobile .depth_column {
  display: none;
}

.depth_column .board_name {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
}

.depth_column .board_name span {
  font-weight: normal;
  margin-left: 14px;
}

.board_main .table_column {
  min-height: 660px;
}

#isMobile .board_main .table_column {
  min-height: auto !important;
}

#isMobile #information.board_main .table_column {
  padding: 20px;
  margin: auto;
  max-width: 450px;
}

.board_main .table_column.board_card_column {
  min-height: 0;
}

.board_main table {
  width: 100%;
}

.board_main table th {
  background-color: #fafafa;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  height: 60px;
  vertical-align: middle;
  font-weight: 500;
  line-height: 3;
  letter-spacing: -0.4px;
}

.board_main table th.day {
  min-width: 82px;
}

#isMobile .board_main table th {
  display: none;
}

.board_main table th.th_day {
  width: 15%;
}

.board_main table tr td {
  border-bottom: 1px solid #cccccc;
}

#isMobile .board_main table tr td {
  border-bottom: 1px solid #eeeeee;
}

.board_main table tr.have_reply td {
  vertical-align: baseline;
}

.board_main table tr.no_visible td,
.board_main table tr.no_visible a,
.board_main table tr.no_visible div:not(.check_reply) {
  color: #cccccc !important;
}

#isMobile .board_main table tr:not(.have_reply) td {
  border-bottom: 1px solid #eeeeee;
}

#isMobile #noticeBoard .board_main table tr:not(.have_reply) td {
  border: none;
}

.board_main table td {
  line-height: 3;
  letter-spacing: -0.4px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
}

#isMobile .board_main table td {
  font-size: 13px;
}

.board_main table td:nth-child(3) {
  text-align: left;
  cursor: pointer;
}

.check_reply {
  width: 60px;
  height: 30px;
  border-radius: 4px;
  background-color: #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 14px;
  line-height: 3.43;
  letter-spacing: -0.35px;
  color: #ffffff;
}

#isMobile .check_reply {
  width: 26px;
  height: 16px;
  font-size: 11px;
  margin: 0 0 0 6px;
}

#isMobile .check_reply.ing {
  background-color: #5c5c5c;
  font-weight: bold;
  width: 90px;
}

.check_reply.finish {
  background-color: #0d6fb8;
  font-weight: bold;
}

.check_reply.ing {
  background-color: #5c5c5c;
  font-weight: bold;
  width: 120px;
}


.board_main table .regdate {
  font-size: 14px;
  line-height: 3.43;
  letter-spacing: -0.35px;
  color: #999999;
}

#isMobile #inquiryBoard table .regdate {
  font-size: 12px;
  line-height: 1.5;
}

.board_main table .regdate.reply_regdate {
  color: #000000;
}

.board_main table .writer_admin,
.board_main table .reply_title {
  font-weight: bold;
  line-height: 3.13;
  letter-spacing: -0.4px;
}

.board_main table .reply_title {
  line-height: 1.7;
}

.board_main table .reply_title .reply_column {
  display: flex;
  align-items: flex-start;
}

.board_main table .reply_title img {
  margin-right: 8px;
}

#isMobile .board_main table .reply_title img {
  width: 20px;
  margin-right: 10px;
}

.board_main .apply_counseling {
  margin-left: auto;
  margin-top: 20px;
  margin-bottom: 34px;
}

.board_main .apply_counseling .go_btn {
  background-color: #0d6fb8;
  margin-top: 0;
}

.pagenation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 129px;
}

#isMobile .pagenation {
  margin: 0 0 24px;
}

#information .pagenation {
  margin-top: 24px;
}

.pagenation .page {
  display: flex;
  align-items: center;
  margin: 0 34px;
  text-align: center;
}

#isMobile .pagenation .page {
  margin: 0 18px;
}

.pagenation .page li {
  font-family: "NanumSquare";
  font-size: 14px;
  line-height: 1.75;
  color: #999999;
  cursor: pointer;
  display: inline-block;
  border-bottom: 3px solid transparent;
  width: 20px;
}

#isMobile .pagenation .page li {
  font-size: 11px;
  width: auto;
}

.pagenation .page li:not(:last-child) {
  margin-right: 30px;
}

#isMobile .pagenation .page li:not(:last-child) {
  margin-right: 18px;
}

.pagenation .page li.active {
  color: #333333;
  border-bottom-color: #000000;
}

.pagenation .page .active a {
  color: #333333 !important;
}

.pagenation .page_first {
  margin-right: 15px;
}

.pagenation .page_last {
  margin-left: 15px;
}

.write_column {
  width: 920px;
  margin: auto;
  padding-top: 72px;
}

#isMobile .write_column {
  width: 100%;
  padding-top: 13px;
}

.write_column.reply_column {
  width: 1000px;
  margin: 45px auto 0;
  background-color: #f1f1f1;
  padding: 48px 40px 75px;
}

#isMobile .write_column.reply_column {
  padding: 19px 0;
}

.write_column .title {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.75px;
}

#isMobile .write_column .title {
  font-size: 18px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  line-height: 1;
}

#isMobile .isMobile_detail .title {
  align-items: flex-start;
  flex-direction: column;
}

#isMobile .isMobile_detail .title span {
  margin-top: 24px;
}

#isMobile .caution_column .title {
  font-size: 12px;
  padding: 0;
}

.write_column .hr {
  width: 100%;
  border-bottom: 2px solid #f1f1f1;
  margin-top: 25px;
  margin-bottom: 37px;
}

#MypageMobile .write_column .strong_hr {
  border-bottom: 4px solid #f1f1f1;
}

#isMobile .write_column .hr {
  margin: 13px 0 0;
}

.write_column form {
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .write_column form {
  padding: 0 20px;
}

.write_column form .form_column {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

#isMobile .write_column form .isMobile_form_column {
  flex-direction: row !important;
}

#isMobile .reply_column form .form_column {
  margin-bottom: 0px;
}

#isMobile .reply_column form .isMobile_title {
  margin-top: 11px;
}

#isMobile .reply_column form .form_column input {
  margin-bottom: 16px;
}

#isMobile .write_column form .isMobile_writer {
  height: 35px;
}

#isMobile .write_column form .form_column:not(.isMobile_writer) {
  flex-direction: column;
  align-items: flex-start;
}

.write_column form .column_name {
  min-width: 117px;
}

#isMobile .write_column form .column_name {
  min-width: 60px;
  font-size: 12px;
  margin-bottom: 6px;
}

#isMobile .isMobile_writer .column_name {
  margin-bottom: 0 !important;
}

.write_column form select {
  width: 300px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .write_column form select {
  width: 100%;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
}

.write_column form .writer {
  height: 50px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

#isMobile .write_column form .writer {
  height: auto;
  font-size: 13px;
  margin-left: 10px;
}

.write_column form textarea {
  font-size: 18px;
  letter-spacing: -0.45px;
  /* width: 800px; */
  width: 100%;
  height: 400px;
  padding: 24px;
}

#isMobile .write_column form textarea {
  font-size: 14px;
  padding: 8px;
  height: 130px;
  border-radius: 4px;
}

.write_column form .write_content {
  align-self: baseline;
}

.write_column .caution_wrapper {
  display: flex;
  align-items: flex-start;
  border-radius: 6px;
  background-color: #f1f1f1;
  padding: 20px 27px 24px;
  margin-top: 28px;
  margin-bottom: 38px;
}

#isMobile .write_column .caution_wrapper {
  padding: 11px 10px 24px;
  margin-top: 12px;
  margin-bottom: 32px;
}

#isMobile .write_column .caution_wrapper img {
  width: 13px;
}

.write_column .caution_column {
  margin-left: 12px;
}

#isMobile .write_column .caution_column {
  margin-left: 3px;
}

.write_column .caution_column .title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.45px;
  color: #666666;
}

.write_column .caution_column ul {
  margin-top: 10px;
  margin-bottom: 5px;
}

#isMobile .write_column .caution_column ul {
  margin-top: 4px;
}

.write_column .caution_column li {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  color: #666666;
}

.write_column .caution_column li:first-child {
  font-weight: bold;
}
#isMobile .write_column .caution_column li {
  font-size: 12px;
}

.write_column .caution_column .checkbox_column {
  display: flex;
  align-items: center;
}

.write_column .caution_column .checkbox_wrap {
  position: relative;
}

.write_column .caution_column label {
  font-size: 20px;
  line-height: 1;
  letter-spacing: -0.5px;
}

.write_column .checkbox_column .warning {
  margin-left: 7px;
}

.write_column form .btn_column {
  display: flex;
  justify-content: center;
  margin-bottom: 123px;
}

#isMobile .write_column form .btn_column {
  margin-bottom: 26px;
}

.write_column form .btn_column .close {
  margin-right: 13px;
}

.go_list {
  width: 160px;
  height: 50px;
  border-radius: 10px;
  border: solid 2px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.9px;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 46px auto 30px;
}

#isMobile .go_list {
  width: 100%;
  max-width: 400px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  margin: 21px auto 32px;
}

#isMobile #noticeBoard .go_list {
  width: 80px;
  margin: 0;
}

#isMobile .isMobile_go_list_wrapper {
  width: 100%;
  padding: 0 20px;
}

.board_card_column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.board_card_column .board_card {
  margin-bottom: 50px;
}

#isMobile .board_card_column .board_card {
  margin-bottom: 10px;
}

.board_card .board_card_img {
  width: 380px;
  height: 380px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

#isMobile .board_card .board_card_img {
  min-width: 156px;
  width: 26vw;
  min-height: 100px;
  height: 16vw;
}

.board_card .board_card_img img {
  width: 100%;
  height: 100%;
}

.board_card .board_card_info {
  width: 380px;
  height: 160px;
  background-color: #f1f1f1;
  padding: 16px 26px 0;
}

#isMobile .board_card .board_card_info {
  min-width: 156px;
  width: 26vw;
  padding: 6px 10px 0;
  height: 140px;
}

.board_card .board_card_title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.45px;
}

#isMobile .board_card .board_card_title {
  font-size: 14px;
  letter-spacing: -1.45px;
}

.board_card .board_card_desc {
  height: 54px;
  line-height: 1.88;
  letter-spacing: -0.4px;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin: 6px 0;
}

#isMobile .board_card .board_card_desc {
  font-size: 11px;
  line-height: 1.7;
  -webkit-line-clamp: 3;
  margin: 0;
}

.board_card .board_card_wrapper {
  display: flex;
  align-items: center;
}

.board_card_date,
.board_card_writer {
  font-size: 14px;
  letter-spacing: -0.35px;
  line-height: 2.14;
  color: #666666;
}

#isMobile .board_card_date,
#isMobile .board_card_writer {
  font-size: 11px;
}

.board_card_wrapper .bar {
  border-right: solid 1px #707070;
  margin: 0 7px;
  height: 16px;
}

#informationDetail {
  width: 920px;
  padding: 72px 0 120px;
  margin: auto;
}

#isMobile #informationDetail {
  justify-content: flex-start;
}

#isMobile #informationDetail .body_column img {
  width: 100%;
}

#isMobile #informationDetail .detail_title {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#isMobile #informationDetail .detail_title span {
  margin-top: 24px;
}

#isMobile .header_column .right {
  width: 100%;
  font-size: 12px;
}

#isMobile #informationDetail .isMobile_detail {
  display: flex;
  flex-direction: column;
  padding-top: 13px;
}

#isMobile #informationDetail .btn_column .btn {
  font-size: 13px;
}

#isMobile #informationDetail .go_list {
  width: 80px;
  margin: 0 auto;
}

.header_column {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 25px;
  margin-bottom: 30px;
}

.header_column .detail_title {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.75px;
}

#isMobile .header_column .detail_title {
  font-size: 18px;
}

.header_column .right {
  display: flex;
  align-items: center;
  line-height: 1.75;
  letter-spacing: -0.4px;
  color: #666666;
}

.body_column .detail_content {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.45px;
  padding: 37px 0 52px;
  border-bottom: 2px solid #f1f1f1;
  white-space: pre-line;
}

#informationDetail .body_column {
  width: 100%;
  height: 80%;
}

#isMobile .body_column .detail_content {
  font-size: 13px;
  padding: 16px 0 24px;
}

#informationDetail .btn_column {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile #informationDetail .btn_column {
  margin-top: 24px;
}

#informationDetail .btn_column .prev {
  margin-right: 17px;
}

#isMobile #informationDetail .btn_column img {
  width: 6px;
}

#isMobile #informationDetail .btn_column .prev {
  margin-right: 8px;
}

#informationDetail .btn_column .next {
  margin-left: 17px;
}

#isMobile .board_main table td {
  height: auto;
}

#isMobile .isMobil_tr .num {
  font-size: 14px;
  width: 45px;
  padding-left: 20px !important;
  vertical-align: baseline;
}

#isMobile .isMobil_tr td div {
  line-height: 1.5;
}

#isMobile .isMobil_tr td {
  text-align: left;
  padding: 16px 0px 16px 0 !important;
}

#isMobile .isMobil_tr.have_reply td {
  padding-top: 0 !important;
}

#isMobile #myNotice .isMobil_tr td {
  padding-left: 20px !important;
}

#isMobile .isMobil_tr td .title {
  font-weight: bold;
}

#isMobile .isMobil_tr td .wrapper {
  display: flex;
  align-items: center;
}

#isMobile .isMobil_tr td .bar {
  width: 1px;
  height: 13px;
  background-color: #000000;
  margin: 0 6px;
}

#isMobile .isMobil_tr td .reply_column {
  display: flex;
  align-items: flex-start;
}

#isMobile .isMobil_tr td .reply_column img {
  width: 20px;
  margin-right: 10px;
}

.isMobile_back {
  display: none;
}

#isMobile .isMobile_back {
  display: inline-block;
  width: 8px;
}

.have_re td {
  vertical-align: baseline !important;
}

.page_link:not(:last-child) {
  margin-right: 16px;
}

#informationDetail .detail_content_column {
  width: 100%;
  height: 100%;
}

.mypage_main {
  width: 62.4%;
  margin: auto;
}

.mypage_header {
  width: 100%;
  border-bottom: 2px solid #cccccc;
}

.mypage_body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 27px 0 66px;
}

#isMobile .mypage_body {
  padding: 0;
}

.mypage_body .side_menu {
  width: 200px;
  height: 880px;
  border-radius: 20px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-right: 50px;
  padding: 16px 30px 0;
}

#MypageMobile .mypage_body .side_menu {
  height: auto;
}

#isMobile .mypage_body .side_menu {
  width: 100vw;
  margin-right: 0;
  border: none;
  padding: 0;
}

.mypage_body .side_menu h2 {
  font-size: 18px;
  font-weight: bold;
  line-height: 2.22;
  letter-spacing: -0.45px;
  display: flex;
  align-items: center;
}

.mypage_body .side_menu ul {
  border-bottom: 1px solid #dddddd;
}

.mypage_body .side_menu li {
  line-height: 2.4;
  letter-spacing: -0.4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#MypageMobile {
  padding-bottom: 100px;
}

#MypageMobile .mypage_body .side_menu li {
  justify-content: space-between;
  padding: 0 36px;
}

#MypageMobile ul:first-child a:first-child {
  display: none;
}

.mypage_body .side_menu .dot {
  width: 8px;
  height: 8px;
  background-color: #056fb8;
  border-radius: 50%;
  margin-right: 13px;
  visibility: hidden;
}

#MypageMobile .mypage_body .side_menu .dot {
  display: none;
}

.mypage_body .side_menu ul {
  padding-bottom: 24px;
}

#MypageMobile .mypage_body .side_menu ul {
  border-bottom: 4px solid #f1f1f1;
}

#MypageMobile .mypage_body .side_menu ul:last-child {
  border-bottom: none;
}

#MypageMobile .mypage_body .side_menu ul {
  padding: 12px 0;
}

.mypage_body .side_menu ul:not(:nth-child(1)) {
  padding-top: 24px;
}

.mypage_body .side_menu ul:not(:nth-child(2)) {
  padding-top: 24px;
}

.mypage_body .side_menu li.active {
  font-weight: bold;
}

.mypage_body .side_menu li.active .dot,
.mypage_body .side_menu .inquiry_wrapper.active .dot {
  visibility: visible;
}

.mypage_body .side_menu .inquiry_wrapper {
  display: flex;
  margin-top: 28px;
}

.mypage_body .side_menu .inquiry_wrapper .dot {
  margin-top: 20px;
}

.mypage_body .inquiry_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 32px 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.mypage_body .inquiry_icon {
  position: relative;
}

.mypage_body .inquiry_count {
  width: 30px;
  height: 20px;
  border-radius: 10px;
  background-color: #056fb8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.3px;
  color: #ffffff;
  margin-left: 8.6px;
}

.mypage_body .inquiry_icon .inquiry_count {
  position: absolute;
  top: 7px;
  right: -20px;
}

.mypage_body .inquiry_count {
  position: static;
}

.mypage_body .inquiry_column img {
  margin-top: 7px;
}

.mypage_body .logout_btn {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mypage_body .logout_btn img {
  margin-right: 10px;
}

.mypage_body .content {
  width: 100%;
}

.mypage_body .content h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
  padding-bottom: 23px;
  border-bottom: 2px solid #eeeeee;
}

#isMobile .mypage_body .content h2 {
  font-size: 17px;
  height: 54px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-width: 1px;
}

#isMobile #InquiryWrite .mypage_body .content h2 {
  justify-content: space-between;
}

#isMobile #InquiryWrite .mypage_body .content img {
  width: 14px;
}

#isMobile #mypageModify .mypage_body .content h2 {
  border: none;
  padding: 0;
}

.profile_wrapper {
  width: 620px;
  margin: auto;
}

.profile_column {
  display: flex;
  padding-left: 48px;
  margin-top: 43px;
}

.profile_column .profile {
  border-radius: 50%;
  width: 160px;
  height: 160px;
  margin-right: 40px;
}

#isMobile #MypageMobile .profile_column .profile {
  width: 80px;
  height: 80px;
  margin-right: 16px;
}

.profile_name {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.45px;
  line-height: 1.25;
  margin-bottom: 4px;
}

#isMobile .profile_name {
  font-size: 14px;
}

.profile_name strong {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
}

#isMobile .profile_name strong {
  font-size: 17px;
}

.profile_email {
  display: flex;
  align-items: center;
}

#isMobile .profile_email {
  font-size: 13px;
}

.profile_email img {
  margin-right: 8px;
}

#isMobile .profile_email img {
  width: 14px;
  margin-right: 4px;
}

.modify_btn {
  width: 120px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #056fb8;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 21px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #056fb8;
}

.modify_btn img {
  margin-right: 4px;
}

.profile_wrapper .btns_column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 74px;
}

.profile_wrapper .btns {
  width: 300px;
  height: 80px;
  border-radius: 10px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 32px;
}

.profile_wrapper .btns_column .btn_name {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
}

.profile_wrapper .btns_column .btn_count {
  font-size: 18px;
  font-weight: normal;
  line-height: 2.67;
  letter-spacing: -0.45px;
}

.profile_wrapper .btns_column .btn_count strong {
  font-weight: bold;
}

.profile_wrapper .btns_column .warning {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.35px;
  color: #d51a1a;
}

.add_tab_column {
  margin-top: 41px;
  border-bottom: 1px solid #dddddd;
  display: flex;
}

#isMobile .add_tab_column {
  margin-top: 0;
  padding-top: 6px;
  background-color: #eeeeee;
  border: none;
}

.add_tab_column .add_btn,
.add_tab_column .address_tab {
  width: 200px;
  height: 50px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: -0.8px;
  cursor: pointer;
}

.add_tab_column .address_tab {
  background-color: #ffffff;
}

#isMobile .add_tab_column .add_btn,
#isMobile .add_tab_column .address_tab {
  font-size: 15px;
  width: 100px;
  height: 46px;
  background-color: #dddddd;
}

#isMobile .add_tab_column .address_tab {
  background-color: #dddddd;
}

.add_tab_column .address_tab .tab_name {
  height: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: -0.9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 6px solid transparent;
}

#isMobile .add_tab_column .address_tab .tab_name {
  font-size: 15px;
  width: 100%;
  border: none;
}

.add_tab_column .address_tab .tab_name.active {
  border-bottom: 6px solid #056fb8;
}

#isMobile .add_tab_column .tab_name.active {
  background-color: #ffffff;
}

.add_address_column .btn_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
}

.add_address_column .btn_column .add_address_btn {
  margin-bottom: 24px;
}

#isMobile .add_address_column .btn_column .add_address_btn {
  width: 80px;
}

#isMobile .add_info {
  font-size: 15px;
}

.add_address_column .add_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add_address_column .add_content .info {
  font-size: 18px;
  line-height: 2.67;
  letter-spacing: -0.45px;
  margin-top: 30px;
}

.add_content {
  width: 700px;
  margin: 0 auto;
}

#isMobile .add_content {
  width: 100vw;
  max-width: 450px;
  padding: 0 20px;
}

.add_content .top {
  width: 100%;
  margin-top: 52.8px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 34px;
}

#isMobile .add_content .top {
  margin-top: 25px;
}

.add_content h3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.5px;
  margin-bottom: 34px;
}

#isMobile .add_content h3 {
  font-size: 14px;
  margin-bottom: 18px;
}

.add_content .column {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

#isMobile .add_content .column {
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 0;
}

#isMobile #mypageAddress .add_content .column.save {
  margin-top: 24px;
  margin-bottom: 24px;
}

#isMobile .add_content .isMobile_column {
  flex-direction: row;
  align-items: center;
}

#isMobile .add_content .bottom .isMobile_column {
  margin-bottom: 16px;
}

.add_content .column.save {
  flex-direction: column;
  margin-top: 85px;
}

.add_content .column .input_name {
  width: 188px;
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .add_content .column .input_name {
  font-size: 13px;
  margin-bottom: 6px;
}

#isMobile .add_content .isMobile_column .input_name {
  margin-bottom: 0;
}

.add_content .column .warning {
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
  color: #dd1818;
}

#isMobile .add_content .column .warning {
  font-size: 12px;
  margin-bottom: 4px;
}

#isMobile .add_content .column.save .warning {
  margin: auto;
}

.add_content .top .column input {
  width: 400px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  padding: 0 26px;
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .add_content .top .column input {
  width: 100%;
  height: 40px;
  font-size: 15px;
  padding: 0 16px;
  border-radius: 4px;
}

#isMobile .add_content .top .column input.addr_code {
  width: 65%;
}

.add_content .column .input_column {
  display: flex;
  align-items: center;
}

.add_content .column .input_column .simple_addr{
  width: 133px;
}

#isMobile .add_content .column .input_column {
  width: 100%;
}

.add_content .own_radio_container {
  margin: 20px 0 35px;
}

.add_content .own_radio_container2 {
  margin: 15px 0 35px;
}

#isMobile .add_content .own_radio_container {
  margin: 22px 0 30px;
}

#isMobile .add_content .own_radio_container2 {
  margin: 15px 0 15px;
}


.add_content .own_radio_column {
  display: flex;
}

.add_content .own_radio_column .left {
  margin-right: 28px;
}

#isMobile .radio_wrap {
  position: relative;
}

.add_content .own_radio_column span {
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .add_content .own_radio_column span {
  font-size: 14px;
}

.add_content .column__on-off {
  display: flex;
}

.alarm_btn {
  width: 100px;
  height: 36px;
  border-radius: 18px;
  border: solid 1px #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile .alarm_btn {
  width: 74px;
  height: 22px;
  font-size: 11px;
}

.alarm_btn img {
  margin-right: 6px;
}

#isMobile .alarm_btn img {
  width: 8px;
  margin-right: 4px;
}

.add_content .column__on-off {
  width: auto;
  margin-right: 16px;
}

.add_content .bottom {
  width: 100%;
  margin-top: 45px;
}

#isMobile .add_content .bottom {
  margin-top: 26px;
}

.add_content .bottom .calendar {
  background-color: #ffffff !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABHNCSVQICAgIfAhkiAAAAeVJREFUSEvtlkFWwkAMQJPSPtmJJxBvwBHoRtudngC4AJYb6A0GPID1BOKqPDfgDbgBeAOW+tpOzAwMYl+R4cFz5ew6k+Qn6UwShJLldpM73m5mg7BZdl7cc6OkiYRjQvIzEU6K5/inkEqUtNmbFlOtvN8WIQEs+GwCSH0TlY7E6yYxILaI4AWApipVfFBnhdgmXUpW6QPRE+vM+bvN3+cSqZOLMEYVgUP4KBFvcnE1VEZ1VBIb2SCIbCAQvTZcmYvMqUQgLpWTa8dTpAv0uiPepGk6CNtWBm2FonHNlZ9zti3Qux0RSOilD4Gw1beV41vKNw0XSwjnngiZetyFSG0iejeQ41rfsMaQNw3Z9ogOJS/TxQn7h2ymcvWYKe0Hnc39o6XLVAvzuo8O+Q2gYNaRqLKfOVUBwleFb712AewhUVJ3JU4RYZbiiW9ANgB7iJLUxU9ODMiTH0JV3LJ/UHxX1unSiisQIBCX8ZoNYL9IjHsM8qSMpUNC9QibavAzEqJ77ud3Nor7yCgIRz5HtzsachpOs37g72NgpyxfGo9wplKLZtJgpZhvUK94VXcaKxNYAp65HZ+lTrWhe7xpwashQLfPQ5YeRriPpE7lWrXj75FIvwkeAA6cVtQ/kA5McqgOTVa+AO9UWFutVmQKAAAAAElFTkSuQmCC) no-repeat 255px 50%;
  cursor: pointer;
}

#isMobile #mypageAddress .add_content .bottom .calendar {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABHNCSVQICAgIfAhkiAAAAeVJREFUSEvtlkFWwkAMQJPSPtmJJxBvwBHoRtudngC4AJYb6A0GPID1BOKqPDfgDbgBeAOW+tpOzAwMYl+R4cFz5ew6k+Qn6UwShJLldpM73m5mg7BZdl7cc6OkiYRjQvIzEU6K5/inkEqUtNmbFlOtvN8WIQEs+GwCSH0TlY7E6yYxILaI4AWApipVfFBnhdgmXUpW6QPRE+vM+bvN3+cSqZOLMEYVgUP4KBFvcnE1VEZ1VBIb2SCIbCAQvTZcmYvMqUQgLpWTa8dTpAv0uiPepGk6CNtWBm2FonHNlZ9zti3Qux0RSOilD4Gw1beV41vKNw0XSwjnngiZetyFSG0iejeQ41rfsMaQNw3Z9ogOJS/TxQn7h2ymcvWYKe0Hnc39o6XLVAvzuo8O+Q2gYNaRqLKfOVUBwleFb712AewhUVJ3JU4RYZbiiW9ANgB7iJLUxU9ODMiTH0JV3LJ/UHxX1unSiisQIBCX8ZoNYL9IjHsM8qSMpUNC9QibavAzEqJ77ud3Nor7yCgIRz5HtzsachpOs37g72NgpyxfGo9wplKLZtJgpZhvUK94VXcaKxNYAp65HZ+lTrWhe7xpwashQLfPQ5YeRriPpE7lWrXj75FIvwkeAA6cVtQ/kA5McqgOTVa+AO9UWFutVmQKAAAAAElFTkSuQmCC) no-repeat 95% 50%;
}

.add_content .bottom input {
  width: 300px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  font-size: 18px;
  letter-spacing: -0.45px;
  padding: 0 24px;
}

#isMobile .add_content .bottom input {
  width: 100%;
  height: 40px;
  font-size: 14px;
}

.add_content .check_btn .delete_icon {
  margin-right: 6px;
}

#isMobile .add_content .check_btn .delete_icon {
  width: 10px;
}

.add_content .bottom .notice_cycle {
  width: 300px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.45px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile .add_content .bottom .notice_cycle {
  font-size: 14px;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
}

#mypageAddress .save_btn_column {
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile #mypageAddress .save_btn_column {
  width: 100%;
}

.add_content .save_btn_column .close {
  margin-right: 20px;
}

.add_content .address_num_info {
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
  color: #666666;
}

.add_content .see_more {
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #056fb8;
  text-decoration: underline;
  margin-left: 8px;
  cursor: pointer;
}

#isMobile .add_content .see_more {
  font-size: 90%;
}

#mypageAddress .num_modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.6px;
}

#mypageAddress .num_modal .manage_num_list {
  width: 100%;
  background-color: #f1f1f1;
  padding: 18px 24px;
  margin: 18px 0 23px;
  line-height: 1.88;
}

.mypage_body .content.board_main h2 {
  border-bottom: none;
}

#isMobile .mypage_body .content.board_main h2 {
  border-bottom: 1px solid #eeeeee;
  width: 100%;
}

#isMobile .mypage_body .content.board_main h2.isMobile_inquiryBoard_h2 {
  border-bottom: none;
}

#isMobile .mypage_body .content.board_main h2.isMobile_detail_h2 {
  border: none;
}

#isMobile .mypage_body .content.board_main .detail_title_column h2 {
  border: none;
  font-size: 20px;
  padding: 0;
}

.mypage_body .content h2 .count {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.45px;
  color: #056fb8;
}

#isMobile .mypage_body .content h2 .count {
  margin-left: 4px;
}

.mypage_body .board_main .table_column {
  min-height: 0;
  margin-bottom: 55px;
}

/* 이벤트 페이지 사진 크기 임의 조정!!! */
/* .mypage_body .board_main .table_column img {
  width: 120px;
  height: 120px;
} */

.title_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile .title_column {
  align-items: flex-start;
  flex-direction: column;
}

#isMobile #myNotice .title_column {
  border-bottom: 1px solid #eeeeee;
}

.all_confirm {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #1f7ec0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile .all_confirm {
  width: 94px;
  height: 34px;
  align-self: flex-end;
  font-size: 12px;
  margin: 12px 20px;
}

.all_confirm img {
  margin-left: 20px;
}

#isMobile .all_confirm img {
  margin-left: 4px;
  width: 14px;
}

.board_main table .confirm {
  letter-spacing: -0.4px;
  color: #999999;
}

#isMobile .board_main table .not_confirm {
  font-weight: bold;
}

.board_main table .confrim_notice td {
  color: #999999;
}

#myNotice .board_main table th:nth-child(3) {
  width: 50%;
  text-align: left;
}

.notice_modal {
  padding: 45px 35px 47px;
}

.notice_modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.6px;
}

.notice_modal .notice_desc {
  width: 100%;
  height: 200px;
  overflow-y: auto;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #666666;
  margin: 20px 0;
}

/* 스크롤바 */
.notice_modal ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.notice_modal ::-webkit-scrollbar-track {
  background-color: #dddddd;
}
.notice_modal ::-webkit-scrollbar-thumb {
  border-radius: 23px;
  background: #999999;
}
.notice_modal ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

#isMobile #myNoticeSetting {
  padding: 0;
}

#myNoticeSetting .all_check {
  display: flex;
  align-items: center;
  padding: 38px 0;
  border-bottom: 1px solid #dddddd;
}

#isMobile #myNoticeSetting .myNoticeSetting_table {
  display: flex;
  display: -ms-flexbox;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 24px 0;
}

#isMobile .myNoticeSetting_table tbody {
  display: flex;
}

#isMobile .myNoticeSetting_table th,
#isMobile .myNoticeSetting_table td {
  display: block;
}

#myNoticeSetting .input_name {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: -0.45px;
}

#isMobile #myNoticeSetting .input_name {
  font-size: 14px;
}

#myNoticeSetting .all_check .input_name {
  margin-right: 25px;
}

#myNoticeSetting table {
  width: 100%;
  margin-top: 43px;
}

#myNoticeSetting table th {
  font-weight: 500;
  letter-spacing: -0.4px;
  width: 16.66%;
  height: 46px;
}

#isMobile #myNoticeSetting table th {
  font-size: 14px;
  font-weight: normal;
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: left;
}

#myNoticeSetting table td {
  height: 80px;
}

#isMobile .isMobile_radio_wrapper {
  width: 100%;
  padding: 0 20px;
}

#isMobile #myNoticeSetting table td {
  height: 40px;
  display: flex;
}

#myNoticeSetting table .name {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: -0.45px;
}

#isMobile #myNoticeSetting table .name {
  font-size: 14px;
  width: 130px !important;
}

#myNoticeSetting table .column__on-off {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

#myNoticeSetting .warning {
  letter-spacing: -0.4px;
  color: #d90e0e;
}

#isMobile #myNoticeSetting .warning {
  font-size: 12px;
}

#isMobile #noticeBoard {
  padding: 0;
}

#noticeBoard .board_main table td:nth-child(3) {
  text-align: center;
}

#isMobile #noticeBoard .content h2 {
  font-size: 17px;
  height: 54px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-width: 1px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
}

#isMobile #noticeBoard .board_main table td:nth-child(3) {
  text-align: left;
}

#noticeBoard .board_main table th:nth-child(2),
#noticeBoard .board_main table td:nth-child(2) {
  text-align: left;
  width: 70%;
}

#isMobile #noticeBoard .board_main table th:nth-child(2),
#isMobile #noticeBoard .board_main table td:nth-child(2) {
  width: 100%;
}

#noticeBoard .board_main table td:nth-child(2) div {
  cursor: pointer;
}

#noticeBoard .search_reslt {
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: #666666;
  margin: 12px 0 17px;
}

#isMobile #noticeBoard .search_reslt,
#isMobile #frequentlyBoard .search_reslt {
  font-size: 13px;
  padding: 0 20px;
}

#noticeBoard .search_reslt strong {
  font-weight: 500;
  color: #000000;
}

.search_condition_column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search_condition_column select {
  width: 140px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  font-size: 16px;
  letter-spacing: -0.4px;
  color: #000000;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAYAAADwdn+XAAAABHNCSVQICAgIfAhkiAAAAOJJREFUKFNj1NTUnPL79++qO3fufGIgAWhras65ev16CiNQD6OWpuZzBkZGs2vXrj0ixgwtLa1N////n3/9+vX1IAPAAGjIjT9//4bfunXrIj5DtDQ0jvxnZGwAat4DUgc3AGrI8X///1fcuHHjIDZDgJZc+c/AEAfUfA4mj2IA2BANje1AG2YCFW1ANgSo+envP39sb9++fQ9ZHMMAkCQwYJcx/vu3+9rNm/Pl5OQEebm5n37++lX60aNH79FdhtUAqCFTgQG1j4mRcdK169elcYULTgNAGjQ0NNqB4VGJL1ABvRReCRJb2cEAAAAASUVORK5CYII=) no-repeat 110px 50% !important;
  margin-right: 10px;
}

#isMobile .search_condition_column select.left {
  margin-right: 10px;
}

#isMobile .search_condition_column select {
  width: 50%;
  height: 40px;
  border-radius: 4px;
  margin-right: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAYAAADwdn+XAAAABHNCSVQICAgIfAhkiAAAAOJJREFUKFNj1NTUnPL79++qO3fufGIgAWhras65ev16CiNQD6OWpuZzBkZGs2vXrj0ixgwtLa1N////n3/9+vX1IAPAAGjIjT9//4bfunXrIj5DtDQ0jvxnZGwAat4DUgc3AGrI8X///1fcuHHjIDZDgJZc+c/AEAfUfA4mj2IA2BANje1AG2YCFW1ANgSo+envP39sb9++fQ9ZHMMAkCQwYJcx/vu3+9rNm/Pl5OQEebm5n37++lX60aNH79FdhtUAqCFTgQG1j4mRcdK169elcYULTgNAGjQ0NNqB4VGJL1ABvRReCRJb2cEAAAAASUVORK5CYII=) no-repeat 90% 50% !important;
  font-size: 13px;
  padding: 0 16px;
}

.isMobile_select_wrapper {
  display: flex;
}

#isMobile .isMobile_select_wrapper {
  width: 100%;
}

#isMobile .search_condition_column {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.search_condition_column .search_input {
  width: 400px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px 0 26px;
}

#isMobile .search_condition_column .search_input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-top: 10px;
  padding: 0 16px;
}

#isMobile .search_condition_column .search_input input {
  font-size: 13px;
}

#isMobile .search_condition_column .search_input img {
  width: 18px;
}

.search_condition_column .search_input input {
  width: 90%;
}

#noticeBoard .search_warning {
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
  text-align: center;
  color: #e40f0f;
  margin-top: 6px;
}

#isMobile #noticeBoard .search_warning,
#isMobile #frequentlyBoard .search_warning {
  font-size: 12px;
}

#isMobile .noticeBoard_table thead {
  display: none;
}

#isMobile .noticeBoard_table tr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;
  border-bottom: 1px solid #eeeeee;
}

#isMobile .noticeBoard_table td {
  line-height: 1.5;
  border: none;
  width: 100%;
  text-align: left;
}

#isMobile .noticeBoard_table td:nth-child(2) {
  font-weight: bold;
  font-size: 15px;
}

.detail_title_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #eeeeee;
  border-bottom: 2px solid #f1f1f1 !important;
  padding: 24px 0;
}

#isMobile .detail_column {
  padding: 0 20px;
}

#isMobile .detail_title_column {
  border: none;
  flex-direction: column;
  align-items: flex-start;
}

.detail_title_column h2 {
  padding-bottom: 0 !important;
}

.detail_title_column .regdate {
  line-height: 1.75;
  letter-spacing: -0.4px;
  color: #666666;
}

#isMobile .detail_title_column .regdate {
  font-size: 14px;
  margin-top: 10px;
}

.detail_content_column {
  width: 100%;
  min-height: 440px;
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.45px;
  padding: 34px 0;
  border: none;
  border-bottom: 2px solid #f1f1f1;
}

#isMobile .detail_content_column {
  font-size: 13px;
  min-height: auto;
}

.detail_column .btn_column {
  display: flex;
  align-items: center;
}

#isMobile .detail_column .btn_column {
  font-size: 13px;
  justify-content: space-between;
  margin-top: 16px;
}

.detail_column .btn_column .prev {
  margin-right: 17px;
}

.detail_column .btn_column .next {
  margin-left: 17px;
}

.mypage_tab_menu {
  display: flex;
  justify-content: space-around;
  margin: 51px 0 19px;
}

#isMobile .mypage_tab_menu {
  padding: 20px 0;
  margin: 0;
  justify-content: space-around;
  border-bottom: 1px solid #eeeeee;
}

#agreement .mypage_tab_menu {
  margin-bottom: 34px;
}

#isMobile #agreement .mypage_tab_menu {
  margin-bottom: 0;
  border-bottom: none;
}

.mypage_tab_menu .tab {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: -0.45px;
  padding: 0 0 !important;
  cursor: pointer;
}

#agreement .mypage_tab_menu .tab {
  padding: 0;
}

#isMobile #agreement .mypage_tab_menu .tab {
  color: #999999;
  font-size: 15px;
}

#isMobile .mypage_tab_menu .tab:first-child {
  margin-left: 0;
  margin-right: 0;
}

.mypage_tab_menu .tab.active {
  border-bottom: 6px solid #056fb8;
}

#isMobile .mypage_tab_menu .tab.active {
  border-bottom: 3px solid #056fb8;
  padding: 0;
  color: #000000 !important;
}

#ongoing table th:last-child {
  width: 170px;
}

.mypage_tab_menu .tab span {
  font-size: 14px;
  letter-spacing: -0.35px;
}

.event_table table td {
  padding: 20px 0;
  line-height: 1;
}

.event_table table .period {
  font-size: 14px;
  letter-spacing: -0.35px;
}

.event_table table th:nth-child(3) {
  text-align: left;
}

.agreement_desc {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.4px;
  border-top: 1px solid #eeeeee;
  padding: 35px 0;
  resize: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  max-height: 700px;
  height: 100%;
}

#isMobile .agreement_desc {
  font-size: 13px;
  border: none;
  padding: 0 20px;
  height: calc(100vh - 200px);
  overflow-x: hidden;
}

#mypageModify .profile_wrapper {
  width: 559px;
}

#isMobile #mypageModify .profile_wrapper {
  width: 100%;
}

#mypageModify .profile_column {
  width: 540px;
  padding-left: 0;
  padding-bottom: 36px;
  border-bottom: 1px solid #dddddd;
}

#isMobile #mypageModify .profile_column {
  width: 100%;
  border: none;
}

#isMobile .profile_column {
  padding: 0 20px;
  margin: 34px auto;
  align-items: center;
  flex-direction: column;
}

#isMobile #MypageMobile .profile_column {
  flex-direction: row;
}

#mypageModify .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 54px;
  height: auto;
}

#isMobile #mypageModify .profile {
  margin-right: 0;
  margin-bottom: 16px;
}

#mypageModify .profile img {
  margin-bottom: 17px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
}

#isMobile #mypageModify .profile img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

#isMobile #mypageModify .profile .file_name {
  font-size: 12px;
  text-align: center;
}

.profile_btn_column {
  display: flex;
  align-items: center;
}

.profile_btn_column .btn {
  width: 120px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #056fb8;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #056fb8;
}

#isMobile .profile_btn_column .btn {
  width: 86px;
  height: 40px;
  border-radius: 4px;
  font-size: 13px;
}

.profile_btn_column .add_picture {
  margin-right: 10px;
}

.profile_btn_column #add_picture {
  display: none;
}

.profile_btn_column img {
  margin-right: 7px;
}

#isMobile .profile_btn_column img {
  height: 11px;
}

form.modify_mypage {
  padding: 33px 0 42px;
}

#isMobile form.modify_mypage {
  padding: 0 0 30px;
}

form.mypage .column {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

#isMobile form.mypage .column {
  flex-direction: column;
  align-items: flex-start;
}

#isMobile form.mypage .isMobile_column_chk,
#isMobile form.mypage .isMobile_column_ad {
  margin-bottom: 0;
}

#isMobile form.mypage .column.isMobile_column {
  flex-direction: row;
  align-items: center;
}

#isMobile form.mypage .column.isMobile_column_ad .input_name {
  width: auto;
}

form.mypage .column.warning_column {
  margin-bottom: 0;
}

form.mypage .column .input_column {
  align-items: center;
}

#isMobile form.mypage .column .input_column {
  width: 100%;
}

form.mypage .column .input_name {
  width: 122px;
}

#isMobile form.mypage .column .input_name {
  width: 45px;
  font-size: 12px;
  margin-bottom: 10px;
}

#isMobile form.mypage .isMobile_column_pw .input_name {
  width: 70px;
}

#isMobile form.mypage .column.isMobile_column .input_name {
  margin-bottom: 0;
}

form.mypage .input_info {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
}

#isMobile form.mypage .input_info {
  font-size: 13px;
}

form.mypage input {
  width: 300px;
}

.certify_column {
  margin: 13px 0 9px;
}

#isMobile .certify_column {
  margin: 0 0 9px;
}

.tel_certify_column {
  display: flex;
  margin-right: 11px;
}

.tel_certify {
  width: 80px;
  height: 30px;
  background-color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile .tel_certify {
  font-size: 12px;
  width: 66px;
  height: 26px;
}

.tel_certify.active {
  background-color: #056fb8;
}

.tel_certify_column .ok {
  border-radius: 4px 0 0 4px;
}

.tel_certify_column .no {
  border-radius: 0 4px 4px 0;
}

.check_btn.change_password {
  margin-left: 0;
}

#mypageModify .own_radio_column {
  display: flex;
}

#mypageModify .own_radio_column .radio_wrap.left {
  margin-right: 28px;
}

#isMobile #mypageModify .own_radio_column .radio_wrap.left {
  margin-right: 14px;
}

#mypageModify .own_radio_column .radio_wrap,
#mypageModify .modify_mypage .checkbox_wrap {
  position: relative;
}

#mypageModify .own_radio_column .info {
  margin-left: 16px;
  color: #666666;
}

#isMobile #mypageModify .own_radio_column .info {
  padding-top: 2px;
}

#mypageModify .checkbox_coloumn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 434px;
}

#mypageModify .withdrawal_modal .checkbox_coloumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#isMobile #mypageModify .checkbox_coloumn {
  width: 90%;
}

#isMobile #mypageModify .withdrawal_modal .checkbox_coloumn {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

#mypageModify .checkbox_wrap:last-child {
  text-align: end;
}

.mypage_modify_info {
  width: 100%;
  border-radius: 6px;
  background-color: #f1f1f1;
  padding: 24px 26px;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.35px;
  color: #666666;
}

#isMobile .mypage_modify_info {
  padding: 15px 10px;
  font-size: 12px;
  border-radius: 4px;
}

.withdrawal {
  margin: 47px auto 60px;
  background-color: #999999;
}

#isMobile .withdrawal {
  width: 140px;
  margin: 16px auto 0;
}

#isMobile #frequentlyBoard table tr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#isMobile #frequentlyBoard table td {
  width: 100%;
  text-align: left;
  padding: 0 20px !important;
  line-height: 1.8;
}

#isMobile #frequentlyBoard table td:first-child {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px !important;
}

#isMobile #frequentlyBoard table td:nth-child(2) {
  padding-bottom: 16px !important;
  font-weight: bold;
}

#isMobile #frequentlyBoard table td:nth-child(2) .answer {
  font-weight: normal;
}

#isMobile #frequentlyBoard table td:nth-child(2) .answer_column img {
  width: 20px;
}

#isMobile #frequentlyBoard table td:nth-child(2) .answer_column {
  margin-bottom: 0;
}

#frequentlyBoard .answer_column {
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
}

#frequentlyBoard .answer_column .answer {
  line-height: 1.5;
  letter-spacing: -0.4px;
  margin-left: 16px;
}

#frequentlyBoard .board_main table th:nth-child(1) {
  width: 15%;
}

#frequentlyBoard .board_main table td:nth-child(1) {
  vertical-align: baseline;
}

#frequentlyBoard .board_main table th:nth-child(2) {
  width: 72%;
}

#frequentlyBoard .board_main table td:nth-child(2) {
  text-align: left;
}

#frequentlyBoard .board_main table td {
  cursor: default;
  height: auto;
}

#frequentlyBoard .board_main table td:nth-child(3) {
  text-align: right;
  padding-right: 24px;
  vertical-align: baseline;
}

#frequentlyBoard .board_main table td:nth-child(3) img {
  cursor: pointer;
}

#frequentlyBoard .search_condition_column {
  margin-top: 17px;
}

#frequentlyBoard .search_warning {
  margin-top: 6px;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
  text-align: center;
  color: #e40f0f;
}

#frequentlyBoard .search_reslt {
  margin-top: 10px;
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: #666666;
}

#frequentlyBoard .search_reslt strong {
  font-weight: 500;
  color: #000000;
}

#inquiryBoard .board_main table th:nth-child(2),
#inquiryBoard .board_main table td:nth-child(2) {
  text-align: left;
}

#inquiryBoard table .reply_title {
  font-weight: normal !important;
}

#isMobile #inquiryBoard table .reply_title {
  font-weight: bold !important;
  margin-top: 16px;
}

.new_icon {
  width: 32px;
  height: 18px;
  border-radius: 4px;
  background-color: #ea0303;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -0.25px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

#isMobile .new_icon {
  width: 26px;
  height: 16px;
}

#inquiryBoard .board_btn_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #eeeeee;
  padding: 22px 0 14px;
}

#isMobile #inquiryBoard .board_btn_column {
  padding: 20px;
  border-top: none;
  border-bottom: 1px solid #eeeeee;
}

.info_btn_column {
  line-height: 1.75;
  letter-spacing: -0.4px;
}

#isMobile .info_btn_column {
  width: 65%;
  font-size: 13px;
}

.info_btn_column strong {
  font-weight: bold;
}

.inquiry_btn {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  background-color: #0d6fb8;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.88;
  letter-spacing: -0.4px;
  color: #ffffff;
}

#isMobile .inquiry_btn {
  width: 80px;
  height: 33px;
  font-size: 13px;
}

#inquiryBoard table td:nth-child(2) div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

#isMobile #inquiryBoard table td:nth-child(2) .check_reply {
  width: 26px;
}

.inquiry_write .write_column {
  padding-top: 35px;
}

.inquiry_write .write_column form input {
  width: 100%;
}

.inquiry_write .write_column form .write_content {
  align-self: baseline;
  margin-top: 10px;
}

.inquiry_write .write_column form .btn_column {
  margin-bottom: 0;
}

.inquiry_write form .warning.last {
  text-align: center;
  margin-top: 14px;
}

#inquiryBoard .write_column {
  border-top: 2px solid #eeeeee;
}

#isMobile #inquiryBoard .write_column {
  border-top: 1px solid #eeeeee;
}

#inquiryBoard .write_column form:first-child {
  padding-top: 0;
}

#inquiryBoard form.reply_form {
  background-color: #f1f1f1;
}

#isMobile #inquiryBoard form.reply_form {
  padding: 20px;
}

#inquiryBoard form {
  padding: 36px 38px 54px;
}

#inquiryBoard form textarea {
  height: 200px;
}

#inquiryBoard .write_column form .column_name {
  min-width: 114px;
  width: 114px;
}

#isMobile #inquiryBoard .write_column form .column_name {
  min-width: 45px;
  width: 45px;
}

#isMobile #inquiryBoard .btn_column {
  padding: 0 20px;
  margin-bottom: 24px;
}

#inquiryBoard .write_column .blue_btn {
  margin: 28px auto 0;
}

#inquiryBoard .btn_column.modify {
  display: flex;
  align-items: center;
  justify-content: center;
}

#inquiryBoard .btn_column.modify .blue_btn {
  margin: 0 10px;
}

#inquiryBoard .write_column {
  width: auto;
}

#isMobile #inquiryBoard table tr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #eeeeee;
  padding: 16px 0;
}

#isMobile #inquiryBoard table thead tr {
  padding: 0;
}

#isMobile #inquiryBoard table tr.have_reply {
  border: none;
  padding-bottom: 0;
}

#isMobile #inquiryBoard table td {
  width: 100%;
  text-align: left;
  padding: 0 20px !important;
  border: none;
  line-height: 1.5;
}

#isMobile #inquiryBoard table td:nth-child(2) {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.my_notice_info {
  display: flex;
  margin-top: 16px;
  font-size: 14px;
}

.my_notice_info .bar {
  width: 1px;
  height: 14px;
  margin: 0 6px;
  background-color: #000000;
}

#isMobile #myArticle,
#isMobile #myNotice,
#isMobile #EventBoard,
#isMobile #agreement,
#isMobile #frequentlyBoard,
#isMobile #inquiryBoard,
#isMobile #InquiryWrite {
  padding: 0;
}

#isMobile #EventBoard .isMobile_tr td:nth-child(1) {
  width: 110px;
}

#isMobile #EventBoard .isMobile_tr td:nth-child(2) {
  padding: 16px 0 !important;
}

#isMobile #EventBoard .isMobile_tr .event_img {
  width: 66px;
  height: 66px;
  margin: 0 16px;
}

#isMobile #EventBoard .isMobile_tr div {
  text-align: left;
  line-height: 1.5;
}

#isMobile #EventBoard .isMobile_tr .title {
  font-weight: bold;
}

#isMobile #EventBoard .isMobile_tr .period {
  font-size: 12px;
}

#isMobile .isMobile_inquiryBoard_h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile .isMobile_inquiryBoard_h2 img {
  width: 14px;
}

table.addr_alarm {
  width: 100%;
}

table.addr_alarm th {
  vertical-align: middle;
  padding: 10px 6px !important;
}

table.addr_alarm .column__on-off {
  justify-content: center;
  margin-right: 0;
}

#isMobile table.addr_alarm th {
  font-size: 12px;
}

#isMobile table.addr_alarm .label__on-off {
  width: 50px;
}

#isMobile table.addr_alarm .label__on-off .on,
#isMobile table.addr_alarm .label__on-off .off {
  font-size: 10px;
}

#isMobile table.addr_alarm .label__on-off .off {
  padding-left: 25px;
}

#isMobile table.addr_alarm .input__on-off:checked + .label__on-off .marble {
  left: 29px;
}

.sns_login_message .warning {
  font-size: 17px;
  line-height: 1.71;
  letter-spacing: -0.35px;
  color: #d51a1a;
  margin-bottom: 30px;
}

#Description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Description .description_header {
  width: 62.4%;
}

.desc_main_container img {
  width: 100%;
}

.desc_sub_container img {
  width: 100%;
}

.desc_btn_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desc_btn_container img {
  width: 100%;
}

.link_container_area {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link_container {
  width: 62.4%;
  display: flex;
}

.link_container img {
  width: 100%;
}

#isMobile .link_container_area,
#isMobile .desc_btn_container {
  display: block;
  padding: 0 20px;
}
.admin_login {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100vh;
}

.admin_login h1 {
  font-size: 24px;
  font-weight: 500;
  margin-top: 12px;
}

.admin_container {
  min-height: calc(100vh - 80px);
  display: flex;
}

ul.side_container {
  background-color: #f1f1f1;
  width: 200px;
  min-width: 170px;
}

ul.side_container li.side_tab {
  cursor: pointer;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px 0 28px;
  font-size: 18px;
  letter-spacing: normal;
}

ul.side_container li.side_tab.active {
  background-color: #1876bb;
  color: #ffffff;
}

.main_container {
  width: 100%;
  min-height: 100%;
  height: auto;
  padding: 30px 60px;
  min-width: 1361px;
  overflow-x: auto;
}

.admin_container .admin_login input {
  width: 400px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  padding: 0 20px;
  font-size: 18px;
}

.admin_login input.user_id {
  margin: 50px 0 12px;
}

.admin_login .checkbox_wrap {
  width: 400px;
  margin: 8px 0 40px;
}

.admin_login .login_btn {
  width: 400px;
  height: 50px;
  border-radius: 10px;
  background-color: #0d6fb8;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin_basic_modal {
  width: 300px;
  padding: 20px 18px 32px;
}

.admin_basic_modal img.close {
  margin-left: auto;
}

.admin_basic_modal .modal_info {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.45px;
  text-align: center;
  margin-bottom: 30px;
}

.admin_basic_modal .modal_info.title {
  margin-bottom: 16px;
}

.admin_basic_modal .modal_desc {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.45px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.admin_basic_modal .modal_list_column {
  background-color: #f1f1f1;
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  max-height: 100px;
  line-height: 1.5;
  overflow-y: auto;
  text-align: center;
}

.admin_basic_modal.modal_400 {
  width: 400px;
}

.confirm_btn {
  width: 100px;
  height: 40px;
  border-radius: 6px;
  background-color: #0d6fb8;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm_btn.close_btn {
  background-color: #cccccc;
}

.detail_user_container {
  height: auto;
  padding-bottom: 50px;
}

.detail_user_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 16px;
  padding-bottom: 8px;
}

#setMain .detail_user_header:not(:first-child) {
  margin-top: 16px;
}

#setMain .detail_user_header_column:not(:first-child) {
  margin-top: 16px;
}

#addressDetail h2.main_container_name {
  margin-bottom: 0;
}

h2.main_container_name {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
  margin-bottom: 16px;
}

h2.main_container_name .total_column {
  font-size: 16px;
  color: #999999;
  margin-left: 16px;
}

.user_search_column {
  width: 100%;
  padding: 30px 43px;
  background-color: #f5f5f5;
}

.search_column {
  padding: 5px 0px;
}

.admin_container input[type="date"],
.admin_container input[type="text"] {
  height: 40px;
  padding: 0 22px;
  border-radius: 4px;
  background-color: #ffffff;
  border: none;
  font-size: 16px;
  letter-spacing: -0.4px;
}

#adminLogin.admin_login input {
  width: 400px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-size: 18px;
}

.admin_container input[type="date"] {
  width: 180px;
}

.admin_container .add_event input[type="date"] {
  width: 200px;
}

.admin_container .input_title,
.admin_container .range_text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
}

.admin_container .input_title {
  margin-right: 26px;
}

.admin_container .range_text {
  margin: 0 10px;
}

.user_search_column .first_column {
  display: flex;
}

.user_search_column .first_column .search_regdate_column {
  margin-right: 40px;
}

.user_search_column .first_column .search_keyword_column input {
  width: 600px;
}

.user_search_column .second_column {
  margin: 16px 0 42px;
  display: flex;
  align-items: center;
}

#address .user_search_column .second_column.top,
#boardConsult .user_search_column .second_column.top {
  margin: 16px 0 16px;
}

.user_search_column .second_column .bar {
  width: 1px;
  height: 16px;
  margin: 0 25px;
  background-color: #cccccc;
}

.user_search_column .second_column label {
  margin-left: 6px;
  margin-right: 30px;
}

#address .user_search_column .second_column label {
  display: inline-block;
}

.admin_btn_column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail_user.admin_btn_column {
  margin-top: 80px;
}

#addressDetail .admin_btn_column {
  margin-bottom: 50px;
}

.admin_white_btn {
  width: 140px;
  height: 40px;
  border-radius: 6px;
  border: solid 1px #1876bb;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #1876bb;
  cursor: pointer;
}

.admin_blue_btn {
  width: 140px;
  height: 40px;
  border-radius: 6px;
  background-color: #1876bb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #ffffff;
}

.verstdate_btn {
  width: 100px;
  margin-left: 10px;
}

.admin_btn_column .left {
  margin-right: 10px;
}

.user_list_column {
  width: 100%;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 35px 50px 33px;
}

.user_list_column .title_column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user_list_column h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-right: 16px;
}

.user_list_column .search_result {
  color: #999999;
}

.user_list_column table {
  width: 100%;
  margin: 12px 0 24px;
}

.user_list_column table th {
  font-weight: 500;
  background-color: #f5f5f5;
  border-top: 2px solid #dddddd;
  border-bottom: 2px solid #dddddd;
}

.user_list_column table th,
.user_list_column table td {
  font-size: 14px;
  letter-spacing: -0.35px;
  height: 44px;
  vertical-align: middle;
  text-align: center;
}

.user_list_column table td {
  border-bottom: 1px solid #dddddd;
}

.user_list_column table td.stop {
  color: #df1313;
}

.admin_pagenation {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin_pagenation input[type="text"] {
  width: 50px;
  height: 36px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  padding: 0;
}

.admin_pagenation .current_page {
  margin: 0 28px;
}

.admin_pagenation .page_prev {
  margin-left: 15px;
}

.admin_pagenation .page_next {
  margin-right: 15px;
}

ul.detail_user_tab {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}

#setProvision ul.detail_user_tab li {
  width: auto;
  padding: 0 16px;
}

ul.detail_user_tab li {
  width: 200px;
  height: 60px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  letter-spacing: -0.5px;
  color: #999999;
  border-top: 4px solid transparent;
  cursor: pointer;
}

ul.detail_user_tab li.active {
  background-color: #ffffff;
  border-top: 4px solid #1876bb;
  font-weight: 500;
  color: #000000;
}

.detail_table {
  width: 100%;
}

.detail_table_title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
  margin: 40px 0 10px;
}

#userAdminDetail .detail_table_title.access,
#userAdminAdd .detail_table_title.access {
  display: flex;
  align-items: center;
}

.detail_table_title .info {
  font-weight: normal;
  font-size: 14px;
  margin-left: 16px;
}

#userAdminDetail .detail_table_title .info,
#userAdminAdd .detail_table_title .info {
  display: inline-block;
}

.detail_table th {
  width: 180px;
  height: 58px;
  background-color: #f5f5f5;
  vertical-align: middle;
  border-bottom: 2px solid #ffffff;
  font-weight: 500;
}

.detail_table td {
  width: 38%;
}

#setPush .detail_table td,
#setPush .detail_table th {
  width: auto;
}

#addressDetail .detail_table td,
#boardConsultDivision .detail_table td {
  width: auto;
}

.detail_table th.tel_num {
  height: 88px;
}

.detail_table td {
  vertical-align: middle;
  padding: 0 24px;
}

.detail_table .free_user_radio label {
  margin-right: 30px;
  margin-left: 7px;
}

.detail_table .img_column img {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

#setMain .detail_table .img_column img {
  width: 100%;
  height: 100%;
  margin: 0;
}

.detail_table .img_column .img_name {
  font-size: 14px;
  letter-spacing: -0.35px;
  margin: 8px 0 18px;
}

#setMain .detail_table .img_column .img_name {
  margin-top: 8px;
}

.admin_black_btn_column {
  display: flex;
}

.admin_black_btn {
  width: 60px;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  letter-spacing: -0.35px;
}

.admin_black_btn.left {
  margin-right: 10px;
}

.user_control_column {
  display: flex;
  align-items: center;
}

.admin_delete_btn {
  width: 100px;
  height: 40px;
  border-radius: 4px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  letter-spacing: -0.35px;
  color: #ffffff;
}

.admin_delete_btn.left {
  margin-right: 10px;
}

.admin_delete_btn.close_btn {
  background-color: #cccccc;
}

.detail_table input[type="text"] {
  width: 400px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
}

.detail_table input[type="password"] {
  width: 300px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  padding: 0 22px;
  margin-right: 10px;
}

.detail_table input[type="text"].has_btn {
  width: 290px;
  margin-right: 10px;
}

.detail_table td.nickName .nickName_column,
.detail_table td .password_column {
  display: flex;
  align-items: center;
}

.detail_table td.nickName .admin_white_btn {
  width: 100px;
}

.admin_input_info {
  font-size: 14px;
  letter-spacing: -0.35px;
  margin-top: 4px;
}

.detail_table .change_password .admin_white_btn {
  width: 160px;
}

.detail_table .day_change_count {
  display: flex;
  align-items: center;
}

.detail_table .admin_white_btn80 {
  width: 80px;
  margin-left: 38px;
}

.detail_table .admin_white_btn100 {
  width: 100px;
  margin-left: 10px;
}

input.add_address_limit {
  width: 60px !important;
  margin-right: 4px;
}

.detail_table.marketing_table th {
  height: 118px;
  line-height: 1.25;
}

.detail_table .free_user_chk label {
  margin-right: 27px;
  margin-left: 7px;
}

.admin_basic_modal textarea {
  width: 210px;
  height: 100px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-bottom: 19px;
}

.admin_basic_modal .admin_delete_btn {
  width: 100px;
  height: 40px;
}

#userFreeAddress {
  display: flex;
  min-height: calc(100vh - 320px);
}

#userFreeAddress .warning{
  padding-left: 10px;
  padding-bottom: 10px;
  color: red;
}

#userFreeAddress .address_lists {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f5f5f5;
  padding-bottom: 16px;
  max-height: 100vh;
  overflow-y: auto;
}

#userFreeAddress .address_lists ul {
  width: 100%;
}

#userFreeAddress .address_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid #eeeeee;
  padding: 0 16px;
  cursor: pointer;
}

#userFreeAddress .address_list.active div {
  color: #1876bb;
}

#userFreeAddress .address_list .name {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

#userFreeAddress .address_list .date {
  font-size: 14px;
  letter-spacing: -0.35px;
}

#userFreeAddress .address_detail {
  width: 800px;
  padding: 16px 24px;
}

#userFreeAddress .address_detail table {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

#userFreeAddress .address_detail table .column_name,
#addressDetail .address_detail table .column_name {
  width: 100px;
  border-right: 2px solid #ffffff;
}

#userFreeAddress .address_detail table .sub_th {
  width: 100px;
}

#userFreeAddress .address_detail table input[type="date"] {
  border: 1px solid #dddddd;
}

#userFreeAddress .detail_table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

#addressDetail .detail_table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 10px;
  width: 696px;
}

#addressDetail .category_column .detail_table_header {
  width: 695px;
}

#addressDetail .detail_table_header .detail_table_title {
  margin: 0;
}

#addressDetail .detail_table_header .admin_black_btn {
  width: 100px;
}

#userFreeAddress .detail_table_header label {
  margin-left: 8px;
  margin-right: 24px;
}

#userFreeAddress table .refine_address {
  height: 88px;
}

#userFreeAddress table .refine_address_desc,
#addressDetail table .refine_address_desc {
  width: 400px;
  height: 72px;
  border: 1px solid #dddddd;
  padding: 8px 16px;
}

.calendar_data,
#addressDetail .calendar_data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 270px;
  height: 40px;
  border: solid 1px #dddddd;
  border-radius: 4px;
  padding: 0 22px;
}
.user_free_notice_tab {
  display: flex;
}

.user_free_notice_tab li {
  width: 200px;
  height: 50px;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  color: #999999;
  cursor: pointer;
}

.user_free_notice_tab li.active {
  background: #eff8fe;
  border-bottom: 2px solid #1876bb;
  color: #000000;
}

#userFreeNoticeView .notice_title {
  text-align: left;
}

#userFreeNoticeView .confirmed td,
#userFreeNoticeView .confirmed span {
  color: #999999;
}

#userFreeNoticeView td span {
  cursor: pointer;
}

#userFreeNoticeView th:nth-child(1) {
  width: 5%;
}

#userFreeNoticeView th:nth-child(2) {
  width: 10%;
}

#userFreeNoticeView th:nth-child(3) {
  width: 15%;
}

#userFreeNoticeView th:nth-child(5),
#userFreeNoticeView th:nth-child(6) {
  width: 10%;
}

.admin_notice_modal .modal_info {
  width: 100%;
  text-align: left;
  margin-bottom: 0 !important;
}

/* 관리주소 상세보기 > 리파인 api 결과 창 확인 시 */
/* AddressDetail.js */
.api_result_btn {
  width: 100px;
  height: 40px;
  margin-top: 50px;
}
.modal_inner_ct .modal_title {
  width: 90%;
  margin: 15px 0 10px;
  padding-bottom: 15px;
  font-size: 2em;
  text-align: left;
  border-bottom: 2px solid darkgrey;
  border-width: 50%;
}
.modal_inner_ct .modal_content {
  width: 90%;
  max-height: 600px;
  margin-bottom: 50 !important;
  overflow: auto;
  text-align: left;
  font-size: 1.4em;
}
.modal_inner_ct .address_modal_btn {
  margin: 50px 0 30px;
}

.admin_notice_modal .detail_column {
  background-color: #f1f1f1;
  text-align: left;
  padding: 0px 8px;
  margin-top: 16px;
}

.admin_notice_modal .detail_column span {
  font-size: 14px;
  display: inline-block;
}

.admin_notice_modal .modal_desc {
  font-size: 16px;
  margin-bottom: 24px;
  max-height: 200px;
  overflow-y: auto;
  text-align: left;
}

#userFreeNoticeView.user_list_column {
  margin-top: 0;
}

#userFreeNoticeSet .all_check {
  display: flex;
  align-items: center;
  padding: 24px 16px;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-color: #dddddd;
  width: 60%;
}

#userFreeNoticeSet .input_name {
  margin-right: 30px;
  font-weight: 500;
}

#userFreeNoticeSet table {
  width: 60%;
  margin-top: 24px;
}

#userFreeNoticeSet table .name {
  width: 20%;
  padding: 24px 16px;
}

#userFreeNoticeSet th {
  font-weight: 500;
}

#userFreeNoticeSet th:not(:first-child) {
  width: 15%;
}

#userFreeNoticeSet .column__on-off {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 15px auto;
}

.detail_table td .ip_column {
  color: #d51a1a;
}

.detail_table td.board_access label {
  margin-left: 7px;
}

.detail_table .warning {
  font-size: 14px;
  color: #d51a1a;
}

#userLawyerAdd .detail_user_container {
  padding-bottom: 0;
}

.admin_basic_modal .lawyer_img {
  width: 160px;
  height: 200px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
}

.admin_basic_modal .lawyer_img_info {
  display: flex;
  width: 70%;
  font-size: 14px;
  margin: 8px 0;
}

.admin_basic_modal .lawyer_img_info .sign {
  margin-right: 6px;
}

.sample_img {
  width: auto;
  min-width: 160px;
  max-width: 300px;
  height: 200px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#address .second_column .register_address,
#boardConsult .second_column .register_address {
  width: 410px;
}

#address .second_column .address_checkbox{
  height: 100px;
  padding-top: 10px;
}

.list_counter_column {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: solid 1px #dddddd;
  display: flex;
  margin: 16px auto 0;
}

.list_counter_column .list_counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 80px;
}

.list_counter_column .list_counter.all {
  background-color: #f5f5f5;
}

.list_counter_column .list_counter:not(:last-child) {
  border-right: solid 1px #dddddd;
}

.list_counter_column .list_counter h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.5px;
}

.list_counter_column .list_counter strong {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-right: 4px;
}

.list_counter_column .list_counter span {
  font-size: 14px;
  line-height: 1.5;
}

#addressDetail .serial_number {
  width: 290px;
}

.approval_column {
  display: flex;
  align-items: center;
}

.admin_container select {
  height: 40px;
  width: 180px;
  padding: 0 22px;
  border-radius: 4px;
  background-color: #ffffff !important;
  border: none;
  font-size: 16px;
  letter-spacing: -0.4px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAYAAADwdn+XAAAABHNCSVQICAgIfAhkiAAAAOJJREFUKFNj1NTUnPL79++qO3fufGIgAWhras65ev16CiNQD6OWpuZzBkZGs2vXrj0ixgwtLa1N////n3/9+vX1IAPAAGjIjT9//4bfunXrIj5DtDQ0jvxnZGwAat4DUgc3AGrI8X///1fcuHHjIDZDgJZc+c/AEAfUfA4mj2IA2BANje1AG2YCFW1ANgSo+envP39sb9++fQ9ZHMMAkCQwYJcx/vu3+9rNm/Pl5OQEebm5n37++lX60aNH79FdhtUAqCFTgQG1j4mRcdK169elcYULTgNAGjQ0NNqB4VGJL1ABvRReCRJb2cEAAAAASUVORK5CYII=) no-repeat 90% 50%; /* 화살표 모양의 이미지 */
}

.admin_container .board_table select {
  border: solid 1px #dddddd;
}

.board_table .board_title {
  border: solid 1px #dddddd;
  height: 40px;
  border-radius: 4px;
  padding: 0 22px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

#boardConsultDetail .board_table .board_content textarea {
  margin-bottom: 20px;
}

.board_table .board_content textarea,
.board_table .board_content .textarea {
  width: 100%;
  height: 300px;
  padding: 10px 22px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 4px;
  background-color: #ffffff !important;
  border: solid 1px #dddddd;
}

.detail_table.reply_table {
  background-color: #f1f1f1;
  margin-top: 16px;
  padding-bottom: 16px;
}

.detail_table.reply_table th {
  background-color: #f1f1f1;
  border-bottom: 2px solid #f1f1f1;
}

.board_table .board_content td {
  padding-bottom: 16px;
  padding-top: 8px;
}

.admin_table_bar {
  width: 100%;
  margin-top: 40px;
  border-bottom: 2px solid #eeeeee;
}

.thumbnail {
  width: 100px;
  height: 100px;
  background-color: #eeeeee;
  margin: 6px auto;
}

.property_board_list th:not(:nth-child(6)) {
  width: 9%;
}

.select_column {
  display: flex;
  align-items: center;
}

.select_column .select_name {
  font-size: 18px;
  font-weight: bold;
}

.select_column select {
  border: solid 1px #dddddd;
  margin: 0px 16px;
}

.single_column td {
  width: auto;
}

.single_column .img_column {
  display: flex;
  align-items: flex-end;
}

#setMain .single_column .img_column {
  align-items: flex-start;
}

#setMain .single_column .img_column .banner_img_column {
  border: solid 1px #dddddd;
  background-color: #ffffff;
  width: 250px;
  height: 150px;
  margin-right: 16px;
}

#setMain .single_column .img_column .banner_img_file {
  display: none;
}

.single_column input.title {
  width: 600px;
}

.event_period_column {
  display: flex;
  align-items: center;
}

.event_period_column .calendar_data {
  border: solid 1px #dddddd !important;
}

.event_period_column span {
  margin: 0 10px;
}

.single_column.reply_column {
  background-color: #f5f5f5;
  margin: 16px 0 32px;
}

.single_column.reply_column th {
  border-bottom: none;
}

#setPush .th_taskname {
  width: 20% !important;
}

#setPush .th_checkbox {
  width: 10% !important;
}

#setPush .detail_table input[type="checkbox"] {
  margin: auto;
  display: block;
}

#setPush .detail_table td {
  padding: 8px 0;
}

#setPush .detail_table textarea {
  width: 100%;
  height: 60px;
  padding: 4px 6px;
  font-size: 16px;
}

#setPush .detail_table tr td:first-child {
  font-weight: 500;
}

#setPush .detail_table .info {
  font-size: 14px;
  font-weight: normal;
}

.provision_content {
  width: 100%;
  height: 500px;
  padding: 24px;
  font-size: 16px;
}

.provision_save {
  margin: 24px auto;
}

.version_table input {
  width: 100px !important;
}

#setVersion .admin_table_bar {
  margin-top: 16px;
}

.alarm_wrapper {
  width: 60%;
}

.alarm_wrapper .column__on-off {
  margin-left: 16px;
}

.wrapper_apiResult {
  background-color: #999999;
  width: 80%;
  height: 80%;
  margin: 0 auto;
  position: fixed;
  top: 50%;
}

.free_user_chk ul li {
  margin : 15px 0px 15px 0px;
}

.excelDown {
  color: #fff;
  background-color: green;
  width: 120px;
  height: 30px;
  border-radius: 5px;
  margin-left: 25px;
}
#admin_header {
  width: 100%;
  min-width: 1530px;
  height: 80px;
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 33px;
}

#admin_header .left_wrapper {
  display: flex;
  align-items: center;
}

.admin_logo_column {
  display: flex;
  align-items: center;
}

.admin_logo_column h1 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
  color: #000000;
  margin-left: 10px;
}

ul.nav_menus {
  display: flex;
  align-items: center;
}

li.nav_menu {
  border-bottom: 4px solid transparent;
  padding: 10px 0;
  font-size: 18px;
  letter-spacing: -0.45px;
  color: #999999;
  margin-left: 68px;
}

li.nav_menu.active {
  border-bottom: 4px solid #1876bb;
  font-weight: 500;
  color: #000000;
}

#admin_header .right_wrapper {
  display: flex;
  align-items: center;
}

#admin_header .right_wrapper .user_name {
  font-weight: 500;
  letter-spacing: -0.4px;
}

.admin_logout_column {
  margin-left: 30px;
}

.admin_logout_column img {
  margin-right: 9px;
}

.mypage_main {
  width: 62.4%;
  margin: auto;
}

.mypage_header {
  width: 100%;
  border-bottom: 2px solid #cccccc;
}

.mypage_body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 27px 0 66px;
}

#isMobile .mypage_body {
  padding: 0;
}

.mypage_body .side_menu {
  width: 200px;
  height: 880px;
  border-radius: 20px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-right: 50px;
  padding: 16px 30px 0;
}

#MypageMobile .mypage_body .side_menu {
  height: auto;
}

#isMobile .mypage_body .side_menu {
  width: 100vw;
  margin-right: 0;
  border: none;
  padding: 0;
}

.mypage_body .side_menu h2 {
  font-size: 18px;
  font-weight: bold;
  line-height: 2.22;
  letter-spacing: -0.45px;
  display: flex;
  align-items: center;
}

.mypage_body .side_menu ul {
  border-bottom: 1px solid #dddddd;
}

.mypage_body .side_menu li {
  line-height: 2.4;
  letter-spacing: -0.4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#MypageMobile {
  padding-bottom: 100px;
}

#MypageMobile .mypage_body .side_menu li {
  justify-content: space-between;
  padding: 0 36px;
}

#MypageMobile ul:first-child a:first-child {
  display: none;
}

.mypage_body .side_menu .dot {
  width: 8px;
  height: 8px;
  background-color: #056fb8;
  border-radius: 50%;
  margin-right: 13px;
  visibility: hidden;
}

#MypageMobile .mypage_body .side_menu .dot {
  display: none;
}

.mypage_body .side_menu ul {
  padding-bottom: 24px;
}

#MypageMobile .mypage_body .side_menu ul {
  border-bottom: 4px solid #f1f1f1;
}

#MypageMobile .mypage_body .side_menu ul:last-child {
  border-bottom: none;
}

#MypageMobile .mypage_body .side_menu ul {
  padding: 12px 0;
}

.mypage_body .side_menu ul:not(:nth-child(1)) {
  padding-top: 24px;
}

.mypage_body .side_menu ul:not(:nth-child(2)) {
  padding-top: 24px;
}

.mypage_body .side_menu li.active {
  font-weight: bold;
}

.mypage_body .side_menu li.active .dot,
.mypage_body .side_menu .inquiry_wrapper.active .dot {
  visibility: visible;
}

.mypage_body .side_menu .inquiry_wrapper {
  display: flex;
  margin-top: 28px;
}

.mypage_body .side_menu .inquiry_wrapper .dot {
  margin-top: 20px;
}

.mypage_body .inquiry_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 32px 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.mypage_body .inquiry_icon {
  position: relative;
}

.mypage_body .inquiry_count {
  width: 30px;
  height: 20px;
  border-radius: 10px;
  background-color: #056fb8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.3px;
  color: #ffffff;
  margin-left: 8.6px;
}

.mypage_body .inquiry_icon .inquiry_count {
  position: absolute;
  top: 7px;
  right: -20px;
}

.mypage_body .inquiry_count {
  position: static;
}

.mypage_body .inquiry_column img {
  margin-top: 7px;
}

.mypage_body .logout_btn {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mypage_body .logout_btn img {
  margin-right: 10px;
}

.mypage_body .content {
  width: 100%;
}

.mypage_body .content h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
  padding-bottom: 23px;
  border-bottom: 2px solid #eeeeee;
}

#isMobile .mypage_body .content h2 {
  font-size: 17px;
  height: 54px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-width: 1px;
}

#isMobile #InquiryWrite .mypage_body .content h2 {
  justify-content: space-between;
}

#isMobile #InquiryWrite .mypage_body .content img {
  width: 14px;
}

#isMobile #mypageModify .mypage_body .content h2 {
  border: none;
  padding: 0;
}

.profile_wrapper {
  width: 620px;
  margin: auto;
}

.profile_column {
  display: flex;
  padding-left: 48px;
  margin-top: 43px;
}

.profile_column .profile {
  border-radius: 50%;
  width: 160px;
  height: 160px;
  margin-right: 40px;
}

#isMobile #MypageMobile .profile_column .profile {
  width: 80px;
  height: 80px;
  margin-right: 16px;
}

.profile_name {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.45px;
  line-height: 1.25;
  margin-bottom: 4px;
}

#isMobile .profile_name {
  font-size: 14px;
}

.profile_name strong {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
}

#isMobile .profile_name strong {
  font-size: 17px;
}

.profile_email {
  display: flex;
  align-items: center;
}

#isMobile .profile_email {
  font-size: 13px;
}

.profile_email img {
  margin-right: 8px;
}

#isMobile .profile_email img {
  width: 14px;
  margin-right: 4px;
}

.modify_btn {
  width: 120px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #056fb8;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 21px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #056fb8;
}

.modify_btn img {
  margin-right: 4px;
}

.profile_wrapper .btns_column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 74px;
}

.profile_wrapper .btns {
  width: 300px;
  height: 80px;
  border-radius: 10px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 32px;
}

.profile_wrapper .btns_column .btn_name {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
}

.profile_wrapper .btns_column .btn_count {
  font-size: 18px;
  font-weight: normal;
  line-height: 2.67;
  letter-spacing: -0.45px;
}

.profile_wrapper .btns_column .btn_count strong {
  font-weight: bold;
}

.profile_wrapper .btns_column .warning {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.35px;
  color: #d51a1a;
}

.add_tab_column {
  margin-top: 41px;
  border-bottom: 1px solid #dddddd;
  display: flex;
}

#isMobile .add_tab_column {
  margin-top: 0;
  padding-top: 6px;
  background-color: #eeeeee;
  border: none;
}

.add_tab_column .add_btn,
.add_tab_column .address_tab {
  width: 200px;
  height: 50px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: -0.8px;
  cursor: pointer;
}

.add_tab_column .address_tab {
  background-color: #ffffff;
}

#isMobile .add_tab_column .add_btn,
#isMobile .add_tab_column .address_tab {
  font-size: 15px;
  width: 100px;
  height: 46px;
  background-color: #dddddd;
}

#isMobile .add_tab_column .address_tab {
  background-color: #dddddd;
}

.add_tab_column .address_tab .tab_name {
  height: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: -0.9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 6px solid transparent;
}

#isMobile .add_tab_column .address_tab .tab_name {
  font-size: 15px;
  width: 100%;
  border: none;
}

.add_tab_column .address_tab .tab_name.active {
  border-bottom: 6px solid #056fb8;
}

#isMobile .add_tab_column .tab_name.active {
  background-color: #ffffff;
}

.add_address_column .btn_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
}

.add_address_column .btn_column .add_address_btn {
  margin-bottom: 24px;
}

#isMobile .add_address_column .btn_column .add_address_btn {
  width: 80px;
}

#isMobile .add_info {
  font-size: 15px;
}

.add_address_column .add_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add_address_column .add_content .info {
  font-size: 18px;
  line-height: 2.67;
  letter-spacing: -0.45px;
  margin-top: 30px;
}

.add_content {
  width: 700px;
  margin: 0 auto;
}

#isMobile .add_content {
  width: 100vw;
  max-width: 450px;
  padding: 0 20px;
}

.add_content .top {
  width: 100%;
  margin-top: 52.8px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 34px;
}

#isMobile .add_content .top {
  margin-top: 25px;
}

.add_content h3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.5px;
  margin-bottom: 34px;
}

#isMobile .add_content h3 {
  font-size: 14px;
  margin-bottom: 18px;
}

.add_content .column {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

#isMobile .add_content .column {
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 0;
}

#isMobile #mypageAddress .add_content .column.save {
  margin-top: 24px;
  margin-bottom: 24px;
}

#isMobile .add_content .isMobile_column {
  flex-direction: row;
  align-items: center;
}

#isMobile .add_content .bottom .isMobile_column {
  margin-bottom: 16px;
}

.add_content .column.save {
  flex-direction: column;
  margin-top: 85px;
}

.add_content .column .input_name {
  width: 188px;
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .add_content .column .input_name {
  font-size: 13px;
  margin-bottom: 6px;
}

#isMobile .add_content .isMobile_column .input_name {
  margin-bottom: 0;
}

.add_content .column .warning {
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
  color: #dd1818;
}

#isMobile .add_content .column .warning {
  font-size: 12px;
  margin-bottom: 4px;
}

#isMobile .add_content .column.save .warning {
  margin: auto;
}

.add_content .top .column input {
  width: 400px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  padding: 0 26px;
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .add_content .top .column input {
  width: 100%;
  height: 40px;
  font-size: 15px;
  padding: 0 16px;
  border-radius: 4px;
}

#isMobile .add_content .top .column input.addr_code {
  width: 65%;
}

.add_content .column .input_column {
  display: flex;
  align-items: center;
}

#isMobile .add_content .column .input_column {
  width: 100%;
}

.add_content .own_radio_container {
  margin: 20px 0 35px;
}

#isMobile .add_content .own_radio_container {
  margin: 22px 0 30px;
}

.add_content .own_radio_column {
  display: flex;
}

.add_content .own_radio_column .left {
  margin-right: 28px;
}

#isMobile .radio_wrap {
  position: relative;
}

.add_content .own_radio_column span {
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .add_content .own_radio_column span {
  font-size: 14px;
}

.add_content .column__on-off {
  display: flex;
}

.alarm_btn {
  width: 100px;
  height: 36px;
  border-radius: 18px;
  border: solid 1px #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile .alarm_btn {
  width: 74px;
  height: 22px;
  font-size: 11px;
}

.alarm_btn img {
  margin-right: 6px;
}

#isMobile .alarm_btn img {
  width: 8px;
  margin-right: 4px;
}

.add_content .column__on-off {
  width: auto;
  margin-right: 16px;
}

.add_content .bottom {
  width: 100%;
  margin-top: 45px;
}

#isMobile .add_content .bottom {
  margin-top: 26px;
}

.add_content .bottom .calendar {
  background-color: #ffffff !important;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABHNCSVQICAgIfAhkiAAAAeVJREFUSEvtlkFWwkAMQJPSPtmJJxBvwBHoRtudngC4AJYb6A0GPID1BOKqPDfgDbgBeAOW+tpOzAwMYl+R4cFz5ew6k+Qn6UwShJLldpM73m5mg7BZdl7cc6OkiYRjQvIzEU6K5/inkEqUtNmbFlOtvN8WIQEs+GwCSH0TlY7E6yYxILaI4AWApipVfFBnhdgmXUpW6QPRE+vM+bvN3+cSqZOLMEYVgUP4KBFvcnE1VEZ1VBIb2SCIbCAQvTZcmYvMqUQgLpWTa8dTpAv0uiPepGk6CNtWBm2FonHNlZ9zti3Qux0RSOilD4Gw1beV41vKNw0XSwjnngiZetyFSG0iejeQ41rfsMaQNw3Z9ogOJS/TxQn7h2ymcvWYKe0Hnc39o6XLVAvzuo8O+Q2gYNaRqLKfOVUBwleFb712AewhUVJ3JU4RYZbiiW9ANgB7iJLUxU9ODMiTH0JV3LJ/UHxX1unSiisQIBCX8ZoNYL9IjHsM8qSMpUNC9QibavAzEqJ77ud3Nor7yCgIRz5HtzsachpOs37g72NgpyxfGo9wplKLZtJgpZhvUK94VXcaKxNYAp65HZ+lTrWhe7xpwashQLfPQ5YeRriPpE7lWrXj75FIvwkeAA6cVtQ/kA5McqgOTVa+AO9UWFutVmQKAAAAAElFTkSuQmCC) no-repeat 255px 50%;
  cursor: pointer;
}

#isMobile #mypageAddress .add_content .bottom .calendar {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAABHNCSVQICAgIfAhkiAAAAeVJREFUSEvtlkFWwkAMQJPSPtmJJxBvwBHoRtudngC4AJYb6A0GPID1BOKqPDfgDbgBeAOW+tpOzAwMYl+R4cFz5ew6k+Qn6UwShJLldpM73m5mg7BZdl7cc6OkiYRjQvIzEU6K5/inkEqUtNmbFlOtvN8WIQEs+GwCSH0TlY7E6yYxILaI4AWApipVfFBnhdgmXUpW6QPRE+vM+bvN3+cSqZOLMEYVgUP4KBFvcnE1VEZ1VBIb2SCIbCAQvTZcmYvMqUQgLpWTa8dTpAv0uiPepGk6CNtWBm2FonHNlZ9zti3Qux0RSOilD4Gw1beV41vKNw0XSwjnngiZetyFSG0iejeQ41rfsMaQNw3Z9ogOJS/TxQn7h2ymcvWYKe0Hnc39o6XLVAvzuo8O+Q2gYNaRqLKfOVUBwleFb712AewhUVJ3JU4RYZbiiW9ANgB7iJLUxU9ODMiTH0JV3LJ/UHxX1unSiisQIBCX8ZoNYL9IjHsM8qSMpUNC9QibavAzEqJ77ud3Nor7yCgIRz5HtzsachpOs37g72NgpyxfGo9wplKLZtJgpZhvUK94VXcaKxNYAp65HZ+lTrWhe7xpwashQLfPQ5YeRriPpE7lWrXj75FIvwkeAA6cVtQ/kA5McqgOTVa+AO9UWFutVmQKAAAAAElFTkSuQmCC) no-repeat 95% 50%;
}

.add_content .bottom input {
  width: 300px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  font-size: 18px;
  letter-spacing: -0.45px;
  padding: 0 24px;
}

#isMobile .add_content .bottom input {
  width: 100%;
  height: 40px;
  font-size: 14px;
}

.add_content .check_btn .delete_icon {
  margin-right: 6px;
}

#isMobile .add_content .check_btn .delete_icon {
  width: 10px;
}

.add_content .bottom .notice_cycle {
  width: 300px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.45px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile .add_content .bottom .notice_cycle {
  font-size: 14px;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
}

#mypageAddress .save_btn_column {
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile #mypageAddress .save_btn_column {
  width: 100%;
}

.add_content .save_btn_column .close {
  margin-right: 20px;
}

.add_content .address_num_info {
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
  color: #666666;
}

.add_content .see_more {
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #056fb8;
  text-decoration: underline;
  margin-left: 8px;
  cursor: pointer;
}

#isMobile .add_content .see_more {
  font-size: 90%;
}

#mypageAddress .num_modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.6px;
}

#mypageAddress .num_modal .manage_num_list {
  width: 100%;
  background-color: #f1f1f1;
  padding: 18px 24px;
  margin: 18px 0 23px;
  line-height: 1.88;
}

.mypage_body .content.board_main h2 {
  border-bottom: none;
}

#isMobile .mypage_body .content.board_main h2 {
  border-bottom: 1px solid #eeeeee;
  width: 100%;
}

#isMobile .mypage_body .content.board_main h2.isMobile_inquiryBoard_h2 {
  border-bottom: none;
}

#isMobile .mypage_body .content.board_main h2.isMobile_detail_h2 {
  border: none;
}

#isMobile .mypage_body .content.board_main .detail_title_column h2 {
  border: none;
  font-size: 20px;
  padding: 0;
}

.mypage_body .content h2 .count {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.45px;
  color: #056fb8;
}

#isMobile .mypage_body .content h2 .count {
  margin-left: 4px;
}

.mypage_body .board_main .table_column {
  min-height: 0;
  margin-bottom: 55px;
}

/* 이벤트 페이지 사진 크기 임의 조정!!! */
/* .mypage_body .board_main .table_column img {
  width: 120px;
  height: 120px;
} */

.title_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile .title_column {
  align-items: flex-start;
  flex-direction: column;
}

#isMobile #myNotice .title_column {
  border-bottom: 1px solid #eeeeee;
}

.all_confirm {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #1f7ec0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile .all_confirm {
  width: 94px;
  height: 34px;
  align-self: flex-end;
  font-size: 12px;
  margin: 12px 20px;
}

.all_confirm img {
  margin-left: 20px;
}

#isMobile .all_confirm img {
  margin-left: 4px;
  width: 14px;
}

.board_main table .confirm {
  letter-spacing: -0.4px;
  color: #999999;
}

#isMobile .board_main table .not_confirm {
  font-weight: bold;
}

.board_main table .confrim_notice td {
  color: #999999;
}

#myNotice .board_main table th:nth-child(3) {
  width: 50%;
  text-align: left;
}

.notice_modal {
  padding: 45px 35px 47px;
}

.notice_modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.6px;
}

.notice_modal .notice_desc {
  width: 100%;
  height: 200px;
  overflow-y: auto;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #666666;
  margin: 20px 0;
}

/* 스크롤바 */
.notice_modal ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.notice_modal ::-webkit-scrollbar-track {
  background-color: #dddddd;
}
.notice_modal ::-webkit-scrollbar-thumb {
  border-radius: 23px;
  background: #999999;
}
.notice_modal ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

#isMobile #myNoticeSetting {
  padding: 0;
}

#myNoticeSetting .all_check {
  display: flex;
  align-items: center;
  padding: 38px 0;
  border-bottom: 1px solid #dddddd;
}

#isMobile #myNoticeSetting .myNoticeSetting_table {
  display: flex;
  display: -ms-flexbox;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 24px 0;
}

#isMobile .myNoticeSetting_table tbody {
  display: flex;
}

#isMobile .myNoticeSetting_table th,
#isMobile .myNoticeSetting_table td {
  display: block;
}

#myNoticeSetting .input_name {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: -0.45px;
}

#isMobile #myNoticeSetting .input_name {
  font-size: 14px;
}

#myNoticeSetting .all_check .input_name {
  margin-right: 25px;
}

#myNoticeSetting table {
  width: 100%;
  margin-top: 43px;
}

#myNoticeSetting table th {
  font-weight: 500;
  letter-spacing: -0.4px;
  width: 16.66%;
  height: 46px;
}

#isMobile #myNoticeSetting table th {
  font-size: 14px;
  font-weight: normal;
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: left;
}

#myNoticeSetting table td {
  height: 80px;
}

#isMobile .isMobile_radio_wrapper {
  width: 100%;
  padding: 0 20px;
}

#isMobile #myNoticeSetting table td {
  height: 40px;
  display: flex;
}

#myNoticeSetting table .name {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: -0.45px;
}

#isMobile #myNoticeSetting table .name {
  font-size: 14px;
  width: 130px !important;
}

#myNoticeSetting table .column__on-off {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}

#myNoticeSetting .warning {
  letter-spacing: -0.4px;
  color: #d90e0e;
}

#isMobile #myNoticeSetting .warning {
  font-size: 12px;
}

#isMobile #noticeBoard {
  padding: 0;
}

#noticeBoard .board_main table td:nth-child(3) {
  text-align: center;
}

#isMobile #noticeBoard .content h2 {
  font-size: 17px;
  height: 54px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-width: 1px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
}

#isMobile #noticeBoard .board_main table td:nth-child(3) {
  text-align: left;
}

#noticeBoard .board_main table th:nth-child(2),
#noticeBoard .board_main table td:nth-child(2) {
  text-align: left;
  width: 70%;
}

#isMobile #noticeBoard .board_main table th:nth-child(2),
#isMobile #noticeBoard .board_main table td:nth-child(2) {
  width: 100%;
}

#noticeBoard .board_main table td:nth-child(2) div {
  cursor: pointer;
}

#noticeBoard .search_reslt {
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: #666666;
  margin: 12px 0 17px;
}

#isMobile #noticeBoard .search_reslt,
#isMobile #frequentlyBoard .search_reslt {
  font-size: 13px;
  padding: 0 20px;
}

#noticeBoard .search_reslt strong {
  font-weight: 500;
  color: #000000;
}

.search_condition_column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search_condition_column select {
  width: 140px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  font-size: 16px;
  letter-spacing: -0.4px;
  color: #000000;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAYAAADwdn+XAAAABHNCSVQICAgIfAhkiAAAAOJJREFUKFNj1NTUnPL79++qO3fufGIgAWhras65ev16CiNQD6OWpuZzBkZGs2vXrj0ixgwtLa1N////n3/9+vX1IAPAAGjIjT9//4bfunXrIj5DtDQ0jvxnZGwAat4DUgc3AGrI8X///1fcuHHjIDZDgJZc+c/AEAfUfA4mj2IA2BANje1AG2YCFW1ANgSo+envP39sb9++fQ9ZHMMAkCQwYJcx/vu3+9rNm/Pl5OQEebm5n37++lX60aNH79FdhtUAqCFTgQG1j4mRcdK169elcYULTgNAGjQ0NNqB4VGJL1ABvRReCRJb2cEAAAAASUVORK5CYII=) no-repeat 110px 50% !important;
  margin-right: 10px;
}

#isMobile .search_condition_column select.left {
  margin-right: 10px;
}

#isMobile .search_condition_column select {
  width: 50%;
  height: 40px;
  border-radius: 4px;
  margin-right: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAICAYAAADwdn+XAAAABHNCSVQICAgIfAhkiAAAAOJJREFUKFNj1NTUnPL79++qO3fufGIgAWhras65ev16CiNQD6OWpuZzBkZGs2vXrj0ixgwtLa1N////n3/9+vX1IAPAAGjIjT9//4bfunXrIj5DtDQ0jvxnZGwAat4DUgc3AGrI8X///1fcuHHjIDZDgJZc+c/AEAfUfA4mj2IA2BANje1AG2YCFW1ANgSo+envP39sb9++fQ9ZHMMAkCQwYJcx/vu3+9rNm/Pl5OQEebm5n37++lX60aNH79FdhtUAqCFTgQG1j4mRcdK169elcYULTgNAGjQ0NNqB4VGJL1ABvRReCRJb2cEAAAAASUVORK5CYII=) no-repeat 90% 50% !important;
  font-size: 13px;
  padding: 0 16px;
}

.isMobile_select_wrapper {
  display: flex;
}

#isMobile .isMobile_select_wrapper {
  width: 100%;
}

#isMobile .search_condition_column {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.search_condition_column .search_input {
  width: 400px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px 0 26px;
}

#isMobile .search_condition_column .search_input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-top: 10px;
  padding: 0 16px;
}

#isMobile .search_condition_column .search_input input {
  font-size: 13px;
}

#isMobile .search_condition_column .search_input img {
  width: 18px;
}

.search_condition_column .search_input input {
  width: 90%;
}

#noticeBoard .search_warning {
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
  text-align: center;
  color: #e40f0f;
  margin-top: 6px;
}

#isMobile #noticeBoard .search_warning,
#isMobile #frequentlyBoard .search_warning {
  font-size: 12px;
}

#isMobile .noticeBoard_table thead {
  display: none;
}

#isMobile .noticeBoard_table tr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;
  border-bottom: 1px solid #eeeeee;
}

#isMobile .noticeBoard_table td {
  line-height: 1.5;
  border: none;
  width: 100%;
  text-align: left;
}

#isMobile .noticeBoard_table td:nth-child(2) {
  font-weight: bold;
  font-size: 15px;
}

.detail_title_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #eeeeee;
  border-bottom: 2px solid #f1f1f1 !important;
  padding: 24px 0;
}

#isMobile .detail_column {
  padding: 0 20px;
}

#isMobile .detail_title_column {
  border: none;
  flex-direction: column;
  align-items: flex-start;
}

.detail_title_column h2 {
  padding-bottom: 0 !important;
}

.detail_title_column .regdate {
  line-height: 1.75;
  letter-spacing: -0.4px;
  color: #666666;
}

#isMobile .detail_title_column .regdate {
  font-size: 14px;
  margin-top: 10px;
}

.detail_content_column {
  width: 100%;
  min-height: 440px;
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.45px;
  padding: 34px 0;
  border: none;
  border-bottom: 2px solid #f1f1f1;
}

#isMobile .detail_content_column {
  font-size: 13px;
  min-height: auto;
}

.detail_column .btn_column {
  display: flex;
  align-items: center;
}

#isMobile .detail_column .btn_column {
  font-size: 13px;
  justify-content: space-between;
  margin-top: 16px;
}

.detail_column .btn_column .prev {
  margin-right: 17px;
}

.detail_column .btn_column .next {
  margin-left: 17px;
}

.mypage_tab_menu {
  display: flex;
  justify-content: space-around;
  margin: 51px 0 19px;
}

#isMobile .mypage_tab_menu {
  padding: 20px 0;
  margin: 0;
  justify-content: space-around;
  border-bottom: 1px solid #eeeeee;
}

#agreement .mypage_tab_menu {
  margin-bottom: 34px;
}

#isMobile #agreement .mypage_tab_menu {
  margin-bottom: 0;
  border-bottom: none;
}

.mypage_tab_menu .tab {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: -0.45px;
  padding: 0 13px;
  cursor: pointer;
}

#agreement .mypage_tab_menu .tab {
  padding: 0;
}

#isMobile #agreement .mypage_tab_menu .tab {
  color: #999999;
  font-size: 15px;
}

#isMobile .mypage_tab_menu .tab:first-child {
  margin-left: 0;
  margin-right: 0;
}

.mypage_tab_menu .tab.active {
  border-bottom: 6px solid #056fb8;
}

#isMobile .mypage_tab_menu .tab.active {
  border-bottom: 3px solid #056fb8;
  padding: 0;
  color: #000000 !important;
}

#ongoing table th:last-child {
  width: 170px;
}

.mypage_tab_menu .tab span {
  font-size: 14px;
  letter-spacing: -0.35px;
}

.event_table table td {
  padding: 20px 0;
  line-height: 1;
}

.event_table table .period {
  font-size: 14px;
  letter-spacing: -0.35px;
}

.event_table table th:nth-child(3) {
  text-align: left;
}

.agreement_desc {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.4px;
  border-top: 1px solid #eeeeee;
  padding: 35px 0;
  resize: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  max-height: 700px;
  height: 100%;
}

#isMobile .agreement_desc {
  font-size: 13px;
  border: none;
  padding: 0 20px;
  height: calc(100vh - 200px);
  overflow-x: hidden;
}

#mypageModify .profile_wrapper {
  width: 559px;
}

#isMobile #mypageModify .profile_wrapper {
  width: 100%;
}

#mypageModify .profile_column {
  width: 540px;
  padding-left: 0;
  padding-bottom: 36px;
  border-bottom: 1px solid #dddddd;
}

#isMobile #mypageModify .profile_column {
  width: 100%;
  border: none;
}

#isMobile .profile_column {
  padding: 0 20px;
  margin: 34px auto;
  align-items: center;
  flex-direction: column;
}

#isMobile #MypageMobile .profile_column {
  flex-direction: row;
}

#mypageModify .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 54px;
  height: auto;
}

#isMobile #mypageModify .profile {
  margin-right: 0;
  margin-bottom: 16px;
}

#mypageModify .profile img {
  margin-bottom: 17px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
}

#isMobile #mypageModify .profile img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

#isMobile #mypageModify .profile .file_name {
  font-size: 12px;
  text-align: center;
}

.profile_btn_column {
  display: flex;
  align-items: center;
}

.profile_btn_column .btn {
  width: 120px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #056fb8;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #056fb8;
}

#isMobile .profile_btn_column .btn {
  width: 86px;
  height: 40px;
  border-radius: 4px;
  font-size: 13px;
}

.profile_btn_column .add_picture {
  margin-right: 10px;
}

.profile_btn_column #add_picture {
  display: none;
}

.profile_btn_column img {
  margin-right: 7px;
}

#isMobile .profile_btn_column img {
  height: 11px;
}

form.modify_mypage {
  padding: 33px 0 42px;
}

#isMobile form.modify_mypage {
  padding: 0 0 30px;
}

form.mypage .column {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

#isMobile form.mypage .column {
  flex-direction: column;
  align-items: flex-start;
}

#isMobile form.mypage .isMobile_column_chk,
#isMobile form.mypage .isMobile_column_ad {
  margin-bottom: 0;
}

#isMobile form.mypage .column.isMobile_column {
  flex-direction: row;
  align-items: center;
}

#isMobile form.mypage .column.isMobile_column_ad .input_name {
  width: auto;
}

form.mypage .column.warning_column {
  margin-bottom: 0;
}

form.mypage .column .input_column {
  align-items: center;
}

#isMobile form.mypage .column .input_column {
  width: 100%;
}

form.mypage .column .input_name {
  width: 122px;
}

#isMobile form.mypage .column .input_name {
  width: 45px;
  font-size: 12px;
  margin-bottom: 10px;
}

#isMobile form.mypage .isMobile_column_pw .input_name {
  width: 70px;
}

#isMobile form.mypage .column.isMobile_column .input_name {
  margin-bottom: 0;
}

form.mypage .input_info {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
}

#isMobile form.mypage .input_info {
  font-size: 13px;
}

form.mypage input {
  width: 300px;
}

.certify_column {
  margin: 13px 0 9px;
}

#isMobile .certify_column {
  margin: 0 0 9px;
}

.tel_certify_column {
  display: flex;
  margin-right: 11px;
}

.tel_certify {
  width: 80px;
  height: 30px;
  background-color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile .tel_certify {
  font-size: 12px;
  width: 66px;
  height: 26px;
}

.tel_certify.active {
  background-color: #056fb8;
}

.tel_certify_column .ok {
  border-radius: 4px 0 0 4px;
}

.tel_certify_column .no {
  border-radius: 0 4px 4px 0;
}

.check_btn.change_password {
  margin-left: 0;
}

#mypageModify .own_radio_column {
  display: flex;
}

#mypageModify .own_radio_column .radio_wrap.left {
  margin-right: 28px;
}

#isMobile #mypageModify .own_radio_column .radio_wrap.left {
  margin-right: 14px;
}

#mypageModify .own_radio_column .radio_wrap,
#mypageModify .modify_mypage .checkbox_wrap {
  position: relative;
}

#mypageModify .own_radio_column .info {
  margin-left: 16px;
  color: #666666;
}

#isMobile #mypageModify .own_radio_column .info {
  padding-top: 2px;
}

#mypageModify .checkbox_coloumn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 434px;
}

#mypageModify .withdrawal_modal .checkbox_coloumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#isMobile #mypageModify .checkbox_coloumn {
  width: 90%;
}

#isMobile #mypageModify .withdrawal_modal .checkbox_coloumn {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

#mypageModify .checkbox_wrap:last-child {
  text-align: end;
}

.mypage_modify_info {
  width: 100%;
  border-radius: 6px;
  background-color: #f1f1f1;
  padding: 24px 26px;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.35px;
  color: #666666;
}

#isMobile .mypage_modify_info {
  padding: 15px 10px;
  font-size: 12px;
  border-radius: 4px;
}

.withdrawal {
  margin: 47px auto 60px;
  background-color: #999999;
}

#isMobile .withdrawal {
  width: 140px;
  margin: 16px auto 0;
}

#isMobile #frequentlyBoard table tr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#isMobile #frequentlyBoard table td {
  width: 100%;
  text-align: left;
  padding: 0 20px !important;
  line-height: 1.8;
}

#isMobile #frequentlyBoard table td:first-child {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px !important;
}

#isMobile #frequentlyBoard table td:nth-child(2) {
  padding-bottom: 16px !important;
  font-weight: bold;
}

#isMobile #frequentlyBoard table td:nth-child(2) .answer {
  font-weight: normal;
}

#isMobile #frequentlyBoard table td:nth-child(2) .answer_column img {
  width: 20px;
}

#isMobile #frequentlyBoard table td:nth-child(2) .answer_column {
  margin-bottom: 0;
}

#frequentlyBoard .answer_column {
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
}

#frequentlyBoard .answer_column .answer {
  line-height: 1.5;
  letter-spacing: -0.4px;
  margin-left: 16px;
}

#frequentlyBoard .board_main table th:nth-child(1) {
  width: 15%;
}

#frequentlyBoard .board_main table td:nth-child(1) {
  vertical-align: baseline;
}

#frequentlyBoard .board_main table th:nth-child(2) {
  width: 72%;
}

#frequentlyBoard .board_main table td:nth-child(2) {
  text-align: left;
}

#frequentlyBoard .board_main table td {
  cursor: default;
  height: auto;
}

#frequentlyBoard .board_main table td:nth-child(3) {
  text-align: right;
  padding-right: 24px;
  vertical-align: baseline;
}

#frequentlyBoard .board_main table td:nth-child(3) img {
  cursor: pointer;
}

#frequentlyBoard .search_condition_column {
  margin-top: 17px;
}

#frequentlyBoard .search_warning {
  margin-top: 6px;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
  text-align: center;
  color: #e40f0f;
}

#frequentlyBoard .search_reslt {
  margin-top: 10px;
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: #666666;
}

#frequentlyBoard .search_reslt strong {
  font-weight: 500;
  color: #000000;
}

#inquiryBoard .board_main table th:nth-child(2),
#inquiryBoard .board_main table td:nth-child(2) {
  text-align: left;
}

#inquiryBoard table .reply_title {
  font-weight: normal !important;
}

#isMobile #inquiryBoard table .reply_title {
  font-weight: bold !important;
  margin-top: 16px;
}

.new_icon {
  width: 32px;
  height: 18px;
  border-radius: 4px;
  background-color: #ea0303;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -0.25px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

#isMobile .new_icon {
  width: 26px;
  height: 16px;
}

#inquiryBoard .board_btn_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #eeeeee;
  padding: 22px 0 14px;
}

#isMobile #inquiryBoard .board_btn_column {
  padding: 20px;
  border-top: none;
  border-bottom: 1px solid #eeeeee;
}

.info_btn_column {
  line-height: 1.75;
  letter-spacing: -0.4px;
}

#isMobile .info_btn_column {
  width: 65%;
  font-size: 13px;
}

.info_btn_column strong {
  font-weight: bold;
}

.inquiry_btn {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  background-color: #0d6fb8;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.88;
  letter-spacing: -0.4px;
  color: #ffffff;
}

#isMobile .inquiry_btn {
  width: 80px;
  height: 33px;
  font-size: 13px;
}

#inquiryBoard table td:nth-child(2) div {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
}

#isMobile #inquiryBoard table td:nth-child(2) .check_reply {
  width: 26px;
}

.inquiry_write .write_column {
  padding-top: 35px;
}

.inquiry_write .write_column form input {
  width: 100%;
}

.inquiry_write .write_column form .write_content {
  align-self: baseline;
  margin-top: 10px;
}

.inquiry_write .write_column form .btn_column {
  margin-bottom: 0;
}

.inquiry_write form .warning.last {
  text-align: center;
  margin-top: 14px;
}

#inquiryBoard .write_column {
  border-top: 2px solid #eeeeee;
}

#isMobile #inquiryBoard .write_column {
  border-top: 1px solid #eeeeee;
}

#inquiryBoard .write_column form:first-child {
  padding-top: 0;
}

#inquiryBoard form.reply_form {
  background-color: #f1f1f1;
}

#isMobile #inquiryBoard form.reply_form {
  padding: 20px;
}

#inquiryBoard form {
  padding: 36px 38px 54px;
}

#inquiryBoard form textarea {
  height: 200px;
}

#inquiryBoard .write_column form .column_name {
  min-width: 114px;
  width: 114px;
}

#isMobile #inquiryBoard .write_column form .column_name {
  min-width: 45px;
  width: 45px;
}

#isMobile #inquiryBoard .btn_column {
  padding: 0 20px;
  margin-bottom: 24px;
}

#inquiryBoard .write_column .blue_btn {
  margin: 28px auto 0;
}

#inquiryBoard .btn_column.modify {
  display: flex;
  align-items: center;
  justify-content: center;
}

#inquiryBoard .btn_column.modify .blue_btn {
  margin: 0 10px;
}

#inquiryBoard .write_column {
  width: auto;
}

#isMobile #inquiryBoard table tr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #eeeeee;
  padding: 16px 0;
}

#isMobile #inquiryBoard table thead tr {
  padding: 0;
}

#isMobile #inquiryBoard table tr.have_reply {
  border: none;
  padding-bottom: 0;
}

#isMobile #inquiryBoard table td {
  width: 100%;
  text-align: left;
  padding: 0 20px !important;
  border: none;
  line-height: 1.5;
}

#isMobile #inquiryBoard table td:nth-child(2) {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.my_notice_info {
  display: flex;
  margin-top: 16px;
  font-size: 14px;
}

.my_notice_info .bar {
  width: 1px;
  height: 14px;
  margin: 0 6px;
  background-color: #000000;
}

#isMobile #myArticle,
#isMobile #myNotice,
#isMobile #EventBoard,
#isMobile #agreement,
#isMobile #frequentlyBoard,
#isMobile #inquiryBoard,
#isMobile #InquiryWrite {
  padding: 0;
}

#isMobile #EventBoard .isMobile_tr td:nth-child(1) {
  width: 110px;
}

#isMobile #EventBoard .isMobile_tr td:nth-child(2) {
  padding: 16px 0 !important;
}

#isMobile #EventBoard .isMobile_tr .event_img {
  width: 66px;
  height: 66px;
  margin: 0 16px;
}

#isMobile #EventBoard .isMobile_tr div {
  text-align: left;
  line-height: 1.5;
}

#isMobile #EventBoard .isMobile_tr .title {
  font-weight: bold;
}

#isMobile #EventBoard .isMobile_tr .period {
  font-size: 12px;
}

#isMobile .isMobile_inquiryBoard_h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile .isMobile_inquiryBoard_h2 img {
  width: 14px;
}

table.addr_alarm {
  width: 100%;
}

table.addr_alarm th {
  vertical-align: middle;
  padding: 10px 6px !important;
}

table.addr_alarm .column__on-off {
  justify-content: center;
  margin-right: 0;
}

#isMobile table.addr_alarm th {
  font-size: 12px;
}

#isMobile table.addr_alarm .label__on-off {
  width: 50px;
}

#isMobile table.addr_alarm .label__on-off .on,
#isMobile table.addr_alarm .label__on-off .off {
  font-size: 10px;
}

#isMobile table.addr_alarm .label__on-off .off {
  padding-left: 25px;
}

#isMobile table.addr_alarm .input__on-off:checked + .label__on-off .marble {
  left: 29px;
}

