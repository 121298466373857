.find_user .title_info {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.45px;
  text-align: center;
  margin-top: 33px;
  margin-bottom: 53px;
}

#isMobile .find_user .title_info {
  width: 100%;
  font-size: 14px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}

#isMobile #findId.find_user .title_info,
#isMobile #findPw.find_user .title_info {
  margin-bottom: 20px;
}

.find_user .title_info.confirm {
  margin-bottom: 4px;
}

.find_user form input {
  width: 360px;
}

.find_user form input.wide {
  width: 100%;
}

.find_user form .input_column.auth_num_column {
  align-items: center;
  border-radius: 6px;
  border: solid 1px #dddddd;
  padding-right: 24px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.find_user form input.auth_num {
  border: none;
  width: -webkit-fill-available;
}

.find_user form .auth_num_column .counter {
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
}

#isMobile .find_user form .auth_num_column .counter {
  font-size: 12px;
}

.find_user form .check_btn {
  width: 140px;
}
.find_user .btn_column .nice_btn {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.find_user form .info {
  line-height: 1.2;
  color: #666666;
}

.find_user .btn_column {
  text-align: center;
}

.find_user form .info.btn_bottom {
  line-height: 2.14;
  text-align: center;
  margin-bottom: 100px;
}

.find_user form .warning {
  margin-bottom: 20px;
}

#isMobile .find_user form .warning {
  margin-bottom: 4px;
}

.find_user .user_id {
  font-size: 24px;
  line-height: 1.75;
  letter-spacing: -0.6px;
}

#isMobile .find_user .user_id {
  font-size: 18px;
  font-weight: bold;
}

#isMobile #findId form {
  width: 100%;
}

.find_user .login_btn {
  margin: 76px auto 0;
}

.find_user .new_pw {
  margin-top: 38px;
}

.find_user .new_pw input {
  width: 510px;
}

.find_user form .new_pw .input_column {
  flex-direction: column;
}

.find_user form .input_name {
  font-size: 18px;
  letter-spacing: -0.45px;
  margin-bottom: 8px;
}

.find_user .new_pw .warning {
  margin-bottom: 4px;
}

.find_user .new_pw .login_btn {
  margin-top: 18px;
}

#dormantUser .title_info {
  font-size: 24px;
  line-height: 2.25;
  letter-spacing: -0.6px;
  color: #000000;
  margin-bottom: 30px;
}

#isMobile #dormantUser .title_info,
#isMobile #dormantUser .title_info span {
  font-size: 14px;
  line-height: 1.5;
}

#isMobile #dormantUser .title_info {
  margin: 16px 0;
}

#dormantUser .title_info span {
  font-size: 16px;
  line-height: 2.25;
  letter-spacing: -0.4px;
  color: #666666;
  display: block;
}

#dormantUser .title_info span.inner_span {
  display: inline-block;
}

#dormantUser .title_info strong {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #000000;
}

#isMobile #findPw form {
  width: 100%;
}
