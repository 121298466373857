.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100% !important;
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin-left: 0;
}

#isMobileHome .swiper-pagination-bullet {
  background-color: #dddddd;
  opacity: 1;
}

#isMobileHome .swiper-pagination-bullet {
  width: 4px;
  height: 4px;
}

.swiper-pagination-bullet-active {
  width: 60px;
  border-radius: 4px;
  background-color: #056fb8;
}

#isMobileHome .swiper-pagination-bullet-active {
  width: 30px;
  height: 4px;
  background-color: #056fb8;
}

.swiper-pagination-bullet:not(:last-child) {
  margin-right: 20px !important;
}

#isMobileHome .swiper-pagination-bullet:not(:last-child) {
  margin-right: 10px !important;
}

#sliderMainBanner .swiper-button-next:after,
#sliderMainBanner .swiper-container-rtl .swiper-button-prev:after {
  content: url("../images/slide_next.png");
}

#sliderMainBanner .swiper-button-prev:after,
#sliderMainBanner .swiper-container-rtl .swiper-button-next:after {
  content: url("../images/slide_prev.png");
}

#isMobileHome #sliderMainBanner .swiper-button-next:after,
#isMobileHome
  #sliderMainBanner
  .swiper-container-rtl
  .swiper-button-prev:after {
  content: "";
}

#isMobileHome #sliderMainBanner .swiper-button-prev:after,
#isMobileHome
  #sliderMainBanner
  .swiper-container-rtl
  .swiper-button-next:after {
  content: "";
}

.slide_section.single {
  padding: 44px 20px;
}

.slide_section.single .slide_content {
  margin-bottom: 0;
}

.slide_section.single .swiper-pagination {
  display: none !important;
}

.slide_section.single .swiper-button-next:after,
.slide_section.single .swiper-button-prev:after,
#isMobileHome .slide_section .swiper-button-next:after,
#isMobileHome .slide_section .swiper-button-prev:after {
  content: "";
}

.swiper-pagination,
.swiper-pagination span {
  font-size: 14px;
  letter-spacing: -0.35px;
  color: #ffffff;
}

#home .page_column_move {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 30px;
  border-radius: 15px;
  background-color: #286fa8;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

#home #sliderEventBanner .page_column_move {
  background-color: #0e0b08;
}

#isMobileHome #home #sliderEventBanner .page_column_move {
  left: 95%;
}

#isMobileHome #home .page_column_move {
  width: 60px;
  height: 20px;
}

#home .page_column_move img {
  margin-right: 6px;
}

.swiper-pagination-1,
.swiper-pagination-1 span {
  width: auto !important;
  font-size: 14px !important;
  letter-spacing: -0.35px !important;
  color: #ffffff !important;
}

#isMobileHome .swiper-pagination-1,
#isMobileHome .swiper-pagination-1 span {
  font-size: 8px !important;
}

#isMobileHome .banner {
  width: 100%;
}

#sliderEventBanner {
  margin-top: 10px;
}

#isMobileHome #sliderEventBanner {
  margin-top: 20px;
}

#sliderEventBanner img.banner {
  width: 100%;
  height: 240px;
}

#isMobileHome #sliderEventBanner img.banner {
  height: 120px;
}

#isMobileHome #sliderMainBanner img.banner {
  width: 100%;
  height: auto;
}

#sliderMainBanner.single .page_column_move,
#sliderMainBanner.single .swiper-button-disabled,
#sliderEventBanner .swiper-button-prev,
#sliderEventBanner .swiper-button-next,
#sliderEventBanner .swiper-button-disabled {
  display: none;
}
