#footer {
  width: 100%;
  background-color: #eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 50px 0;
}

#footer .container {
  width: 62.4%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  /* padding: 57px 0; */
  position: relative;
}

#isMobileHome #footer .container {
  width: 100%;
  max-width: 510px;
}

#footer .left_wrapper {
  display: flex;
  width: 100%;
}

#isMobileHome #footer .left_wrapper {
  flex-direction: column;
}

#footer .center_column {
  width: 100%;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #000000;
}

#footer .center_column .center_column_wrapper {
  display: flex;
  justify-content: space-between;
}

#footer .company {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
}

#isMobileHome #footer .company {
  font-size: 16px;
}

#footer .company_info {
  display: flex;
  flex-wrap: wrap;
}

#isMobileHome #footer .company_info,
#isMobileHome #footer .copyright {
  font-size: 12px;
  line-height: 1.8;
}

#footer .right_column {
  font-size: 18px;
  letter-spacing: -0.45px;
  color: #000000;
  min-width: 270px;
}

#isMobileHome #footer .right_column {
  display: none;
}

#footer .isMobile_right_column {
  display: none;
}

#isMobileHome #footer .isMobile_right_column {
  display: block;
  font-size: 12px;
  text-align: right;
}

#footer .move_top {
  position: absolute;
  top: -90px;
  right: 0;
  width: 60px;
  height: 60px;
  border-radius: 4px;
  box-shadow: 5px 8.7px 30px 0 rgba(0, 0, 0, 0.1);
  background-color: #056fb8;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobileHome #footer .move_top {
  width: 40px;
  height: 40px;
  top: -45px;
}

#isMobileHome #footer .move_top img {
  width: 11px;
}

#isMobileHome #footer {
  padding: 16px 20px 16px 12px;
}

#isMobileHome #footer img {
  width: 65px;
}
@media screen and (max-width: 530px) {
  #isMobileHome #footer .isMobile_right_column {
    display: none;
  }
}
