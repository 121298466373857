.board_main .section_1 {
  width: 100%;
  height: 300px;
  background-color: #3795e1;
  display: flex;
  justify-content: center;
}

#isMobile .board_main .section_1 {
  height: 120px;
}

#information,
#counseling {
  padding-bottom: 60px;
}

#information .section_1 {
  background-color: #9695da;
}

.board_main .section_1 .container {
  width: 1000px;
  position: relative;
}

#isMobile .board_main .section_1 .container {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
  max-width: 450px;
}

.board_main .section_1 .title {
  font-family: "NanumSquare";
  font-weight: 800;
  font-size: 40px;
  line-height: 0.85;
  letter-spacing: -2px;
  color: #ffffff;
  margin-top: 50px;
  margin-bottom: 15px;
}

#isMobile .board_main .section_1 .title {
  font-size: 20px;
  margin-top: 0;
}

#information .section_1 .title {
  margin-top: 80px;
}

.board_main .section_1 .desc {
  font-family: "NanumSquare";
  font-weight: 400;
  font-size: 24px;
  line-height: 1.42;
  letter-spacing: -1.2px;
  color: #ffffff;
}

#isMobile .board_main .section_1 .desc {
  font-size: 14px;
}

.board_main .go_btn_column .go_btn {
  width: 180px;
  margin-top: 29px;
}

#isMobile .board_main .go_btn_column:not(.apply_counseling) .go_btn {
  margin-top: 5px;
  height: 28px;
}

.board_main .board_main_img {
  position: absolute;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

#isMobile .board_main .board_main_img {
  width: 116px;
  right: 8px;
}

.board_main .section_2 {
  width: 62.4%;
  display: flex;
  flex-direction: column;
  margin: auto;
}

#isMobile .board_main .section_2 {
  width: 100%;
}

.depth_column {
  display: flex;
  align-items: center;
  margin-top: 58px;
  margin-bottom: 18px;
}

#isMobile .depth_column {
  display: none;
}

.depth_column .board_name {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
}

.depth_column .board_name span {
  font-weight: normal;
  margin-left: 14px;
}

.board_main .table_column {
  min-height: 660px;
}

#isMobile .board_main .table_column {
  min-height: auto !important;
}

#isMobile #information.board_main .table_column {
  padding: 20px;
  margin: auto;
  max-width: 450px;
}

.board_main .table_column.board_card_column {
  min-height: 0;
}

.board_main table {
  width: 100%;
}

.board_main table th {
  background-color: #fafafa;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  height: 60px;
  vertical-align: middle;
  font-weight: 500;
  line-height: 3;
  letter-spacing: -0.4px;
}

.board_main table th.day {
  min-width: 82px;
}

#isMobile .board_main table th {
  display: none;
}

.board_main table th.th_day {
  width: 15%;
}

.board_main table tr td {
  border-bottom: 1px solid #cccccc;
}

#isMobile .board_main table tr td {
  border-bottom: 1px solid #eeeeee;
}

.board_main table tr.have_reply td {
  vertical-align: baseline;
}

.board_main table tr.no_visible td,
.board_main table tr.no_visible a,
.board_main table tr.no_visible div:not(.check_reply) {
  color: #cccccc !important;
}

#isMobile .board_main table tr:not(.have_reply) td {
  border-bottom: 1px solid #eeeeee;
}

#isMobile #noticeBoard .board_main table tr:not(.have_reply) td {
  border: none;
}

.board_main table td {
  line-height: 3;
  letter-spacing: -0.4px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
}

#isMobile .board_main table td {
  font-size: 13px;
}

.board_main table td:nth-child(3) {
  text-align: left;
  cursor: pointer;
}

.check_reply {
  width: 60px;
  height: 30px;
  border-radius: 4px;
  background-color: #cccccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-size: 14px;
  line-height: 3.43;
  letter-spacing: -0.35px;
  color: #ffffff;
}

#isMobile .check_reply {
  width: 26px;
  height: 16px;
  font-size: 11px;
  margin: 0 0 0 6px;
}

#isMobile .check_reply.ing {
  background-color: #5c5c5c;
  font-weight: bold;
  width: 90px;
}

.check_reply.finish {
  background-color: #0d6fb8;
  font-weight: bold;
}

.check_reply.ing {
  background-color: #5c5c5c;
  font-weight: bold;
  width: 120px;
}


.board_main table .regdate {
  font-size: 14px;
  line-height: 3.43;
  letter-spacing: -0.35px;
  color: #999999;
}

#isMobile #inquiryBoard table .regdate {
  font-size: 12px;
  line-height: 1.5;
}

.board_main table .regdate.reply_regdate {
  color: #000000;
}

.board_main table .writer_admin,
.board_main table .reply_title {
  font-weight: bold;
  line-height: 3.13;
  letter-spacing: -0.4px;
}

.board_main table .reply_title {
  line-height: 1.7;
}

.board_main table .reply_title .reply_column {
  display: flex;
  align-items: flex-start;
}

.board_main table .reply_title img {
  margin-right: 8px;
}

#isMobile .board_main table .reply_title img {
  width: 20px;
  margin-right: 10px;
}

.board_main .apply_counseling {
  margin-left: auto;
  margin-top: 20px;
  margin-bottom: 34px;
}

.board_main .apply_counseling .go_btn {
  background-color: #0d6fb8;
  margin-top: 0;
}

.pagenation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 129px;
}

#isMobile .pagenation {
  margin: 0 0 24px;
}

#information .pagenation {
  margin-top: 24px;
}

.pagenation .page {
  display: flex;
  align-items: center;
  margin: 0 34px;
  text-align: center;
}

#isMobile .pagenation .page {
  margin: 0 18px;
}

.pagenation .page li {
  font-family: "NanumSquare";
  font-size: 14px;
  line-height: 1.75;
  color: #999999;
  cursor: pointer;
  display: inline-block;
  border-bottom: 3px solid transparent;
  width: 20px;
}

#isMobile .pagenation .page li {
  font-size: 11px;
  width: auto;
}

.pagenation .page li:not(:last-child) {
  margin-right: 30px;
}

#isMobile .pagenation .page li:not(:last-child) {
  margin-right: 18px;
}

.pagenation .page li.active {
  color: #333333;
  border-bottom-color: #000000;
}

.pagenation .page .active a {
  color: #333333 !important;
}

.pagenation .page_first {
  margin-right: 15px;
}

.pagenation .page_last {
  margin-left: 15px;
}

.write_column {
  width: 920px;
  margin: auto;
  padding-top: 72px;
}

#isMobile .write_column {
  width: 100%;
  padding-top: 13px;
}

.write_column.reply_column {
  width: 1000px;
  margin: 45px auto 0;
  background-color: #f1f1f1;
  padding: 48px 40px 75px;
}

#isMobile .write_column.reply_column {
  padding: 19px 0;
}

.write_column .title {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.75px;
}

#isMobile .write_column .title {
  font-size: 18px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  line-height: 1;
}

#isMobile .isMobile_detail .title {
  align-items: flex-start;
  flex-direction: column;
}

#isMobile .isMobile_detail .title span {
  margin-top: 24px;
}

#isMobile .caution_column .title {
  font-size: 12px;
  padding: 0;
}

.write_column .hr {
  width: 100%;
  border-bottom: 2px solid #f1f1f1;
  margin-top: 25px;
  margin-bottom: 37px;
}

#MypageMobile .write_column .strong_hr {
  border-bottom: 4px solid #f1f1f1;
}

#isMobile .write_column .hr {
  margin: 13px 0 0;
}

.write_column form {
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .write_column form {
  padding: 0 20px;
}

.write_column form .form_column {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

#isMobile .write_column form .isMobile_form_column {
  flex-direction: row !important;
}

#isMobile .reply_column form .form_column {
  margin-bottom: 0px;
}

#isMobile .reply_column form .isMobile_title {
  margin-top: 11px;
}

#isMobile .reply_column form .form_column input {
  margin-bottom: 16px;
}

#isMobile .write_column form .isMobile_writer {
  height: 35px;
}

#isMobile .write_column form .form_column:not(.isMobile_writer) {
  flex-direction: column;
  align-items: flex-start;
}

.write_column form .column_name {
  min-width: 117px;
}

#isMobile .write_column form .column_name {
  min-width: 60px;
  font-size: 12px;
  margin-bottom: 6px;
}

#isMobile .isMobile_writer .column_name {
  margin-bottom: 0 !important;
}

.write_column form select {
  width: 300px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .write_column form select {
  width: 100%;
  height: 40px;
  padding: 0 20px;
  font-size: 14px;
}

.write_column form .writer {
  height: 50px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

#isMobile .write_column form .writer {
  height: auto;
  font-size: 13px;
  margin-left: 10px;
}

.write_column form textarea {
  font-size: 18px;
  letter-spacing: -0.45px;
  /* width: 800px; */
  width: 100%;
  height: 400px;
  padding: 24px;
}

#isMobile .write_column form textarea {
  font-size: 14px;
  padding: 8px;
  height: 130px;
  border-radius: 4px;
}

.write_column form .write_content {
  align-self: baseline;
}

.write_column .caution_wrapper {
  display: flex;
  align-items: flex-start;
  border-radius: 6px;
  background-color: #f1f1f1;
  padding: 20px 27px 24px;
  margin-top: 28px;
  margin-bottom: 38px;
}

#isMobile .write_column .caution_wrapper {
  padding: 11px 10px 24px;
  margin-top: 12px;
  margin-bottom: 32px;
}

#isMobile .write_column .caution_wrapper img {
  width: 13px;
}

.write_column .caution_column {
  margin-left: 12px;
}

#isMobile .write_column .caution_column {
  margin-left: 3px;
}

.write_column .caution_column .title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: -0.45px;
  color: #666666;
}

.write_column .caution_column ul {
  margin-top: 10px;
  margin-bottom: 5px;
}

#isMobile .write_column .caution_column ul {
  margin-top: 4px;
}

.write_column .caution_column li {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: -0.4px;
  color: #666666;
}

.write_column .caution_column li:first-child {
  font-weight: bold;
}
#isMobile .write_column .caution_column li {
  font-size: 12px;
}

.write_column .caution_column .checkbox_column {
  display: flex;
  align-items: center;
}

.write_column .caution_column .checkbox_wrap {
  position: relative;
}

.write_column .caution_column label {
  font-size: 20px;
  line-height: 1;
  letter-spacing: -0.5px;
}

.write_column .checkbox_column .warning {
  margin-left: 7px;
}

.write_column form .btn_column {
  display: flex;
  justify-content: center;
  margin-bottom: 123px;
}

#isMobile .write_column form .btn_column {
  margin-bottom: 26px;
}

.write_column form .btn_column .close {
  margin-right: 13px;
}

.go_list {
  width: 160px;
  height: 50px;
  border-radius: 10px;
  border: solid 2px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.9px;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 46px auto 30px;
}

#isMobile .go_list {
  width: 100%;
  max-width: 400px;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  margin: 21px auto 32px;
}

#isMobile #noticeBoard .go_list {
  width: 80px;
  margin: 0;
}

#isMobile .isMobile_go_list_wrapper {
  width: 100%;
  padding: 0 20px;
}

.board_card_column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.board_card_column .board_card {
  margin-bottom: 50px;
}

#isMobile .board_card_column .board_card {
  margin-bottom: 10px;
}

.board_card .board_card_img {
  width: 380px;
  height: 380px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

#isMobile .board_card .board_card_img {
  min-width: 156px;
  width: 26vw;
  min-height: 100px;
  height: 16vw;
}

.board_card .board_card_img img {
  width: 100%;
  height: 100%;
}

.board_card .board_card_info {
  width: 380px;
  height: 160px;
  background-color: #f1f1f1;
  padding: 16px 26px 0;
}

#isMobile .board_card .board_card_info {
  min-width: 156px;
  width: 26vw;
  padding: 6px 10px 0;
  height: 140px;
}

.board_card .board_card_title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.45px;
}

#isMobile .board_card .board_card_title {
  font-size: 14px;
  letter-spacing: -1.45px;
}

.board_card .board_card_desc {
  height: 54px;
  line-height: 1.88;
  letter-spacing: -0.4px;
  color: #666666;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 라인수 */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin: 6px 0;
}

#isMobile .board_card .board_card_desc {
  font-size: 11px;
  line-height: 1.7;
  -webkit-line-clamp: 3;
  margin: 0;
}

.board_card .board_card_wrapper {
  display: flex;
  align-items: center;
}

.board_card_date,
.board_card_writer {
  font-size: 14px;
  letter-spacing: -0.35px;
  line-height: 2.14;
  color: #666666;
}

#isMobile .board_card_date,
#isMobile .board_card_writer {
  font-size: 11px;
}

.board_card_wrapper .bar {
  border-right: solid 1px #707070;
  margin: 0 7px;
  height: 16px;
}

#informationDetail {
  width: 920px;
  padding: 72px 0 120px;
  margin: auto;
}

#isMobile #informationDetail {
  justify-content: flex-start;
}

#isMobile #informationDetail .body_column img {
  width: 100%;
}

#isMobile #informationDetail .detail_title {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#isMobile #informationDetail .detail_title span {
  margin-top: 24px;
}

#isMobile .header_column .right {
  width: 100%;
  font-size: 12px;
}

#isMobile #informationDetail .isMobile_detail {
  display: flex;
  flex-direction: column;
  padding-top: 13px;
}

#isMobile #informationDetail .btn_column .btn {
  font-size: 13px;
}

#isMobile #informationDetail .go_list {
  width: 80px;
  margin: 0 auto;
}

.header_column {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 25px;
  margin-bottom: 30px;
}

.header_column .detail_title {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.75px;
}

#isMobile .header_column .detail_title {
  font-size: 18px;
}

.header_column .right {
  display: flex;
  align-items: center;
  line-height: 1.75;
  letter-spacing: -0.4px;
  color: #666666;
}

.body_column .detail_content {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.45px;
  padding: 37px 0 52px;
  border-bottom: 2px solid #f1f1f1;
  white-space: pre-line;
}

#informationDetail .body_column {
  width: 100%;
  height: 80%;
}

#isMobile .body_column .detail_content {
  font-size: 13px;
  padding: 16px 0 24px;
}

#informationDetail .btn_column {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile #informationDetail .btn_column {
  margin-top: 24px;
}

#informationDetail .btn_column .prev {
  margin-right: 17px;
}

#isMobile #informationDetail .btn_column img {
  width: 6px;
}

#isMobile #informationDetail .btn_column .prev {
  margin-right: 8px;
}

#informationDetail .btn_column .next {
  margin-left: 17px;
}

#isMobile .board_main table td {
  height: auto;
}

#isMobile .isMobil_tr .num {
  font-size: 14px;
  width: 45px;
  padding-left: 20px !important;
  vertical-align: baseline;
}

#isMobile .isMobil_tr td div {
  line-height: 1.5;
}

#isMobile .isMobil_tr td {
  text-align: left;
  padding: 16px 0px 16px 0 !important;
}

#isMobile .isMobil_tr.have_reply td {
  padding-top: 0 !important;
}

#isMobile #myNotice .isMobil_tr td {
  padding-left: 20px !important;
}

#isMobile .isMobil_tr td .title {
  font-weight: bold;
}

#isMobile .isMobil_tr td .wrapper {
  display: flex;
  align-items: center;
}

#isMobile .isMobil_tr td .bar {
  width: 1px;
  height: 13px;
  background-color: #000000;
  margin: 0 6px;
}

#isMobile .isMobil_tr td .reply_column {
  display: flex;
  align-items: flex-start;
}

#isMobile .isMobil_tr td .reply_column img {
  width: 20px;
  margin-right: 10px;
}

.isMobile_back {
  display: none;
}

#isMobile .isMobile_back {
  display: inline-block;
  width: 8px;
}

.have_re td {
  vertical-align: baseline !important;
}

.page_link:not(:last-child) {
  margin-right: 16px;
}

#informationDetail .detail_content_column {
  width: 100%;
  height: 100%;
}
