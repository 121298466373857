#admin_header {
  width: 100%;
  min-width: 1530px;
  height: 80px;
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 33px;
}

#admin_header .left_wrapper {
  display: flex;
  align-items: center;
}

.admin_logo_column {
  display: flex;
  align-items: center;
}

.admin_logo_column h1 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
  color: #000000;
  margin-left: 10px;
}

ul.nav_menus {
  display: flex;
  align-items: center;
}

li.nav_menu {
  border-bottom: 4px solid transparent;
  padding: 10px 0;
  font-size: 18px;
  letter-spacing: -0.45px;
  color: #999999;
  margin-left: 68px;
}

li.nav_menu.active {
  border-bottom: 4px solid #1876bb;
  font-weight: 500;
  color: #000000;
}

#admin_header .right_wrapper {
  display: flex;
  align-items: center;
}

#admin_header .right_wrapper .user_name {
  font-weight: 500;
  letter-spacing: -0.4px;
}

.admin_logout_column {
  margin-left: 30px;
}

.admin_logout_column img {
  margin-right: 9px;
}
