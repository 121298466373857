* {
  color: #000000;
}

.main {
  flex: auto;
}

.center_main {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#isMobile .center_main {
  padding: 0 24px;
  max-width: 450px;
  width: 100vw;
  margin: auto;
  /* justify-content: center; */
  padding-bottom: 50px;
}

#isMobileHome,
#isMobile.root_container {
  padding-bottom: 50px;
}

#isMobile #joinAdd.center_main {
  padding: 0;
}

#isMobile .isMobile_joinAdd {
  padding: 0 24px;
}

#isMobile #joinAdd.center_main {
  align-items: flex-start;
}

#isMobile .join_user {
  align-items: flex-start;
}

/* 체크박스 */
.checkbox_wrap input[type="checkbox"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.checkbox_wrap input[type="checkbox"] + label {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  height: 18px;
}

#isMobile .checkbox_wrap input[type="checkbox"] + label {
  padding-left: 17px;
  font-size: 14px;
}

#isMobile .caution_column .checkbox_wrap input[type="checkbox"] + label {
  font-size: 13px;
}

.checkbox_wrap input[type="checkbox"] + label:before {
  content: "";
  background: url("./images/checkbox.png") center no-repeat;
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  color: #ffffff;
  text-align: center;
  background-color: #dddddd;
  box-sizing: border-box;
  border-radius: 5px;
}

#isMobile .checkbox_wrap input[type="checkbox"] + label:before,
#isMobile .checkbox_wrap input[type="checkbox"]:checked + label:after {
  width: 12px;
  height: 12px;
  border-radius: 3px;
  top: 5px;
}

.checkbox_wrap input[type="checkbox"]:checked + label:after {
  content: "";
  background: url("./images/checkbox.png") center no-repeat;
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: #0d6fb8;
  color: #ffffff;
  text-align: center;
  border-radius: 5px;
}

/* 체크박스 End */

.join_title,
.main_title {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.9px;
  margin-top: 108px;
  text-align: center;
}

#isMobile .join_title,
#isMobile .main_title {
  font-size: 22px;
  margin-top: 0;
  margin-right: auto;
  text-align: left;
}

#isMobile #joinAdd .main_title {
  margin-top: 16px;
}

.sns_login .column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#isMobile .sns_login .column {
  flex-direction: row;
  margin-top: 16px;
}

.sns_login .column img {
  margin-bottom: 10px;
}

#isMobile .sns_login .column img {
  width: 40px;
  margin-bottom: 4px;
}

#isMobile .join_user .sns_login .column img {
  margin-right: 6px;
  margin-bottom: 0;
}

form .column.manage_adress .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login_form .input_name {
  font-size: 18px;
  letter-spacing: -0.45px;
  margin-bottom: 8px;
}

#isMobile .login_form .input_name {
  font-size: 14px;
}

form input {
  width: 400px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.45px;
  padding: 0 24px;
}

#isMobile form input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  border-radius: 4px;
  padding: 0 16px;
}

form input.wide {
  width: 100%;
}

form .input_column {
  display: flex;
}

#isMobile form .input_column {
  width: 100%;
}

form .check_btn,
.add_content .check_btn {
  width: 100px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #056fb8;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #056fb8;
  cursor: pointer;
  margin-left: 10px;
}

#isMobile form .check_btn,
#isMobile .add_content .check_btn {
  width: 80px;
  height: 40px;
  font-size: 13px;
  border-radius: 4px;
}
#isMobile #findPw form .check_btn,
#isMobile #findId form .check_btn {
  width: 100px;
  min-width: 100px;
}

#isMobile #mypageAddress .add_content .check_btn {
  width: 100px;
  height: 40px;
}

form .warning {
  font-size: 14px;
  letter-spacing: -0.35px;
  color: #e30d0d;
  height: 14px;
  margin: 4px 0;
}

#isMobile form .warning {
  font-size: 12px;
  height: auto;
}

#isMobile form .caution_column .warning {
  margin-bottom: 0;
}

.login_form .warning.pass {
  color: #0d6fb8;
}

.login_form .warning.age {
  margin-bottom: 6px;
}

form .info {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.35px;
}

#isMobile form .info {
  font-size: 12px;
}

#isMobile #mypageModify form .info {
  font-size: 11px;
}

/* radio 커스텀 */
.radio_wrap input[type="radio"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.radio_wrap input[type="radio"] + label {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  height: 20px;
}

#isMobile .radio_wrap input[type="radio"] + label {
  padding-left: 18px;
}

#isMobile #mypageModify .radio_wrap input[type="radio"] + label {
  font-size: 14px;
}

.radio_wrap input[type="radio"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  text-align: center;
  background: #fff;
  border: 7px solid #dddddd;
  box-sizing: border-box;
}

#isMobile .radio_wrap input[type="radio"] + label:before,
#isMobile .radio_wrap input[type="radio"]:checked + label:after {
  width: 12px;
  height: 12px;
  border-width: 4px;
  top: 4px;
}

#isMobile #mypageModify .radio_wrap input[type="radio"] + label:before,
#isMobile #mypageModify .radio_wrap input[type="radio"]:checked + label:after {
  top: 1px;
}

.radio_wrap input[type="radio"]:checked + label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #fff;
  border: 7px solid #056fb8;
  box-sizing: border-box;
}

/* ON/OFF 스위치 */
.column__on-off {
  width: 300px;
  text-align: left;
}
.column__on-off {
  width: auto;
}

.input__on-off {
  display: none;
}

.label__on-off {
  overflow: hidden;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 24px;
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  border-radius: 13px;
  background-color: #999999;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

#isMobile .label__on-off {
  height: 22px;
}

#isMobile .label__on-off .marble {
  width: 20px;
  height: 20px;
}

#isMobile .label__on-off .off,
#isMobile .label__on-off .on {
  line-height: 22px;
}

#isMobile .input__on-off:checked + .label__on-off .marble {
  left: 39px;
}

.label__on-off > * {
  vertical-align: middle;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 14px;
}
.label__on-off .marble {
  position: absolute;
  top: 1px;
  left: 1px;
  display: block;
  width: 22px;
  height: 22px;
  background-color: #fff;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.label__on-off .on {
  display: none;
  padding-left: 9px;
  line-height: 23px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: #ffffff;
}
.label__on-off .off {
  padding-left: 29px;
  line-height: 23px;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: #ffffff;
}
.input__on-off:checked + .label__on-off {
  background-color: #056fb8;
}
.input__on-off:checked + .label__on-off .on {
  display: inline-block;
}
.input__on-off:checked + .label__on-off .off {
  display: none;
}
.input__on-off:checked + .label__on-off .marble {
  left: 37px;
}

.blue_btn,
.next_btn,
.red_btn {
  width: 160px;
  height: 50px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile .blue_btn,
#isMobile .next_btn,
#isMobile .red_btn {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-size: 15px;
}

#isMobile #mypageAddress .blue_btn {
  width: 155px;
}

#isMobile .counseling_write_form {
  margin-top: 24px;
}

#isMobile .counseling_write_form .blue_btn {
  width: 160px;
}

#isMobile .modal_ct .blue_btn,
#isMobile .modal_ct .next_btn,
#isMobile .modal_ct .red_btn {
  width: 100px;
}

.blue_btn {
  letter-spacing: -0.9px;
}

.next_btn {
  background-color: #cccccc;
  letter-spacing: -0.45px;
  margin: auto;
}

.next_btn.active,
.blue_btn {
  background-color: #0d6fb8 !important;
}

.red_btn {
  background-color: #d61616 !important;
}

.go_btn_column .go_btn {
  width: 220px;
  height: 50px;
  border-radius: 25px;
  background-color: #003867;
  font-family: "NanumSquare";
  font-weight: bold;
  font-size: 18px;
  letter-spacing: -0.9px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 22px 0 28px;
}

#isMobile .go_btn_column .go_btn {
  width: 113px;
  height: 33px;
  font-size: 12px;
  padding: 0 10px 0 18px;
  margin-right: 20px;
}

#isMobile .go_btn_column .go_btn img {
  height: 11px;
}

#isMobileHome .go_btn_column .go_btn {
  width: 140px;
  height: 30px;
  font-size: 13px;
  padding: 0 10px 0 16px;
}

#isMobileHome .go_btn_column .go_btn img {
  width: 5px;
}

#map {
  width: 600px;
  min-width: 500px;
  max-width: 600px;
  width: 40vw;
  text-align: center;
}

#map.addr_list {
  width: 300px;
  height: 300px;
  min-width: 300px;
  max-width: 300px;
}

#isMobile #map {
  min-width: auto;
  max-width: initial;
  width: auto;
}

#isMobile #map.addr_list {
  width: 100%;
}

.ie_container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#isMobile .checkbox_wrap {
  position: relative;
}

.isMobile_bottom_nav {
  position: fixed;
  background-color: #ffffff;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #dddddd;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.isMobile_bottom_nav img {
  height: 18px;
}

.isMobile_bottom_menu {
  width: 25%;
  max-width: 100px;
}

.isMobile_bottom_menu a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.isMobile_bottom_menu_name {
  font-size: 12px;
  margin-top: 4px;
  color: #999999;
}

.isMobile_bottom_menu_name.active {
  color: #0d6fb8;
  font-weight: bold;
}

#unauthorized {

}

#unauthorized .horizontal_line{
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.categoryArea {
  display: flex;
  justify-content: center;
}

.categoryArea .category {
  background: #fafafa;
  border-bottom: 4px solid #ffffff;
  font-size: 20px;
  padding: 20px 30px;
  font-weight: 500;
  cursor: pointer;
}

.categoryArea .on {
  color:#fff;
  background: #056fb8;
  border-bottom: 4px solid #056fb8;
}

.categoryArea .category:hover {
  border-bottom: 4px solid #056fb8;
}

.categoryAreaM {
  display: flex;
  justify-content: center;

}

.categoryAreaM .category {
  background: #fafafa;
  border-bottom: 4px solid #ffffff;
  font-size: 16px;
  padding: 18px 30px;
  font-weight: 500;
  cursor: pointer;
}

.categoryAreaM .category:hover {
  border-bottom: 4px solid #056fb8;
}

.categoryAreaM .on {
  color:#fff;
  background: #056fb8;
  border-bottom: 4px solid #056fb8;
}

.password_input {
  width: 350px;
  height: 50px;
  margin-top: 25px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.45px;
  padding: 0 24px;
}