.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin-left: 0;
}

#isMobileHome .swiper-pagination-bullet {
  background-color: #dddddd;
  opacity: 1;
}

#isMobileHome .swiper-pagination-bullet {
  width: 4px;
  height: 4px;
}

.swiper-pagination-bullet-active {
  width: 60px;
  border-radius: 4px;
  background-color: #056fb8;
}

#isMobileHome .swiper-pagination-bullet-active {
  width: 30px;
  height: 4px;
  background-color: #056fb8;
}

.swiper-pagination-bullet:not(:last-child) {
  margin-right: 20px !important;
}

#isMobileHome .swiper-pagination-bullet:not(:last-child) {
  margin-right: 10px !important;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: url("../images/main_slider_next.png");
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: url("../images/main_slider_prev.png");
}

.mySwiper.single .slide_content {
  margin-bottom: 0;
}

.mySwiper.single .swiper-pagination {
  display: none !important;
}

.mySwiper.single .swiper-button-next:after,
.mySwiper.single .swiper-button-prev:after,
#isMobileHome .slide_section .swiper-button-next:after,
#isMobileHome .slide_section .swiper-button-prev:after {
  content: "";
}
