.mypage_main {
  width: 62.4%;
  margin: auto;
}

.mypage_header {
  width: 100%;
  border-bottom: 2px solid #cccccc;
}

.mypage_body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 27px 0 66px;
}

#isMobile .mypage_body {
  padding: 0;
}

.mypage_body .side_menu {
  width: 200px;
  height: 880px;
  border-radius: 20px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-right: 50px;
  padding: 16px 30px 0;
}

#MypageMobile .mypage_body .side_menu {
  height: auto;
}

#isMobile .mypage_body .side_menu {
  width: 100vw;
  margin-right: 0;
  border: none;
  padding: 0;
}

.mypage_body .side_menu h2 {
  font-size: 18px;
  font-weight: bold;
  line-height: 2.22;
  letter-spacing: -0.45px;
  display: flex;
  align-items: center;
}

.mypage_body .side_menu ul {
  border-bottom: 1px solid #dddddd;
}

.mypage_body .side_menu li {
  line-height: 2.4;
  letter-spacing: -0.4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#MypageMobile {
  padding-bottom: 100px;
}

#MypageMobile .mypage_body .side_menu li {
  justify-content: space-between;
  padding: 0 36px;
}

#MypageMobile ul:first-child a:first-child {
  display: none;
}

.mypage_body .side_menu .dot {
  width: 8px;
  height: 8px;
  background-color: #056fb8;
  border-radius: 50%;
  margin-right: 13px;
  visibility: hidden;
}

#MypageMobile .mypage_body .side_menu .dot {
  display: none;
}

.mypage_body .side_menu ul {
  padding-bottom: 24px;
}

#MypageMobile .mypage_body .side_menu ul {
  border-bottom: 4px solid #f1f1f1;
}

#MypageMobile .mypage_body .side_menu ul:last-child {
  border-bottom: none;
}

#MypageMobile .mypage_body .side_menu ul {
  padding: 12px 0;
}

.mypage_body .side_menu ul:not(:nth-child(1)) {
  padding-top: 24px;
}

.mypage_body .side_menu ul:not(:nth-child(2)) {
  padding-top: 24px;
}

.mypage_body .side_menu li.active {
  font-weight: bold;
}

.mypage_body .side_menu li.active .dot,
.mypage_body .side_menu .inquiry_wrapper.active .dot {
  visibility: visible;
}

.mypage_body .side_menu .inquiry_wrapper {
  display: flex;
  margin-top: 28px;
}

.mypage_body .side_menu .inquiry_wrapper .dot {
  margin-top: 20px;
}

.mypage_body .inquiry_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 32px 20px;
  width: fit-content;
}

.mypage_body .inquiry_icon {
  position: relative;
}

.mypage_body .inquiry_count {
  width: 30px;
  height: 20px;
  border-radius: 10px;
  background-color: #056fb8;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.3px;
  color: #ffffff;
  margin-left: 8.6px;
}

.mypage_body .inquiry_icon .inquiry_count {
  position: absolute;
  top: 7px;
  right: -20px;
}

.mypage_body .inquiry_count {
  position: static;
}

.mypage_body .inquiry_column img {
  margin-top: 7px;
}

.mypage_body .logout_btn {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mypage_body .logout_btn img {
  margin-right: 10px;
}

.mypage_body .content {
  width: 100%;
}

.mypage_body .content h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
  padding-bottom: 23px;
  border-bottom: 2px solid #eeeeee;
}

#isMobile .mypage_body .content h2 {
  font-size: 17px;
  height: 54px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-width: 1px;
}

#isMobile #InquiryWrite .mypage_body .content h2 {
  justify-content: space-between;
}

#isMobile #InquiryWrite .mypage_body .content img {
  width: 14px;
}

#isMobile #mypageModify .mypage_body .content h2 {
  border: none;
  padding: 0;
}

.profile_wrapper {
  width: 620px;
  margin: auto;
}

.profile_column {
  display: flex;
  padding-left: 48px;
  margin-top: 43px;
}

.profile_column .profile {
  border-radius: 50%;
  width: 160px;
  height: 160px;
  margin-right: 40px;
}

#isMobile #MypageMobile .profile_column .profile {
  width: 80px;
  height: 80px;
  margin-right: 16px;
}

.profile_name {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.45px;
  line-height: 1.25;
  margin-bottom: 4px;
}

#isMobile .profile_name {
  font-size: 14px;
}

.profile_name strong {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
}

#isMobile .profile_name strong {
  font-size: 17px;
}

.profile_email {
  display: flex;
  align-items: center;
}

#isMobile .profile_email {
  font-size: 13px;
}

.profile_email img {
  margin-right: 8px;
}

#isMobile .profile_email img {
  width: 14px;
  margin-right: 4px;
}

.modify_btn {
  width: 120px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #056fb8;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 21px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #056fb8;
}

.modify_btn img {
  margin-right: 4px;
}

.profile_wrapper .btns_column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 74px;
}

.profile_wrapper .btns {
  width: 300px;
  height: 80px;
  border-radius: 10px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 0 32px;
}

.profile_wrapper .btns_column .btn_name {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
}

.profile_wrapper .btns_column .btn_count {
  font-size: 18px;
  font-weight: normal;
  line-height: 2.67;
  letter-spacing: -0.45px;
}

.profile_wrapper .btns_column .btn_count strong {
  font-weight: bold;
}

.profile_wrapper .btns_column .warning {
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.35px;
  color: #d51a1a;
}

.add_tab_column {
  margin-top: 41px;
  border-bottom: 1px solid #dddddd;
  display: flex;
}

#isMobile .add_tab_column {
  margin-top: 0;
  padding-top: 6px;
  background-color: #eeeeee;
  border: none;
}

.add_tab_column .add_btn,
.add_tab_column .address_tab {
  width: 200px;
  height: 50px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: -0.8px;
  cursor: pointer;
}

.add_tab_column .address_tab {
  background-color: #ffffff;
}

#isMobile .add_tab_column .add_btn,
#isMobile .add_tab_column .address_tab {
  font-size: 15px;
  width: 100px;
  height: 46px;
  background-color: #dddddd;
}

#isMobile .add_tab_column .address_tab {
  background-color: #dddddd;
}

.add_tab_column .address_tab .tab_name {
  height: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: -0.9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 6px solid transparent;
}

#isMobile .add_tab_column .address_tab .tab_name {
  font-size: 15px;
  width: 100%;
  border: none;
}

.add_tab_column .address_tab .tab_name.active {
  border-bottom: 6px solid #056fb8;
}

#isMobile .add_tab_column .tab_name.active {
  background-color: #ffffff;
}

.add_address_column .btn_column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
}

.add_address_column .btn_column .add_address_btn {
  margin-bottom: 24px;
}

#isMobile .add_address_column .btn_column .add_address_btn {
  width: 80px;
}

#isMobile .add_info {
  font-size: 15px;
}

.add_address_column .add_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add_address_column .add_content .info {
  font-size: 18px;
  line-height: 2.67;
  letter-spacing: -0.45px;
  margin-top: 30px;
}

.add_content {
  width: 700px;
  margin: 0 auto;
}

#isMobile .add_content {
  width: 100vw;
  max-width: 450px;
  padding: 0 20px;
}

.add_content .top {
  width: 100%;
  margin-top: 52.8px;
  border-bottom: 1px solid #dddddd;
  padding-bottom: 34px;
}

#isMobile .add_content .top {
  margin-top: 25px;
}

.add_content h3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.5px;
  margin-bottom: 34px;
}

#isMobile .add_content h3 {
  font-size: 14px;
  margin-bottom: 18px;
}

.add_content .column {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

#isMobile .add_content .column {
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 0;
}

#isMobile #mypageAddress .add_content .column.save {
  margin-top: 24px;
  margin-bottom: 24px;
}

#isMobile .add_content .isMobile_column {
  flex-direction: row;
  align-items: center;
}

#isMobile .add_content .bottom .isMobile_column {
  margin-bottom: 16px;
}

.add_content .column.save {
  flex-direction: column;
  margin-top: 85px;
}

.add_content .column .input_name {
  width: 188px;
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .add_content .column .input_name {
  font-size: 13px;
  margin-bottom: 6px;
}

#isMobile .add_content .isMobile_column .input_name {
  margin-bottom: 0;
}

.add_content .column .warning {
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
  color: #dd1818;
}

#isMobile .add_content .column .warning {
  font-size: 12px;
  margin-bottom: 4px;
}

#isMobile .add_content .column.save .warning {
  margin: auto;
}

.add_content .top .column input {
  width: 400px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  padding: 0 26px;
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .add_content .top .column input {
  width: 100%;
  height: 40px;
  font-size: 15px;
  padding: 0 16px;
  border-radius: 4px;
}

#isMobile .add_content .top .column input.addr_code {
  width: 65%;
}

.add_content .column .input_column {
  display: flex;
  align-items: center;
}

#isMobile .add_content .column .input_column {
  width: 100%;
}

.add_content .own_radio_container {
  margin: 20px 0 35px;
}

#isMobile .add_content .own_radio_container {
  margin: 22px 0 30px;
}

.add_content .own_radio_column {
  display: flex;
}

.add_content .own_radio_column .left {
  margin-right: 28px;
}

#isMobile .radio_wrap {
  position: relative;
}

.add_content .own_radio_column span {
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .add_content .own_radio_column span {
  font-size: 14px;
}

.add_content .column__on-off {
  display: flex;
}

.alarm_btn {
  width: 100px;
  height: 36px;
  border-radius: 18px;
  border: solid 1px #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile .alarm_btn {
  width: 74px;
  height: 22px;
  font-size: 11px;
}

.alarm_btn img {
  margin-right: 6px;
}

#isMobile .alarm_btn img {
  width: 8px;
  margin-right: 4px;
}

.add_content .column__on-off {
  width: auto;
  margin-right: 16px;
}

.add_content .bottom {
  width: 100%;
  margin-top: 45px;
}

#isMobile .add_content .bottom {
  margin-top: 26px;
}

.add_content .bottom .calendar {
  background-color: #ffffff !important;
  background: url("../../images/calendar.png") no-repeat 255px 50%;
  cursor: pointer;
}

#isMobile #mypageAddress .add_content .bottom .calendar {
  background: url("../../images/calendar.png") no-repeat 95% 50%;
}

.add_content .bottom input {
  width: 300px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  font-size: 18px;
  letter-spacing: -0.45px;
  padding: 0 24px;
}

#isMobile .add_content .bottom input {
  width: 100%;
  height: 40px;
  font-size: 14px;
}

.add_content .check_btn .delete_icon {
  margin-right: 6px;
}

#isMobile .add_content .check_btn .delete_icon {
  width: 10px;
}

.add_content .bottom .notice_cycle {
  width: 300px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.45px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile .add_content .bottom .notice_cycle {
  font-size: 14px;
  width: 100%;
  height: 40px;
  margin-bottom: 16px;
}

#mypageAddress .save_btn_column {
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile #mypageAddress .save_btn_column {
  width: 100%;
}

.add_content .save_btn_column .close {
  margin-right: 20px;
}

.add_content .address_num_info {
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
  color: #666666;
}

.add_content .see_more {
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #056fb8;
  text-decoration: underline;
  margin-left: 8px;
  cursor: pointer;
}

#isMobile .add_content .see_more {
  font-size: 90%;
}

#mypageAddress .num_modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.6px;
}

#mypageAddress .num_modal .manage_num_list {
  width: 100%;
  background-color: #f1f1f1;
  padding: 18px 24px;
  margin: 18px 0 23px;
  line-height: 1.88;
}

.mypage_body .content.board_main h2 {
  border-bottom: none;
}

#isMobile .mypage_body .content.board_main h2 {
  border-bottom: 1px solid #eeeeee;
  width: 100%;
}

#isMobile .mypage_body .content.board_main h2.isMobile_inquiryBoard_h2 {
  border-bottom: none;
}

#isMobile .mypage_body .content.board_main h2.isMobile_detail_h2 {
  border: none;
}

#isMobile .mypage_body .content.board_main .detail_title_column h2 {
  border: none;
  font-size: 20px;
  padding: 0;
}

.mypage_body .content h2 .count {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.45px;
  color: #056fb8;
}

#isMobile .mypage_body .content h2 .count {
  margin-left: 4px;
}

.mypage_body .board_main .table_column {
  min-height: 0;
  margin-bottom: 55px;
}

/* 이벤트 페이지 사진 크기 임의 조정!!! */
/* .mypage_body .board_main .table_column img {
  width: 120px;
  height: 120px;
} */

.title_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile .title_column {
  align-items: flex-start;
  flex-direction: column;
}

#isMobile #myNotice .title_column {
  border-bottom: 1px solid #eeeeee;
}

.all_confirm {
  width: 140px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #1f7ec0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile .all_confirm {
  width: 94px;
  height: 34px;
  align-self: flex-end;
  font-size: 12px;
  margin: 12px 20px;
}

.all_confirm img {
  margin-left: 20px;
}

#isMobile .all_confirm img {
  margin-left: 4px;
  width: 14px;
}

.board_main table .confirm {
  letter-spacing: -0.4px;
  color: #999999;
}

#isMobile .board_main table .not_confirm {
  font-weight: bold;
}

.board_main table .confrim_notice td {
  color: #999999;
}

#myNotice .board_main table th:nth-child(3) {
  width: 50%;
  text-align: left;
}

.notice_modal {
  padding: 45px 35px 47px;
}

.notice_modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.6px;
}

.notice_modal .notice_desc {
  width: 100%;
  height: 200px;
  overflow-y: auto;
  line-height: 1.5;
  letter-spacing: -0.4px;
  color: #666666;
  margin: 20px 0;
}

/* 스크롤바 */
.notice_modal ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.notice_modal ::-webkit-scrollbar-track {
  background-color: #dddddd;
}
.notice_modal ::-webkit-scrollbar-thumb {
  border-radius: 23px;
  background: #999999;
}
.notice_modal ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

#isMobile #myNoticeSetting {
  padding: 0;
}

#myNoticeSetting .all_check {
  display: flex;
  align-items: center;
  padding: 38px 0;
  border-bottom: 1px solid #dddddd;
}

#isMobile #myNoticeSetting .myNoticeSetting_table {
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
  overflow-x: auto;
  overflow-y: hidden;
  margin: 24px 0;
}

#isMobile .myNoticeSetting_table tbody {
  display: flex;
}

#isMobile .myNoticeSetting_table th,
#isMobile .myNoticeSetting_table td {
  display: block;
}

#myNoticeSetting .input_name {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: -0.45px;
}

#isMobile #myNoticeSetting .input_name {
  font-size: 14px;
}

#myNoticeSetting .all_check .input_name {
  margin-right: 25px;
}

#myNoticeSetting table {
  width: 100%;
  margin-top: 43px;
}

#myNoticeSetting table th {
  font-weight: 500;
  letter-spacing: -0.4px;
  width: 16.66%;
  height: 46px;
}

#isMobile #myNoticeSetting table th {
  font-size: 14px;
  font-weight: normal;
  width: auto;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: left;
}

#myNoticeSetting table td {
  height: 80px;
}

#isMobile .isMobile_radio_wrapper {
  width: 100%;
  padding: 0 20px;
}

#isMobile #myNoticeSetting table td {
  height: 40px;
  display: flex;
}

#myNoticeSetting table .name {
  font-size: 18px;
  line-height: 1.75;
  letter-spacing: -0.45px;
}

#isMobile #myNoticeSetting table .name {
  font-size: 14px;
  width: 130px !important;
}

#myNoticeSetting table .column__on-off {
  width: fit-content;
  margin: auto;
}

#myNoticeSetting .warning {
  letter-spacing: -0.4px;
  color: #d90e0e;
}

#isMobile #myNoticeSetting .warning {
  font-size: 12px;
}

#isMobile #noticeBoard {
  padding: 0;
}

#noticeBoard .board_main table td:nth-child(3) {
  text-align: center;
}

#isMobile #noticeBoard .content h2 {
  font-size: 17px;
  height: 54px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  border-width: 1px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
}

#isMobile #noticeBoard .board_main table td:nth-child(3) {
  text-align: left;
}

#noticeBoard .board_main table th:nth-child(2),
#noticeBoard .board_main table td:nth-child(2) {
  text-align: left;
  width: 70%;
}

#isMobile #noticeBoard .board_main table th:nth-child(2),
#isMobile #noticeBoard .board_main table td:nth-child(2) {
  width: 100%;
}

#noticeBoard .board_main table td:nth-child(2) div {
  cursor: pointer;
}

#noticeBoard .search_reslt {
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: #666666;
  margin: 12px 0 17px;
}

#isMobile #noticeBoard .search_reslt,
#isMobile #frequentlyBoard .search_reslt {
  font-size: 13px;
  padding: 0 20px;
}

#noticeBoard .search_reslt strong {
  font-weight: 500;
  color: #000000;
}

.search_condition_column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search_condition_column select {
  width: 140px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  font-size: 16px;
  letter-spacing: -0.4px;
  color: #000000;
  background: url("../../images/dropdown.png") no-repeat 110px 50% !important;
  margin-right: 10px;
}

#isMobile .search_condition_column select.left {
  margin-right: 10px;
}

#isMobile .search_condition_column select {
  width: 50%;
  height: 40px;
  border-radius: 4px;
  margin-right: 0;
  background: url("../../images/dropdown.png") no-repeat 90% 50% !important;
  font-size: 13px;
  padding: 0 16px;
}

.isMobile_select_wrapper {
  display: flex;
}

#isMobile .isMobile_select_wrapper {
  width: 100%;
}

#isMobile .search_condition_column {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.search_condition_column .search_input {
  width: 400px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px 0 26px;
}

#isMobile .search_condition_column .search_input {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  margin-top: 10px;
  padding: 0 16px;
}

#isMobile .search_condition_column .search_input input {
  font-size: 13px;
}

#isMobile .search_condition_column .search_input img {
  width: 18px;
}

.search_condition_column .search_input input {
  width: 90%;
}

#noticeBoard .search_warning {
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
  text-align: center;
  color: #e40f0f;
  margin-top: 6px;
}

#isMobile #noticeBoard .search_warning,
#isMobile #frequentlyBoard .search_warning {
  font-size: 12px;
}

#isMobile .noticeBoard_table thead {
  display: none;
}

#isMobile .noticeBoard_table tr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px;
  border-bottom: 1px solid #eeeeee;
}

#isMobile .noticeBoard_table td {
  line-height: 1.5;
  border: none;
  width: 100%;
  text-align: left;
}

#isMobile .noticeBoard_table td:nth-child(2) {
  font-weight: bold;
  font-size: 15px;
}

.detail_title_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #eeeeee;
  border-bottom: 2px solid #f1f1f1 !important;
  padding: 24px 0;
}

#isMobile .detail_column {
  padding: 0 20px;
}

#isMobile .detail_title_column {
  border: none;
  flex-direction: column;
  align-items: flex-start;
}

.detail_title_column h2 {
  padding-bottom: 0 !important;
}

.detail_title_column .regdate {
  line-height: 1.75;
  letter-spacing: -0.4px;
  color: #666666;
}

#isMobile .detail_title_column .regdate {
  font-size: 14px;
  margin-top: 10px;
}

.detail_content_column {
  width: 100%;
  min-height: 440px;
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.45px;
  padding: 34px 0;
  border: none;
  border-bottom: 2px solid #f1f1f1;
}

#isMobile .detail_content_column {
  font-size: 13px;
  min-height: auto;
}

.detail_column .btn_column {
  display: flex;
  align-items: center;
}

#isMobile .detail_column .btn_column {
  font-size: 13px;
  justify-content: space-between;
  margin-top: 16px;
}

.detail_column .btn_column .prev {
  margin-right: 17px;
}

.detail_column .btn_column .next {
  margin-left: 17px;
}

.mypage_tab_menu {
  display: flex;
  justify-content: space-around;
  margin: 51px 0 19px;
}

#isMobile .mypage_tab_menu {
  padding: 20px 0;
  margin: 0;
  justify-content: space-around;
  border-bottom: 1px solid #eeeeee;
}

#agreement .mypage_tab_menu {
  margin-bottom: 34px;
}

#isMobile #agreement .mypage_tab_menu {
  margin-bottom: 0;
  border-bottom: none;
}

.mypage_tab_menu .tab {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: -0.45px;
  padding: 0 13px;
  cursor: pointer;
}

#agreement .mypage_tab_menu .tab {
  padding: 0;
}

#isMobile #agreement .mypage_tab_menu .tab {
  color: #999999;
  font-size: 15px;
}

#isMobile .mypage_tab_menu .tab:first-child {
  margin-left: 0;
  margin-right: 0;
}

.mypage_tab_menu .tab.active {
  border-bottom: 6px solid #056fb8;
}

#isMobile .mypage_tab_menu .tab.active {
  border-bottom: 3px solid #056fb8;
  padding: 0;
  color: #000000 !important;
}

#ongoing table th:last-child {
  width: 170px;
}

.mypage_tab_menu .tab span {
  font-size: 14px;
  letter-spacing: -0.35px;
}

.event_table table td {
  padding: 20px 0;
  line-height: 1;
}

.event_table table .period {
  font-size: 14px;
  letter-spacing: -0.35px;
}

.event_table table th:nth-child(3) {
  text-align: left;
}

.agreement_desc {
  font-size: 16px;
  line-height: 1.75;
  letter-spacing: -0.4px;
  border-top: 1px solid #eeeeee;
  padding: 35px 0;
  resize: none;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  max-height: 700px;
  height: 100%;
}

#isMobile .agreement_desc {
  font-size: 13px;
  border: none;
  padding: 0 20px;
  height: calc(100vh - 200px);
  overflow-x: hidden;
}

#mypageModify .profile_wrapper {
  width: 559px;
}

#isMobile #mypageModify .profile_wrapper {
  width: 100%;
}

#mypageModify .profile_column {
  width: 540px;
  padding-left: 0;
  padding-bottom: 36px;
  border-bottom: 1px solid #dddddd;
}

#isMobile #mypageModify .profile_column {
  width: 100%;
  border: none;
}

#isMobile .profile_column {
  padding: 0 20px;
  margin: 34px auto;
  align-items: center;
  flex-direction: column;
}

#isMobile #MypageMobile .profile_column {
  flex-direction: row;
}

#mypageModify .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 54px;
  height: auto;
}

#isMobile #mypageModify .profile {
  margin-right: 0;
  margin-bottom: 16px;
}

#mypageModify .profile img {
  margin-bottom: 17px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
}

#isMobile #mypageModify .profile img {
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

#isMobile #mypageModify .profile .file_name {
  font-size: 12px;
  text-align: center;
}

.profile_btn_column {
  display: flex;
  align-items: center;
}

.profile_btn_column .btn {
  width: 120px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #056fb8;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #056fb8;
}

#isMobile .profile_btn_column .btn {
  width: 86px;
  height: 40px;
  border-radius: 4px;
  font-size: 13px;
}

.profile_btn_column .add_picture {
  margin-right: 10px;
}

.profile_btn_column #add_picture {
  display: none;
}

.profile_btn_column img {
  margin-right: 7px;
}

#isMobile .profile_btn_column img {
  height: 11px;
}

form.modify_mypage {
  padding: 33px 0 42px;
}

#isMobile form.modify_mypage {
  padding: 0 0 30px;
}

form.mypage .column {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

#isMobile form.mypage .column {
  flex-direction: column;
  align-items: flex-start;
}

#isMobile form.mypage .isMobile_column_chk,
#isMobile form.mypage .isMobile_column_ad {
  margin-bottom: 0;
}

#isMobile form.mypage .column.isMobile_column {
  flex-direction: row;
  align-items: center;
}

#isMobile form.mypage .column.isMobile_column_ad .input_name {
  width: auto;
}

form.mypage .column.warning_column {
  margin-bottom: 0;
}

form.mypage .column .input_column {
  align-items: center;
}

#isMobile form.mypage .column .input_column {
  width: 100%;
}

form.mypage .column .input_name {
  width: 122px;
}

#isMobile form.mypage .column .input_name {
  width: 45px;
  font-size: 12px;
  margin-bottom: 10px;
}

#isMobile form.mypage .isMobile_column_pw .input_name {
  width: 70px;
}

#isMobile form.mypage .column.isMobile_column .input_name {
  margin-bottom: 0;
}

form.mypage .input_info {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
}

#isMobile form.mypage .input_info {
  font-size: 13px;
}

form.mypage input {
  width: 300px;
}

.certify_column {
  margin: 13px 0 9px;
}

#isMobile .certify_column {
  margin: 0 0 9px;
}

.tel_certify_column {
  display: flex;
  margin-right: 11px;
}

.tel_certify {
  width: 80px;
  height: 30px;
  background-color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile .tel_certify {
  font-size: 12px;
  width: 66px;
  height: 26px;
}

.tel_certify.active {
  background-color: #056fb8;
}

.tel_certify_column .ok {
  border-radius: 4px 0 0 4px;
}

.tel_certify_column .no {
  border-radius: 0 4px 4px 0;
}

.check_btn.change_password {
  margin-left: 0;
}

#mypageModify .own_radio_column {
  display: flex;
}

#mypageModify .own_radio_column .radio_wrap.left {
  margin-right: 28px;
}

#isMobile #mypageModify .own_radio_column .radio_wrap.left {
  margin-right: 14px;
}

#mypageModify .own_radio_column .radio_wrap,
#mypageModify .modify_mypage .checkbox_wrap {
  position: relative;
}

#mypageModify .own_radio_column .info {
  margin-left: 16px;
  color: #666666;
}

#isMobile #mypageModify .own_radio_column .info {
  padding-top: 2px;
}

#mypageModify .checkbox_coloumn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 434px;
}

#mypageModify .withdrawal_modal .checkbox_coloumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#isMobile #mypageModify .checkbox_coloumn {
  width: 90%;
}

#isMobile #mypageModify .withdrawal_modal .checkbox_coloumn {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

#mypageModify .checkbox_wrap:last-child {
  text-align: end;
}

.mypage_modify_info {
  width: 100%;
  border-radius: 6px;
  background-color: #f1f1f1;
  padding: 24px 26px;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: -0.35px;
  color: #666666;
}

#isMobile .mypage_modify_info {
  padding: 15px 10px;
  font-size: 12px;
  border-radius: 4px;
}

.withdrawal {
  margin: 47px auto 60px;
  background-color: #999999;
}

#isMobile .withdrawal {
  width: 140px;
  margin: 16px auto 0;
}

#isMobile #frequentlyBoard table tr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#isMobile #frequentlyBoard table td {
  width: 100%;
  text-align: left;
  padding: 0 20px !important;
  line-height: 1.8;
}

#isMobile #frequentlyBoard table td:first-child {
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px !important;
}

#isMobile #frequentlyBoard table td:nth-child(2) {
  padding-bottom: 16px !important;
  font-weight: bold;
}

#isMobile #frequentlyBoard table td:nth-child(2) .answer {
  font-weight: normal;
}

#isMobile #frequentlyBoard table td:nth-child(2) .answer_column img {
  width: 20px;
}

#isMobile #frequentlyBoard table td:nth-child(2) .answer_column {
  margin-bottom: 0;
}

#frequentlyBoard .answer_column {
  display: flex;
  align-items: flex-start;
  margin: 20px 0;
}

#frequentlyBoard .answer_column .answer {
  line-height: 1.5;
  letter-spacing: -0.4px;
  margin-left: 16px;
}

#frequentlyBoard .board_main table th:nth-child(1) {
  width: 15%;
}

#frequentlyBoard .board_main table td:nth-child(1) {
  vertical-align: baseline;
}

#frequentlyBoard .board_main table th:nth-child(2) {
  width: 72%;
}

#frequentlyBoard .board_main table td:nth-child(2) {
  text-align: left;
}

#frequentlyBoard .board_main table td {
  cursor: default;
  height: auto;
}

#frequentlyBoard .board_main table td:nth-child(3) {
  text-align: right;
  padding-right: 24px;
  vertical-align: baseline;
}

#frequentlyBoard .board_main table td:nth-child(3) img {
  cursor: pointer;
}

#frequentlyBoard .search_condition_column {
  margin-top: 17px;
}

#frequentlyBoard .search_warning {
  margin-top: 6px;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: -0.35px;
  text-align: center;
  color: #e40f0f;
}

#frequentlyBoard .search_reslt {
  margin-top: 10px;
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: #666666;
}

#frequentlyBoard .search_reslt strong {
  font-weight: 500;
  color: #000000;
}

#inquiryBoard .board_main table th:nth-child(2),
#inquiryBoard .board_main table td:nth-child(2) {
  text-align: left;
}

#inquiryBoard table .reply_title {
  font-weight: normal !important;
}

#isMobile #inquiryBoard table .reply_title {
  font-weight: bold !important;
  margin-top: 16px;
}

.new_icon {
  width: 32px;
  height: 18px;
  border-radius: 4px;
  background-color: #ea0303;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -0.25px;
  color: #ffffff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

#isMobile .new_icon {
  width: 26px;
  height: 16px;
}

#inquiryBoard .board_btn_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid #eeeeee;
  padding: 22px 0 14px;
}

#isMobile #inquiryBoard .board_btn_column {
  padding: 20px;
  border-top: none;
  border-bottom: 1px solid #eeeeee;
}

.info_btn_column {
  line-height: 1.75;
  letter-spacing: -0.4px;
}

#isMobile .info_btn_column {
  width: 65%;
  font-size: 13px;
}

.info_btn_column strong {
  font-weight: bold;
}

.inquiry_btn {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  background-color: #0d6fb8;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.88;
  letter-spacing: -0.4px;
  color: #ffffff;
}

#isMobile .inquiry_btn {
  width: 80px;
  height: 33px;
  font-size: 13px;
}

#inquiryBoard table td:nth-child(2) div {
  width: fit-content;
  cursor: pointer;
}

#isMobile #inquiryBoard table td:nth-child(2) .check_reply {
  width: 26px;
}

.inquiry_write .write_column {
  padding-top: 35px;
}

.inquiry_write .write_column form input {
  width: 100%;
}

.inquiry_write .write_column form .write_content {
  align-self: baseline;
  margin-top: 10px;
}

.inquiry_write .write_column form .btn_column {
  margin-bottom: 0;
}

.inquiry_write form .warning.last {
  text-align: center;
  margin-top: 14px;
}

#inquiryBoard .write_column {
  border-top: 2px solid #eeeeee;
}

#isMobile #inquiryBoard .write_column {
  border-top: 1px solid #eeeeee;
}

#inquiryBoard .write_column form:first-child {
  padding-top: 0;
}

#inquiryBoard form.reply_form {
  background-color: #f1f1f1;
}

#isMobile #inquiryBoard form.reply_form {
  padding: 20px;
}

#inquiryBoard form {
  padding: 36px 38px 54px;
}

#inquiryBoard form textarea {
  height: 200px;
}

#inquiryBoard .write_column form .column_name {
  min-width: 114px;
  width: 114px;
}

#isMobile #inquiryBoard .write_column form .column_name {
  min-width: 45px;
  width: 45px;
}

#isMobile #inquiryBoard .btn_column {
  padding: 0 20px;
  margin-bottom: 24px;
}

#inquiryBoard .write_column .blue_btn {
  margin: 28px auto 0;
}

#inquiryBoard .btn_column.modify {
  display: flex;
  align-items: center;
  justify-content: center;
}

#inquiryBoard .btn_column.modify .blue_btn {
  margin: 0 10px;
}

#inquiryBoard .write_column {
  width: auto;
}

#isMobile #inquiryBoard table tr {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid #eeeeee;
  padding: 16px 0;
}

#isMobile #inquiryBoard table thead tr {
  padding: 0;
}

#isMobile #inquiryBoard table tr.have_reply {
  border: none;
  padding-bottom: 0;
}

#isMobile #inquiryBoard table td {
  width: 100%;
  text-align: left;
  padding: 0 20px !important;
  border: none;
  line-height: 1.5;
}

#isMobile #inquiryBoard table td:nth-child(2) {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.my_notice_info {
  display: flex;
  margin-top: 16px;
  font-size: 14px;
}

.my_notice_info .bar {
  width: 1px;
  height: 14px;
  margin: 0 6px;
  background-color: #000000;
}

#isMobile #myArticle,
#isMobile #myNotice,
#isMobile #EventBoard,
#isMobile #agreement,
#isMobile #frequentlyBoard,
#isMobile #inquiryBoard,
#isMobile #InquiryWrite {
  padding: 0;
}

#isMobile #EventBoard .isMobile_tr td:nth-child(1) {
  width: 110px;
}

#isMobile #EventBoard .isMobile_tr td:nth-child(2) {
  padding: 16px 0 !important;
}

#isMobile #EventBoard .isMobile_tr .event_img {
  width: 66px;
  height: 66px;
  margin: 0 16px;
}

#isMobile #EventBoard .isMobile_tr div {
  text-align: left;
  line-height: 1.5;
}

#isMobile #EventBoard .isMobile_tr .title {
  font-weight: bold;
}

#isMobile #EventBoard .isMobile_tr .period {
  font-size: 12px;
}

#isMobile .isMobile_inquiryBoard_h2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile .isMobile_inquiryBoard_h2 img {
  width: 14px;
}

table.addr_alarm {
  width: 100%;
}

table.addr_alarm th {
  vertical-align: middle;
  padding: 10px 6px !important;
}

table.addr_alarm .column__on-off {
  justify-content: center;
  margin-right: 0;
}

#isMobile table.addr_alarm th {
  font-size: 12px;
}

#isMobile table.addr_alarm .label__on-off {
  width: 50px;
}

#isMobile table.addr_alarm .label__on-off .on,
#isMobile table.addr_alarm .label__on-off .off {
  font-size: 10px;
}

#isMobile table.addr_alarm .label__on-off .off {
  padding-left: 25px;
}

#isMobile table.addr_alarm .input__on-off:checked + .label__on-off .marble {
  left: 29px;
}
