.admin_login {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  height: 100vh;
}

.admin_login h1 {
  font-size: 24px;
  font-weight: 500;
  margin-top: 12px;
}

.admin_container {
  min-height: calc(100vh - 80px);
  display: flex;
}

ul.side_container {
  background-color: #f1f1f1;
  width: 200px;
  min-width: 170px;
}

ul.side_container li.side_tab {
  cursor: pointer;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 18px 0 28px;
  font-size: 18px;
  letter-spacing: normal;
}

ul.side_container li.side_tab.active {
  background-color: #1876bb;
  color: #ffffff;
}

.main_container {
  width: 100%;
  min-height: 100%;
  height: auto;
  padding: 30px 60px;
  min-width: 1361px;
  overflow-x: auto;
}

.admin_container .admin_login input {
  width: 400px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  padding: 0 20px;
  font-size: 18px;
}

.admin_login input.user_id {
  margin: 50px 0 12px;
}

.admin_login .checkbox_wrap {
  width: 400px;
  margin: 8px 0 40px;
}

.admin_login .login_btn {
  width: 400px;
  height: 50px;
  border-radius: 10px;
  background-color: #0d6fb8;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin_basic_modal {
  width: 300px;
  padding: 20px 18px 32px;
}

.admin_basic_modal img.close {
  margin-left: auto;
}

.admin_basic_modal .modal_info {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: -0.45px;
  text-align: center;
  margin-bottom: 30px;
}

.admin_basic_modal .modal_info.title {
  margin-bottom: 16px;
}

.admin_basic_modal .modal_desc {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.45px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

.admin_basic_modal .modal_list_column {
  background-color: #f1f1f1;
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  max-height: 100px;
  line-height: 1.5;
  overflow-y: auto;
  text-align: center;
}

.admin_basic_modal.modal_400 {
  width: 400px;
}

.confirm_btn {
  width: 100px;
  height: 40px;
  border-radius: 6px;
  background-color: #0d6fb8;
  font-weight: bold;
  letter-spacing: -0.4px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.confirm_btn.close_btn {
  background-color: #cccccc;
}

.detail_user_container {
  height: auto;
  padding-bottom: 50px;
}

.detail_user_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #eeeeee;
  margin-bottom: 16px;
  padding-bottom: 8px;
}

#setMain .detail_user_header:not(:first-child) {
  margin-top: 16px;
}

#setMain .detail_user_header_column:not(:first-child) {
  margin-top: 16px;
}

#addressDetail h2.main_container_name {
  margin-bottom: 0;
}

h2.main_container_name {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
  margin-bottom: 16px;
}

h2.main_container_name .total_column {
  font-size: 16px;
  color: #999999;
  margin-left: 16px;
}

.user_search_column {
  width: 100%;
  padding: 30px 43px;
  background-color: #f5f5f5;
}

.search_column {
  padding: 5px 0px;
}

.admin_container input[type="date"],
.admin_container input[type="text"] {
  height: 40px;
  padding: 0 22px;
  border-radius: 4px;
  background-color: #ffffff;
  border: none;
  font-size: 16px;
  letter-spacing: -0.4px;
}

#adminLogin.admin_login input {
  width: 400px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-size: 18px;
}

.admin_container input[type="date"] {
  width: 180px;
}

.admin_container .add_event input[type="date"] {
  width: 200px;
}

.admin_container .input_title,
.admin_container .range_text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
}

.admin_container .input_title {
  margin-right: 26px;
}

.admin_container .range_text {
  margin: 0 10px;
}

.user_search_column .first_column {
  display: flex;
}

.user_search_column .first_column .search_regdate_column {
  margin-right: 40px;
}

.user_search_column .first_column .search_keyword_column input {
  width: 600px;
}

.user_search_column .second_column {
  margin: 16px 0 42px;
  display: flex;
  align-items: center;
}

#address .user_search_column .second_column.top,
#boardConsult .user_search_column .second_column.top {
  margin: 16px 0 16px;
}

.user_search_column .second_column .bar {
  width: 1px;
  height: 16px;
  margin: 0 25px;
  background-color: #cccccc;
}

.user_search_column .second_column label {
  margin-left: 6px;
  margin-right: 30px;
}

#address .user_search_column .second_column label {
  display: inline-block;
}

.admin_btn_column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail_user.admin_btn_column {
  margin-top: 80px;
}

#addressDetail .admin_btn_column {
  margin-bottom: 50px;
}

.admin_white_btn {
  width: 140px;
  height: 40px;
  border-radius: 6px;
  border: solid 1px #1876bb;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #1876bb;
  cursor: pointer;
}

.admin_blue_btn {
  width: 140px;
  height: 40px;
  border-radius: 6px;
  background-color: #1876bb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: -0.4px;
  color: #ffffff;
}

.verstdate_btn {
  width: 100px;
  margin-left: 10px;
}

.admin_btn_column .left {
  margin-right: 10px;
}

.user_list_column {
  width: 100%;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-top: 20px;
  padding: 35px 50px 33px;
}

.user_list_column .title_column {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user_list_column h3 {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-right: 16px;
}

.user_list_column .search_result {
  color: #999999;
}

.user_list_column table {
  width: 100%;
  margin: 12px 0 24px;
}

.user_list_column table th {
  font-weight: 500;
  background-color: #f5f5f5;
  border-top: 2px solid #dddddd;
  border-bottom: 2px solid #dddddd;
}

.user_list_column table th,
.user_list_column table td {
  font-size: 14px;
  letter-spacing: -0.35px;
  height: 44px;
  vertical-align: middle;
  text-align: center;
}

.user_list_column table td {
  border-bottom: 1px solid #dddddd;
}

.user_list_column table td.stop {
  color: #df1313;
}

.admin_pagenation {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin_pagenation input[type="text"] {
  width: 50px;
  height: 36px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  padding: 0;
}

.admin_pagenation .current_page {
  margin: 0 28px;
}

.admin_pagenation .page_prev {
  margin-left: 15px;
}

.admin_pagenation .page_next {
  margin-right: 15px;
}

ul.detail_user_tab {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
}

#setProvision ul.detail_user_tab li {
  width: auto;
  padding: 0 16px;
}

ul.detail_user_tab li {
  width: 200px;
  height: 60px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  letter-spacing: -0.5px;
  color: #999999;
  border-top: 4px solid transparent;
  cursor: pointer;
}

ul.detail_user_tab li.active {
  background-color: #ffffff;
  border-top: 4px solid #1876bb;
  font-weight: 500;
  color: #000000;
}

.detail_table {
  width: 100%;
}

.detail_table_title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
  margin: 40px 0 10px;
}

#userAdminDetail .detail_table_title.access,
#userAdminAdd .detail_table_title.access {
  display: flex;
  align-items: center;
}

.detail_table_title .info {
  font-weight: normal;
  font-size: 14px;
  margin-left: 16px;
}

#userAdminDetail .detail_table_title .info,
#userAdminAdd .detail_table_title .info {
  display: inline-block;
}

.detail_table th {
  width: 180px;
  height: 58px;
  background-color: #f5f5f5;
  vertical-align: middle;
  border-bottom: 2px solid #ffffff;
  font-weight: 500;
}

.detail_table td {
  width: 38%;
}

#setPush .detail_table td,
#setPush .detail_table th {
  width: auto;
}

#addressDetail .detail_table td,
#boardConsultDivision .detail_table td {
  width: auto;
}

.detail_table th.tel_num {
  height: 88px;
}

.detail_table td {
  vertical-align: middle;
  padding: 0 24px;
}

.detail_table .free_user_radio label {
  margin-right: 30px;
  margin-left: 7px;
}

.detail_table .img_column img {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

#setMain .detail_table .img_column img {
  width: 100%;
  height: 100%;
  margin: 0;
}

.detail_table .img_column .img_name {
  font-size: 14px;
  letter-spacing: -0.35px;
  margin: 8px 0 18px;
}

#setMain .detail_table .img_column .img_name {
  margin-top: 8px;
}

.admin_black_btn_column {
  display: flex;
}

.admin_black_btn {
  width: 60px;
  height: 30px;
  border-radius: 4px;
  border: solid 1px #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  letter-spacing: -0.35px;
}

.admin_black_btn.left {
  margin-right: 10px;
}

.user_control_column {
  display: flex;
  align-items: center;
}

.admin_delete_btn {
  width: 100px;
  height: 40px;
  border-radius: 4px;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  letter-spacing: -0.35px;
  color: #ffffff;
}

.admin_delete_btn.left {
  margin-right: 10px;
}

.admin_delete_btn.close_btn {
  background-color: #cccccc;
}

.detail_table input[type="text"] {
  width: 400px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
}

.detail_table input[type="password"] {
  width: 300px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  padding: 0 22px;
  margin-right: 10px;
}

.detail_table input[type="text"].has_btn {
  width: 290px;
  margin-right: 10px;
}

.detail_table td.nickName .nickName_column,
.detail_table td .password_column {
  display: flex;
  align-items: center;
}

.detail_table td.nickName .admin_white_btn {
  width: 100px;
}

.admin_input_info {
  font-size: 14px;
  letter-spacing: -0.35px;
  margin-top: 4px;
}

.detail_table .change_password .admin_white_btn {
  width: 160px;
}

.detail_table .day_change_count {
  display: flex;
  align-items: center;
}

.detail_table .admin_white_btn80 {
  width: 80px;
  margin-left: 38px;
}

.detail_table .admin_white_btn100 {
  width: 100px;
  margin-left: 10px;
}

input.add_address_limit {
  width: 60px !important;
  margin-right: 4px;
}

.detail_table.marketing_table th {
  height: 118px;
  line-height: 1.25;
}

.detail_table .free_user_chk label {
  margin-right: 27px;
  margin-left: 7px;
}

.admin_basic_modal textarea {
  width: 210px;
  height: 100px;
  border-radius: 4px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-bottom: 19px;
}

.admin_basic_modal .admin_delete_btn {
  width: 100px;
  height: 40px;
}

#userFreeAddress {
  display: flex;
  min-height: calc(100vh - 320px);
}

#userFreeAddress .warning{
  padding-left: 10px;
  padding-bottom: 10px;
  color: red;
}

#userFreeAddress .address_lists {
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #f5f5f5;
  padding-bottom: 16px;
  max-height: 100vh;
  overflow-y: auto;
}

#userFreeAddress .address_lists ul {
  width: 100%;
}

#userFreeAddress .address_list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid #eeeeee;
  padding: 0 16px;
  cursor: pointer;
}

#userFreeAddress .address_list.active div {
  color: #1876bb;
}

#userFreeAddress .address_list .name {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.5px;
}

#userFreeAddress .address_list .date {
  font-size: 14px;
  letter-spacing: -0.35px;
}

#userFreeAddress .address_detail {
  width: 800px;
  padding: 16px 24px;
}

#userFreeAddress .address_detail table {
  width: fit-content;
}

#userFreeAddress .address_detail table .column_name,
#addressDetail .address_detail table .column_name {
  width: 100px;
  border-right: 2px solid #ffffff;
}

#userFreeAddress .address_detail table .sub_th {
  width: 100px;
}

#userFreeAddress .address_detail table input[type="date"] {
  border: 1px solid #dddddd;
}

#userFreeAddress .detail_table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

#addressDetail .detail_table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 10px;
  width: 696px;
}

#addressDetail .category_column .detail_table_header {
  width: 695px;
}

#addressDetail .detail_table_header .detail_table_title {
  margin: 0;
}

#addressDetail .detail_table_header .admin_black_btn {
  width: 100px;
}

#userFreeAddress .detail_table_header label {
  margin-left: 8px;
  margin-right: 24px;
}

#userFreeAddress table .refine_address {
  height: 88px;
}

#userFreeAddress table .refine_address_desc,
#addressDetail table .refine_address_desc {
  width: 400px;
  height: 72px;
  border: 1px solid #dddddd;
  padding: 8px 16px;
}

.calendar_data,
#addressDetail .calendar_data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 270px;
  height: 40px;
  border: solid 1px #dddddd;
  border-radius: 4px;
  padding: 0 22px;
}
.user_free_notice_tab {
  display: flex;
}

.user_free_notice_tab li {
  width: 200px;
  height: 50px;
  border: 1px solid #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  color: #999999;
  cursor: pointer;
}

.user_free_notice_tab li.active {
  background: #eff8fe;
  border-bottom: 2px solid #1876bb;
  color: #000000;
}

#userFreeNoticeView .notice_title {
  text-align: left;
}

#userFreeNoticeView .confirmed td,
#userFreeNoticeView .confirmed span {
  color: #999999;
}

#userFreeNoticeView td span {
  cursor: pointer;
}

#userFreeNoticeView th:nth-child(1) {
  width: 5%;
}

#userFreeNoticeView th:nth-child(2) {
  width: 10%;
}

#userFreeNoticeView th:nth-child(3) {
  width: 15%;
}

#userFreeNoticeView th:nth-child(5),
#userFreeNoticeView th:nth-child(6) {
  width: 10%;
}

.admin_notice_modal .modal_info {
  width: 100%;
  text-align: left;
  margin-bottom: 0 !important;
}

/* 관리주소 상세보기 > 리파인 api 결과 창 확인 시 */
/* AddressDetail.js */
.api_result_btn {
  width: 100px;
  height: 40px;
  margin-top: 50px;
}
.modal_inner_ct .modal_title {
  width: 90%;
  margin: 15px 0 10px;
  padding-bottom: 15px;
  font-size: 2em;
  text-align: left;
  border-bottom: 2px solid darkgrey;
  border-width: 50%;
}
.modal_inner_ct .modal_content {
  width: 90%;
  max-height: 600px;
  margin-bottom: 50 !important;
  overflow: auto;
  text-align: left;
  font-size: 1.4em;
}
.modal_inner_ct .address_modal_btn {
  margin: 50px 0 30px;
}

.admin_notice_modal .detail_column {
  background-color: #f1f1f1;
  text-align: left;
  padding: 0px 8px;
  margin-top: 16px;
}

.admin_notice_modal .detail_column span {
  font-size: 14px;
  display: inline-block;
}

.admin_notice_modal .modal_desc {
  font-size: 16px;
  margin-bottom: 24px;
  max-height: 200px;
  overflow-y: auto;
  text-align: left;
}

#userFreeNoticeView.user_list_column {
  margin-top: 0;
}

#userFreeNoticeSet .all_check {
  display: flex;
  align-items: center;
  padding: 24px 16px;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-color: #dddddd;
  width: 60%;
}

#userFreeNoticeSet .input_name {
  margin-right: 30px;
  font-weight: 500;
}

#userFreeNoticeSet table {
  width: 60%;
  margin-top: 24px;
}

#userFreeNoticeSet table .name {
  width: 20%;
  padding: 24px 16px;
}

#userFreeNoticeSet th {
  font-weight: 500;
}

#userFreeNoticeSet th:not(:first-child) {
  width: 15%;
}

#userFreeNoticeSet .column__on-off {
  width: fit-content;
  margin: 15px auto;
}

.detail_table td .ip_column {
  color: #d51a1a;
}

.detail_table td.board_access label {
  margin-left: 7px;
}

.detail_table .warning {
  font-size: 14px;
  color: #d51a1a;
}

#userLawyerAdd .detail_user_container {
  padding-bottom: 0;
}

.admin_basic_modal .lawyer_img {
  width: 160px;
  height: 200px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
}

.admin_basic_modal .lawyer_img_info {
  display: flex;
  width: 70%;
  font-size: 14px;
  margin: 8px 0;
}

.admin_basic_modal .lawyer_img_info .sign {
  margin-right: 6px;
}

.sample_img {
  width: auto;
  min-width: 160px;
  max-width: 300px;
  height: 200px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#address .second_column .register_address,
#boardConsult .second_column .register_address {
  width: 410px;
}

#address .second_column .address_checkbox{
  height: 100px;
  padding-top: 10px;
}

.list_counter_column {
  width: fit-content;
  border: solid 1px #dddddd;
  display: flex;
  margin: 16px auto 0;
}

.list_counter_column .list_counter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 80px;
}

.list_counter_column .list_counter.all {
  background-color: #f5f5f5;
}

.list_counter_column .list_counter:not(:last-child) {
  border-right: solid 1px #dddddd;
}

.list_counter_column .list_counter h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.5px;
}

.list_counter_column .list_counter strong {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  margin-right: 4px;
}

.list_counter_column .list_counter span {
  font-size: 14px;
  line-height: 1.5;
}

#addressDetail .serial_number {
  width: 290px;
}

.approval_column {
  display: flex;
  align-items: center;
}

.admin_container select {
  height: 40px;
  width: 180px;
  padding: 0 22px;
  border-radius: 4px;
  background-color: #ffffff !important;
  border: none;
  font-size: 16px;
  letter-spacing: -0.4px;
  background: url("../images/dropdown.png") no-repeat 90% 50%; /* 화살표 모양의 이미지 */
}

.admin_container .board_table select {
  border: solid 1px #dddddd;
}

.board_table .board_title {
  border: solid 1px #dddddd;
  height: 40px;
  border-radius: 4px;
  padding: 0 22px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

#boardConsultDetail .board_table .board_content textarea {
  margin-bottom: 20px;
}

.board_table .board_content textarea,
.board_table .board_content .textarea {
  width: 100%;
  height: 300px;
  padding: 10px 22px;
  font-size: 16px;
  line-height: 1.5;
  border-radius: 4px;
  background-color: #ffffff !important;
  border: solid 1px #dddddd;
}

.detail_table.reply_table {
  background-color: #f1f1f1;
  margin-top: 16px;
  padding-bottom: 16px;
}

.detail_table.reply_table th {
  background-color: #f1f1f1;
  border-bottom: 2px solid #f1f1f1;
}

.board_table .board_content td {
  padding-bottom: 16px;
  padding-top: 8px;
}

.admin_table_bar {
  width: 100%;
  margin-top: 40px;
  border-bottom: 2px solid #eeeeee;
}

.thumbnail {
  width: 100px;
  height: 100px;
  background-color: #eeeeee;
  margin: 6px auto;
}

.property_board_list th:not(:nth-child(6)) {
  width: 9%;
}

.select_column {
  display: flex;
  align-items: center;
}

.select_column .select_name {
  font-size: 18px;
  font-weight: bold;
}

.select_column select {
  border: solid 1px #dddddd;
  margin: 0px 16px;
}

.single_column td {
  width: auto;
}

.single_column .img_column {
  display: flex;
  align-items: flex-end;
}

#setMain .single_column .img_column {
  align-items: flex-start;
}

#setMain .single_column .img_column .banner_img_column {
  border: solid 1px #dddddd;
  background-color: #ffffff;
  width: 250px;
  height: 150px;
  margin-right: 16px;
}

#setMain .single_column .img_column .banner_img_file {
  display: none;
}

.single_column input.title {
  width: 600px;
}

.event_period_column {
  display: flex;
  align-items: center;
}

.event_period_column .calendar_data {
  border: solid 1px #dddddd !important;
}

.event_period_column span {
  margin: 0 10px;
}

.single_column.reply_column {
  background-color: #f5f5f5;
  margin: 16px 0 32px;
}

.single_column.reply_column th {
  border-bottom: none;
}

#setPush .th_taskname {
  width: 20% !important;
}

#setPush .th_checkbox {
  width: 10% !important;
}

#setPush .detail_table input[type="checkbox"] {
  margin: auto;
  display: block;
}

#setPush .detail_table td {
  padding: 8px 0;
}

#setPush .detail_table textarea {
  width: 100%;
  height: 60px;
  padding: 4px 6px;
  font-size: 16px;
}

#setPush .detail_table tr td:first-child {
  font-weight: 500;
}

#setPush .detail_table .info {
  font-size: 14px;
  font-weight: normal;
}

.provision_content {
  width: 100%;
  height: 500px;
  padding: 24px;
  font-size: 16px;
}

.provision_save {
  margin: 24px auto;
}

.version_table input {
  width: 100px !important;
}

#setVersion .admin_table_bar {
  margin-top: 16px;
}

.alarm_wrapper {
  width: 60%;
}

.alarm_wrapper .column__on-off {
  margin-left: 16px;
}

.wrapper_apiResult {
  background-color: #999999;
  width: 80%;
  height: 80%;
  margin: 0 auto;
  position: fixed;
  top: 50%;
}

.free_user_chk ul li {
  margin : 15px 0px 15px 0px;
}

.excelDown {
  color: #fff;
  background-color: green;
  width: 120px;
  height: 30px;
  border-radius: 5px;
  margin-left: 25px;
}