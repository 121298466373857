#login .join_title {
  margin-bottom: 2px;
}

#isMobile #login {
  justify-content: center;
}

#login h2 {
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.45px;
  color: #666666;
}

#isMobile #login h2 {
  font-size: 15px;
  margin-right: auto;
}

#login .login_form {
  margin-top: 38px;
}

#isMobile #login .login_form {
  width: 100%;
  margin-top: 16px;
}

#login .login_form .column.top {
  margin-bottom: 20px;
}

#login .auto_login {
  position: relative;
  margin-top: 12px;
  margin-bottom: 12px;
}

#isMobile #login .auto_login {
  margin-top: 8px;
  margin-bottom: 8px;
}

#login .auto_login label {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.35px;
}

#login .login_form .login_btn {
  width: 400px;
  height: 50px;
  border-radius: 10px;
  background-color: #0d6fb8;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: -0.45px;
  color: #ffffff;
}

#isMobile #login .login_form .login_btn {
  width: 100%;
  height: 40px;
  border-radius: 6px;
  font-size: 15px;
}

#login .horizontal_line {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

#isMobile #login .horizontal_line {
  width: 100%;
  margin-top: 20px;
}

#login .horizontal_line .hr {
  width: 160px;
  border-bottom: 2px solid #f1f1f1;
}

#isMobile #login .horizontal_line span {
  font-size: 14px;
}

#login .horizontal_line span {
  display: inline-block;
  line-height: 1.75;
  letter-spacing: -0.4px;
  margin: 0 30px;
}

#login .sns_login {
  display: flex;
  margin-top: 51px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 400px;
  justify-content: space-evenly;
}

#isMobile #login .sns_login {
  margin-top: 20px;
  margin-bottom: 15px;
}

#login .sns_login .column {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

#login .sns_login .column.left {
  margin-right: 81px;
}

#isMobile #login .sns_login .column.left {
  margin-right: 50px;
}

#login .sns_login .column span {
  letter-spacing: -0.4px;
}

#isMobile #login .sns_login .column span {
  font-size: 14px;
}

#login .sub_links {
  display: flex;
  line-height: 1.75;
  letter-spacing: -0.4px;
  text-align: center;
  margin-bottom: 104px;
}

#login .sub_links div {
  cursor: pointer;
}

#isMobile #login .sub_links {
  font-size: 14px;
  margin-bottom: 0;
}

.isMobile {
  display: none;
}

#isMobile .isMobile {
  display: block;
  text-align: left;
}

#appleid-signin {
  display: none;
}

.sns_login_title {
  margin-top: 10px;
}
