#Description {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Description .description_header {
  width: 62.4%;
}

.desc_main_container img {
  width: 100%;
}

.desc_sub_container img {
  width: 100%;
}

.desc_btn_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desc_btn_container img {
  width: 100%;
}

.link_container_area {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link_container {
  width: 62.4%;
  display: flex;
}

.link_container img {
  width: 100%;
}

#isMobile .link_container_area,
#isMobile .desc_btn_container {
  display: block;
  padding: 0 20px;
}