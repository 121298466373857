#header {
  width: 100%;
  height: 80px;
  background: #ffffff;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile #header,
#isMobileHome #header {
  min-height: 53px;
  border-bottom: none;
  padding: 0 12px;
}

#header .container {
  width: 62.4%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile #header .container,
#isMobileHome #header .container {
  width: 100vw;
  max-width: 450px;
}

#header .main_logo {
  width: 60px;
  height: 38px;
}

#isMobile #header .main_logo,
#isMobileHome #header .main_logo {
  width: 42px;
}

#header .wraaper {
  display: flex;
  align-items: center;
}

#header .hello_user {
  font-size: 16px;
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: #000000;
  margin-right: 12px;
}

#header .notice_column {
  position: relative;
  margin: 0 40px 0 30px;
}

#header .notice_count {
  position: absolute;
  top: -4px;
  right: -18px;
  width: 27px;
  height: 18px;
  border-radius: 9px;
  background-color: #000000;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -0.3px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#header .wraaper .login_btn {
  width: 90px;
  height: 36px;
  border-radius: 18px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.2;
  letter-spacing: -0.4px;
  margin-right: 26px;
}

#isMobile #header .wraaper .login_btn,
#isMobileHome #header .wraaper .login_btn {
  width: 73px;
  height: 30px;
  font-size: 14px;
  margin-right: 13px;
}

#header .wraaper .join_btn {
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.4px;
  color: #0d6fb8;
}

#isMobile #header .wraaper .join_btn {
  font-size: 14px;
}

#isMobileHome #header .isMobile_set_icon,
#isMobile #header .isMobile_set_icon {
  display: none;
}

#isMobileHome .isMobile_notice_icon,
#isMobile .isMobile_notice_icon {
  width: 16px;
}

#isMobileHome .isMobile_profile_icon,
#isMobile .isMobile_profile_icon {
  width: 33.3px;
}

#isMobileHome #header .notice_count,
#isMobile #header .notice_count {
  width: 20px;
  height: 13px;
  top: -2px;
  right: -14px;
  font-size: 10px;
}

#isMobileHome #header .notice_column,
#isMobile #header .notice_column {
  margin: 0 15px 0 12px;
}

#isMobileHome #header .hello_user,
#isMobile #header .hello_user {
  display: none;
}
