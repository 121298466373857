.sub_banner_accordion {
  width: 100%;
}

.sub_banner_accordion_item_header {
  border-bottom: 1px solid #cacaca;
}

.sub_banner_accordion_item_body img {
  width: 100%;
}

.sub_banner_accordion_item_body {
  display: none;
}

.sub_banner_accordion_item_body.active {
  display: block;
  padding: 30px 0;
  border-bottom: 1px solid #cacaca;
}

#home .sub_banner_row {
  display: flex;
  flex-direction: row;
}

#home .sub_banner_column_1 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

#home .sub_banner_column_1 img {
  width: 100%;
}

#home .sub_banner_column_2 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

#home .sub_banner_column_2 img {
  width: 100%;
}