.modal_bg {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
}

.modal_ct {
  display: none;
  position: fixed;
  height: auto;
  top: 180px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 101;
  border-radius: 20px;
  box-shadow: 5px 8.7px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}

#isMobile .modal_ct {
  width: 90vw !important;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 400px;
}

.modal_inner_ct {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.openModal.modal_bg {
  display: flex;
  animation: modal-bg-show 0.3s;
}

.openModal.modal_ct {
  display: flex;
  animation: modal-show 0.3s;
}

.center_modal.modal_ct {
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.age_limit {
  padding: 46px 46px 40px 43px;
}

.age_limit h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.6px;
}

#isMobile .age_limit h2 {
  font-size: 18px;
}

.age_limit .modal_info {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.45px;
  letter-spacing: -0.6px;
  margin-top: 18px;
  margin-bottom: 33px;
}

#isMobile .age_limit .modal_info {
  font-size: 16px;
  margin-top: 6px;
  margin-bottom: 24px;
}

.wide_modal {
  padding: 20px 0 20px;
}

.wide_modal .close {
  margin-left: auto;
  margin-right: 28px;
}

#isMobile .wide_modal .close {
  width: 14px;
}

#isMobile .wide_modal.change_modal .close.blue_btn,
#isMobile .wide_modal.withdrawal_modal .close.blue_btn {
  width: 100px;
}

#isMobile .wide_modal.withdrawal_modal .close.blue_btn {
  margin: 0;
}

.wide_modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
  margin-top: 15px;
  margin-bottom: 28px;
}

#isMobile .wide_modal h2 {
  font-size: 18px;
}

.wide_modal .article_column {
  max-height: 380px;
  padding: 0 36px;
  width: 100%;
}

#isMobile .wide_modal .article_column {
  padding: 0 20px;
}

.wide_modal .article_column textarea {
  font-size: 16px;
  width: 100%;
  height: 380px;
  max-height: 380px;
  border: none;
}

.wide_modal ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.wide_modal ::-webkit-scrollbar-track {
  background-color: transparent;
}
.wide_modal ::-webkit-scrollbar-thumb {
  border-radius: 23px;
  background: #878787;
}
.wide_modal ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

.wide_modal .article_title {
  width: 100%;
  line-height: 1.5;
  letter-spacing: -0.4px;
  text-align: left;
  font-weight: 500;
  color: #000000;
}

#isMobile .wide_modal .article_title {
  font-size: 15px;
}

.wide_modal .article_info {
  line-height: 1.5;
  letter-spacing: -0.4px;
  text-align: left;
  color: #666666;
}

.wide_modal .article_info:not(:last-child) {
  margin-bottom: 16px;
}

#isMobile .wide_modal .article_info:not(:last-child) {
  font-size: 15px;
  word-break: normal;
}

#isMobile #mypageAddress {
  padding: 0;
}

#joinAdd .modal_400,
#mypageAddress .modal_400 {
  padding: 35px;
}

#isMobile #mypageAddress .modal_400 {
  padding: 35px !important;
}

#isMobile #joinAdd .modal_400 {
  padding: 35px 24px !important;
}

#joinAdd .modal_400 h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.6px;
  text-align: center;
}

#isMobile #joinAdd .modal_400 h2 {
  font-size: 17px;
  padding: 0 30px;
  max-width: 300px;
}

#joinAdd .modal_400 .modal_info {
  margin-top: 12px;
}

#isMobile #joinAdd .modal_400 .modal_info {
  font-size: 15px;
  text-align: center;
}

#joinAdd .modal_400 .modal_warning {
  line-height: 1.67;
  font-size: 14px;
  letter-spacing: -0.35px;
  text-align: center;
  margin-top: 16px;
}

#joinAdd .modal_400 .blue_btn {
  margin-top: 28px;
}

.register_address .search_address_column {
  width: 500px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-right: 24px;
}

#isMobile .register_address .search_address_column {
  width: 90%;
  height: 40px;
  border-radius: 4px;
  margin-bottom: 15px;
  padding-right: 17px;
}

#isMobile .register_address .search_address_column img {
  width: 17px;
}

.register_address .input_search_address {
  width: -webkit-fill-available;
  padding: 0 26px;
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .register_address .input_search_address {
  width: 100%;
  padding: 0 16px;
  font-size: 14px;
}

.register_address .map {
  margin-bottom: 28px;
}

#isMobile .register_address .map {
  margin-bottom: 16px;
}

.register_address .map .map_img {
  min-width: 500px;
  max-width: 600px;
  width: 40vw;
}

#isMobile .register_address .map img {
  min-width: 100%;
  max-width: 100%;
  width: 100%;
}

.register_address .address_result_column {
  width: 100%;
  padding: 0 49px;
}

#isMobile .register_address .address_result_column {
  width: 90%;
  padding: 0;
}

.register_address .address_result_column .main_address {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.45px;
  text-align: left;
}

#isMobile .register_address .address_result_column .main_address,
#isMobile .register_address .address_result_column .zip_code {
  font-size: 15px;
}

.register_address .address_result_column .zip_code {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.45px;
  text-align: left;
  color: #999999;
}

.register_address .address_result_column .detail_address {
  font-size: 18px;
  letter-spacing: -0.45px;
  width: 100%;
  height: 50px;
  padding: 0 26px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  margin: 16px 0;
}

#isMobile .register_address .address_result_column .detail_address {
  font-size: 14px;
  line-height: 1;
  letter-spacing: -0.45px;
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border-radius: 4px;
}

.exit_modal .btn_column {
  margin: 0 !important;
}

/* 갤럭시 S8에서는 다음 버튼이 안보여서 추가 */
.provision_bottom_div {
  margin-bottom: 20px;
}
#joinAdd .exit_modal .cancel {
  margin-top: 28px;
}

.notice_alram .notice_list {
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  line-height: 2.78;
  letter-spacing: -0.45px;
}

#isMobile .notice_alram .notice_list {
  width: 100%;
  height: 40px;
  font-size: 15px;
}

#isMobile .notice_alram .notice_list {
  width: 100%;
  max-width: 300px;
  height: 40px;
  font-size: 15px;
}

.notice_alram .notice_list.active {
  border-radius: 6px;
  border: solid 1px #056fb8;
}

#isMobile .notice_alram .notice_list.active {
  border-radius: 4px;
}

.modal_400 h2.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
}

#isMobile .modal_400 h2.title {
  font-size: 18px;
}

#joinAdd .blue_btn.close {
  background-color: #056fb8 !important;
}

.blue_btn.close {
  background-color: #999999 !important;
}

.is_login_modal {
  padding: 51px;
}

#isMobile .is_login_modal {
  padding: 24px;
}

.is_login_modal .btn_column {
  display: flex;
  align-items: center;
  margin-top: 47px;
}

#isMobile .is_login_modal .btn_column {
  margin-top: 20px;
}

.is_login_modal .blue_btn.close {
  margin-right: 20px;
}

#mypageAddress .exit_modal .blue_btn.close {
  margin-right: 20px;
}

#mypageAddress .exit_modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.6px;
}

#isMobile #mypageAddress .exit_modal h2 {
  font-size: 130%;
  text-align: center;
  margin-bottom: 10px;
}

#mypageAddress .exit_modal .info {
  line-height: 1.88;
  letter-spacing: -0.4px;
}

#isMobile #mypageAddress .exit_modal .info {
  font-size: 14px;
}

#mypageAddress .exit_modal .warning {
  line-height: 1.88;
  letter-spacing: -0.4px;
  color: #d90d0d;
  margin-bottom: 16px;
}

#isMobile #mypageAddress .exit_modal .warning {
  font-size: 12px;
}

#mypageAddress .exit_modal .blue_btn {
  width: 140px;
}

#mypageAddress .exit_modal .error_info {
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: -0.45px;
  margin-bottom: 33px;
}

.recommend_modal {
  padding: 24px 28px 50px;
}

.recommend_modal .close {
  align-self: flex-end;
}

.recommend_modal h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
}

#isMobile .recommend_modal h2 {
  font-size: 18px;
}

.recommend_modal .grid_column {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 37px 0 36px;
  row-gap: 28px;
}

#isMobile .recommend_modal .grid_column {
  row-gap: 20px;
}

.recommend_modal .grid_icon {
  text-align: center;
  width: fit-content;
  margin: auto;
  cursor: pointer;
}

#isMobile .recommend_modal .grid_icon_name {
  font-size: 12px;
}

.recommend_modal .grid_icon img {
  margin-bottom: 6px;
}

#isMobile .recommend_modal .grid_icon img {
  width: 40px;
}

.change_modal .save_btn_column {
  display: flex;
  margin: 40px 0 20px;
}

.change_modal form {
  width: 100%;
  padding: 0 65px;
}

#isMobile .change_modal form {
  padding: 0 20px;
}

.change_modal form .column {
  display: flex;
  align-items: center;
}

#isMobile .change_modal form .column {
  flex-direction: column;
  align-items: flex-start;
}

.change_modal form .column.warning_column {
  margin-bottom: 0;
}

.change_modal form .input_name {
  width: 169px;
  font-size: 18px;
  letter-spacing: -0.45px;
}

#isMobile .change_modal form .input_name {
  font-size: 14px;
  margin-bottom: 6px;
}

.change_modal form input {
  width: 300px;
}

.change_modal form .info {
  font-size: 12px;
  letter-spacing: -0.3px;
  color: #666666;
}

.agree_ad {
  padding: 33px 0 37px;
}

.agree_ad h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
}

#isMobile .agree_ad h2 {
  font-size: 20px;
}

.agree_ad .info_column {
  margin: 12px 0 20px;
  text-align: center;
}

.agree_ad .info {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
  color: #000000;
}

#isMobile .agree_ad .info {
  font-size: 16px;
}

.agree_ad .date {
  line-height: 1.5;
  color: #666666;
}

#isMobile .agree_ad .date {
  font-size: 14px;
}

.withdrawal_modal form {
  padding: 0 53px;
}

#isMobile .withdrawal_modal form {
  padding: 0 20px;
}

.withdrawal_modal .question h2 {
  margin: 0;
}

#isMobile .withdrawal_modal .question h2 {
  font-size: 14px;
}

.withdrawal_modal .question_info {
  line-height: 1.5;
  letter-spacing: -0.4px;
}

#isMobile .withdrawal_modal .question_info {
  font-size: 13px;
}

#isMobile .withdrawal_modal .question_info.bottom {
  font-size: 10px;
}

.withdrawal_modal .question_info strong {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
}

#isMobile .withdrawal_modal .question_info strong {
  font-size: 14px;
}

.withdrawal_modal .checkbox_coloumn {
  margin: 40px 0 33px;
}

#isMobile .withdrawal_modal .checkbox_coloumn {
  margin: 24px 0 24px;
}

.withdrawal_modal .checkbox_wrap {
  margin-bottom: 20px;
}

#isMobile .withdrawal_modal .checkbox_wrap {
  margin-bottom: 10px;
}

.withdrawal_reason {
  width: 100%;
  padding: 4px;
}

.withdrawal_modal .withdrawal_btn_column {
  display: flex;
  margin-top: 59px;
}

#isMobile .withdrawal_modal .withdrawal_btn_column {
  margin-top: 24px;
}

.withdrawal_modal .withdrawal_btn_column .red_btn {
  margin-right: 20px;
}

.modal_400 .modal_title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: -0.6px;
}

#isMobile .modal_400 .modal_title {
  font-size: 17px;
}

.modal_400.withdrawal_modal {
  padding: 55px 0 51px;
}

.withdrawal_modal .withdrawal_btn_column.really {
  margin-top: 47px;
}

.withdrawal_modal .withdrawal_btn_column.really .blue_btn {
  margin-right: 20px;
}

.withdrawal_modal .withdrawal_btn_column.really .red_btn {
  margin-right: 0;
}

/* 주소 API 전용*/
.pop-address-search {min-width:100%; min-height:400px}
.pop-address-search .pop-address-search-inner {width:100%;position:relative; text-align:center; background-color:transparent; background-repeat:no-repeat; background-position:100% 100%}
.searchContentBox{min-height: 480px;}
.pop-address-search .pop-address-search-inner .search-wrap{width:100%;display:block; position:relative; border-bottom:2px solid #e9e9e9; margin: 0 auto; padding: 15px 0;}
.pop-address-search .pop-address-search-inner .wrap {display:block; width:90%; position:relative; box-sizing:border-box; border:2px solid #186bb9; margin:0 auto; padding:0 39px 0 0;background-color: #fff}
.pop-address-search .pop-address-search-inner .wrap input[type="text"] {width:100%; padding-left:10px; border:o none; line-height:40px; font-size: 14px; font-weight: normal; ime-mode:active;}						
.pop-address-search .pop-address-search-inner .wrap input[type="button"] {position:absolute; right:0; top:10px; width:39px; height:20px; background: url(https://www.juso.go.kr/juso_support_center/img_addrlink/btn-search.png) 50% 50% no-repeat}	
.pop-address-search .pop-address-search-inner .list-roadAddr{font-size:13px;}
.pop-address-search .pop-address-search-inner .list-jibunAddr{font-size:11px; color:#186bb9;}
.pop-address-search .pop-address-search-inner .result {position:relative; margin:20px 20px 5px 20px;}
.pop-address-search .pop-address-search-inner .result table{line-height:1.57em; font-size:12px;}
.pop-address-search .pop-address-search-inner .result table tbody tr td {line-height:1.57em}
.pop-address-search .pop-address-search-inner .result table thead tr th,
.pop-address-search .pop-address-search-inner .result table tbody tr td {height:40px; padding:1px 0}
.pop-address-search .pop-address-search-inner .result table tbody tr:nth-child(odd) td {background:#f9f9f9}
.pop-address-search .pop-address-search-inner .result table tbody tr.nodata td {background:#fff}
.pop-address-search .pop-address-search-inner .detail {margin:20px}
.pop-address-search .pop-address-search-inner .detail table{line-height:1.57em; font-size:12px;}
.pop-address-search .pop-address-search-inner .detail select{ text-indent: 6px; box-sizing: border-box; height: 30px; width:75px; color: #3f3f3f; line-height: 15px; font-size: 12px; border-radius: 2px; border: 1px solid #ddd; padding: 0 15px; background: #fff url(https://www.juso.go.kr/juso_support_center/img_addrlink/arrow_select.png) no-repeat 95% 50%;}
.pop-address-search .pop-address-search-inner .detail input[type="text"]{ text-indent: 6px; box-sizing: border-box; width:100%; height: 30px; color: #3f3f3f; line-height: 15px; font-size: 12px; border-radius: 2px; border: 1px solid #ddd; padding: 0 15px; background: #fff;}
.pop-address-search .pop-address-search-inner .detail .titleTd{background-color:#e9e9e9; height:30px; width:120px; vertical-align: middle;}
.pop-address-search .pop-address-search-inner .detail .btn-area{width:100%; margin-top:20px; align-items: center;}