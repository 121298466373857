#JoinUser .join_title {
  margin-bottom: 38px;
}

.join_user .join_title {
  margin-bottom: 27px;
}

#isMobile .join_user .join_title {
  margin-bottom: 0;
  margin-top: 24px;
}

#isMobile #JoinUser.join_user .join_title {
  margin-bottom: 22px;
}

#isMobile .login_form {
  width: 100%;
  max-width: 450px;
}

.join_user .sns_login .column h2 {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
  text-align: center;
}

#isMobile .join_user .sns_login .column h2 {
  font-size: 16px;
}

.join_user .sns_login {
  margin-bottom: 44px;
}

.join_user .checkbox_wrap.main input[type="checkbox"] + label {
  font-size: 18px;
  letter-spacing: -0.45px;
}

.join_user .checkbox_wrap input[type="checkbox"] + label:before,
.join_user .checkbox_wrap input[type="checkbox"]:checked + label:after {
  top: 2px;
}

.join_user .provision {
  margin-top: 14px;
  width: 510px;
  padding: 16px 24px 22px 20px;
  background-color: #f1f1f1;
}

#isMobile .join_user .provision {
  width: auto;
}

.join_user .provision .column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.join_user .provision .column img {
  cursor: pointer;
}

.join_user .provision .column:not(:last-child) {
  margin-bottom: 17px;
}

.join_user .checkbox_wrap input[type="checkbox"] + label {
  letter-spacing: -0.4px;
}

.join_user .checkbox_wrap.sub input[type="checkbox"] + label:before,
.join_user .checkbox_wrap.sub input[type="checkbox"]:checked + label:after {
  top: 2px;
}

.join_user .btn_column {
  text-align: center;
  margin-top: 26px;
  margin-bottom: 124px;
}

#isMobile .join_user .btn_column {
  margin-bottom: 0;
}

#joinAdd .sub_title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: -0.6px;
  text-align: center;
  margin-top: 24px;
}

#joinAdd form {
  max-width: 500px;
  width: 100%;
}

#joinAdd form input {
  width: 100%;
}

#isMobile #joinAdd .sub_title,
#isMobile #joinAdd .main_title_info {
  font-size: 12px;
  justify-content: flex-start;
  text-align: left;
  height: auto;
  font-weight: normal;
  margin-top: 4px;
}

#joinAdd .main_title_info {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.45px;
  margin-bottom: 47px;
}

#joinAdd .input_name {
  font-size: 18px;
  letter-spacing: -0.45px;
  margin-bottom: 8px;
}

#isMobile #joinAdd .input_name {
  font-size: 13px;
  margin-bottom: 0;
}

#isMobile #joinAdd .isMobile.input_name {
  margin-bottom: 6px;
}

form .check_btn.adree {
  margin-left: 0;
  width: 100%;
  margin-bottom: 20px;
}

#isMobile form .check_btn.adree {
  width: 130px;
  height: 40px;
  font-size: 13px;
  border-radius: 4px;
  margin-bottom: 0;
}

#joinAdd .help {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.75;
  letter-spacing: -0.35px;
  color: #0d6fb8;
  cursor: pointer;
}

#isMobile #joinAdd .help {
  font-size: 13px;
}

#joinAdd .own_radio_container {
  margin-top: 12px;
}

#joinAdd .own_radio_container .input_name {
  margin-bottom: 15px;
}

#joinAdd .own_radio_container .own_radio_column {
  display: flex;
  margin-bottom: 6px;
}

#joinAdd .own_radio_column {
  margin-bottom: 6px;
}

#joinAdd .radio_wrap.left {
  margin-right: 28px;
}

#joinAdd .add_rental_contract {
  border-radius: 4px;
  background-color: #f1f1f1;
  padding: 28px 40px 14px 38px;
  margin-top: 18px;
}

#isMobile #joinAdd .add_rental_contract {
  padding: 30px 24px 4px;
}

#joinAdd .add_rental_contract h3 {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
}

#isMobile #joinAdd .add_rental_contract h3 {
  font-size: 18px;
}

#joinAdd .add_rental_contract .title_info {
  font-size: 16px;
  line-height: 1.88;
  letter-spacing: -0.4px;
  color: #666666;
  margin-bottom: 25px;
}

#isMobile #joinAdd .add_rental_contract .title_info {
  font-size: 14px;
}

#joinAdd .add_rental_contract .sub_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

#isMobile #joinAdd .add_rental_contract .sub_column {
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

#isMobile #joinAdd .add_rental_contract .isMobile.sub_column {
  flex-direction: row;
  align-items: flex-start;
}

#joinAdd .add_rental_contract .sub_column input {
  width: 300px;
}

#isMobile #joinAdd .add_rental_contract .sub_column input {
  width: 100%;
}

#joinAdd .add_rental_contract .sub_column input.calendar {
  background-color: #ffffff !important;
  background: url("../../images/calendar.png") no-repeat 255px 50%;
  cursor: pointer;
}

#isMobile #joinAdd .add_rental_contract .sub_column input.calendar {
  background: url("../../images/calendar.png") no-repeat 95% 50%;
  background-size: 18px;
}

#joinAdd .add_rental_contract .sub_column .notice_cycle {
  width: 300px;
  height: 50px;
  border-radius: 6px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.45px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile #joinAdd .add_rental_contract .sub_column .notice_cycle {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 16px;
}

#joinAdd .add_rental_contract .sub_column .sub_name {
  font-size: 18px;
  letter-spacing: -0.45px;
  text-align: left;
}

#isMobile #joinAdd .add_rental_contract .sub_column .sub_name {
  font-size: 13px;
  margin-bottom: 6px;
}

#isMobile #joinAdd .add_rental_contract .isMobile.sub_column .sub_name {
  margin-bottom: 0;
  margin-right: 24px;
}

#joinAdd .warning_column {
  display: flex;
  align-items: flex-start;
  margin-top: 21px;
}

#joinAdd .warning_column .warning_icon {
  margin-right: 6px;
  margin-top: 6px;
}

#isMobile #joinAdd .warning_column .warning_icon {
  margin-right: 4px;
  margin-top: 4px;
  width: 14px;
}

#joinAdd .warning_column .warning_message {
  font-size: 14px;
  line-height: 1.75;
  letter-spacing: -0.35px;
}

#isMobile #joinAdd .warning_column .warning_message {
  font-size: 12px;
}

#joinAdd .btn_column {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 94px;
}

#isMobile #joinAdd .btn_column {
  margin-top: 24px;
  padding: 0 24px;
}

#joinAdd .btn_column .btn {
  width: 160px;
  height: 50px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  letter-spacing: -0.9px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile #joinAdd .btn_column .btn {
  width: 50%;
  height: 40px;
  border-radius: 6px;
  font-size: 15px;
}

#isMobile .exit_modal .btn_column .btn {
  width: 100px !important;
}

#joinAdd .btn_column .cancel {
  background-color: #999999 !important;
}

#joinAdd .btn_column .left {
  margin-right: 20px;
}

#joinAdd .btn_column .register {
  background-color: #0d6fb8 !important;
}

#isMobile #joinFinish .main_title,
#isMobile #joinAuth .main_title,
#isMobile #findId .main_title,
#isMobile #dormantUser .main_title {
  margin-top: 10vw;
  margin-bottom: 26px;
}

#isMobile #findPw .main_title {
  margin-top: 10vw;
}

#joinFinish .title_info,
#joinAuth .title_info {
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -0.6px;
  text-align: center;
}

#isMobile #joinFinish .title_info,
#isMobile #joinAuth .title_info {
  font-size: 16px;
  text-align: left;
  width: 100%;
}

#joinFinish .blue_btn,
#joinAuth .blue_btn {
  margin: auto;
  margin-top: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobile #joinFinish,
#isMobile #joinAuth,
#isMobile #joinAuth,
#isMobile #findPw,
#isMobile #dormantUser {
  display: block;
}

#joinFinish a {
  width: 100%;
}

#isMobile .isMobile_search_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.email_input {
  margin-top: 24px;
  padding: 0 8px;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.2rem;
  border: solid 1px #dddddd;
  width: 200px;
  height: 50px;
  border-radius: 6px;
}

.email_info {
  text-align: center;
  margin-top: 24px;
}

.email_info {
  text-align: center;
  margin-top: 24px;
}

#isMobile .email_info {
  text-align: left;
  margin-top: 24px;
  display: block;
  font-size: 12px;
}

.login_form .sns_join_name_cloumn {
  margin-bottom: 5px;
}

.joinPath .own_radio_column .radio_wrap{
  display: block;
  margin-bottom: 15px;
}

.joinPath .own_radio_column .left{
  width:150px;
}

.joinPath .own_radio_column ul li {
  display: flex;
}