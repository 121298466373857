.search_wrapper {
  width: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
}

.search.section_1 {
  width: 62.4%;
  display: flex;
  padding: 45px 0;
}

#isMobile .search.section_1 {
  width: 100%;
  padding: 20px;
  padding-bottom: 0 !important;
}

#isMobileHome .search.section_1 {
  width: 100%;
  flex-direction: column;
  padding: 20px 20px;
  max-width: 450px;
}

#isMobile #searchResult.center_main {
  padding: 0 0 60px;
}

#searchResult .search.section_1 {
  justify-content: center;
  padding-bottom: 19px;
}

.search .search_column {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
  height: 70px;
  border-radius: 35px;
  border: solid 4px #056fb8;
  padding-right: 34px;
}

#isMobile .search .search_column {
  height: 40px;
  width: 100%;
  padding-right: 10px;
}

#isMobileHome .search .search_column {
  width: 100%;
  height: 40px;
  border-radius: 35px;
  border: solid 2px #056fb8;
  padding-right: 14px;
}

.search .search_column input {
  width: 90%;
  font-size: 20px;
  letter-spacing: -0.5px;
  padding: 0 40px;
}

#isMobileHome .search .search_column input,
#isMobile .search .search_column input {
  font-size: 14px;
  padding: 0 14px;
}

#home {
  background-color: #fff;
  overflow-x: hidden;
}

#home .search_word_column {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.9px;
  margin-left: 26px;
}

#isMobileHome #home .search_word_column {
  font-size: 13px;
  margin-left: 16px;
  margin-top: 4px;
  display: flex;
}

#isMobileHome #home .search_word_column .search_word_desc {
  font-size: 12px;
}

#home .search_word_title {
  font-weight: bold;
}

#isMobileHome #home .search_word_title {
  margin-right: 8px;
}

#home .search_word_desc {
  word-break: keep-all;
}

#home .section_2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobileHome #home .section_2 {
  width: 100vw;
}

#home .slide_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobileHome #home .slide_container {
  width: 100%;
  padding: 0;
}

#home .slide_prev {
  margin-right: 86px;
  cursor: pointer;
}

#isMobileHome #home .slide_prev,
#isMobileHome #home .slide_next {
  display: none;
}

#home .slide_next {
  margin-left: 86px;
  cursor: pointer;
}

#home .slide_column {
  width: 100%;
  height: 100%;
  padding-top: 78px;
  position: relative;
}

#isMobileHome #home .slide_column {
  padding-top: 27px;
  max-width: 450px;
  margin: auto;
}

#home .slide_column h3 {
  font-family: "NanumSquare";
  font-weight: 400;
  font-size: 24px;
  line-height: 1.42;
  letter-spacing: -1.2px;
  color: #ffffff;
}

#isMobileHome #home .slide_column h3 {
  font-size: 14px;
}

#home .slide_column h2 {
  font-family: "NanumSquare";
  font-weight: 800;
  font-size: 40px;
  line-height: 0.85;
  letter-spacing: -2px;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 33px;
}

#isMobileHome #home .slide_column h2 {
  font-size: 16px;
  margin-top: 4px;
  margin-bottom: 24px;
}

#home .go_btn_column {
  display: flex;
  align-items: center;
}

#isMobileHome #home .go_btn_column {
  flex-direction: column;
  align-items: flex-start;
}

#home .go_btn_column .go_btn_info {
  line-height: 1.75;
  letter-spacing: -0.4px;
  color: #ffffff;
  margin-left: 14px;
}

#isMobileHome #home .go_btn_column .go_btn_info {
  font-size: 10px;
  margin-left: 14px;
}

#home .page_column {
  position: absolute;
  bottom: 28px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  height: 30px;
  padding: 0 16px;
  border-radius: 15px;
  background-color: #286fa8;
}

#isMobileHome #home .page_column {
  position: absolute;
  bottom: 16px;
  left: 100%;
  transform: translate(-100%, 0);
  width: 60px;
  height: 20px;
  padding: 0 6px;
}

#isMobileHome #home .event_column .page_column {
  left: 95%;
  transform: translate(-95%, 0);
}

#home .page_column_move img {
  cursor: pointer;
}

#isMobileHome #home .page_column_move img {
  height: 8px;
}

#home .page_column_num {
  font-size: 14px;
  letter-spacing: -0.35px;
  color: #ffffff;
}

#isMobileHome #home .page_column_num {
  font-size: 8px;
  margin-left: 4px;
}

#home .slide_img_1 {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-100%, -50%);
}

#isMobileHome #home .slide_img_1 {
  width: 130px;
}

#home .section_3 {
  width: 62.4%;
  padding: 56px 0 0 0;
}

#isMobileHome #home .section_3 {
  width: 100%;
  padding: 18px 0 37px 0;
  max-width: 450px;
}

#home .section_3 .service_title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
  margin-bottom: 14px;
}

#isMobileHome #home .section_3 .service_title {
  font-size: 30px;
  padding: 0 20px;
}

#home .section_3 .service_column h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
  margin-top: 56px;
  margin-bottom: 14px;
}

#home .section_4 {
  width: 62.4%;
  padding: 56px 0 0 0;
}

#isMobileHome #home .section_4 {
  width: 100%;
  padding: 18px 0 37px 0;
  max-width: 450px;
}

#home .section_4 .service_title {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
  margin-bottom: 14px;
}

#isMobileHome #home .section_4 .service_title {
  font-size: 30px;
  padding: 0 20px;
}

#home .section_4 .service_column h2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -0.6px;
  margin-top: 56px;
  margin-bottom: 14px;
}

#home .service_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#isMobileHome #home .service_container {
  flex-direction: column;
  padding: 0 20px;
}

#home .service_column {
  width: 380px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-self: normal;
}

#home .service_column2 {
  width: 33.33333333%;
  flex: 0 0 auto;
}

#home .service_column2 img {
  width: 100%;
}

#home .service_column3 {
  width: 50%;
  flex: 0 0 auto; 
}

#home .service_column3 iframe {
  width: 100%;
  height: 370px;
}

#home .column-50 {
  flex: 0 0 auto;
  width: 50%;
}
#home .column-25 {
  flex: 0 0 auto;
  width: 25%;
}

#home .column-25 img {
  width: 100%;
}

#home .column-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

#isMobileHome #home .isMobile_service_column a {
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: space-around;
}

#isMobileHome #home .isMobile_service_column2 {
  width: 100%;
}

#isMobileHome #home .isMobile_service_column2 img {
  width: 100%;
}

#home .service_column:not(:last-child) {
  margin-right: 16px;
}

#isMobileHome #home .service_column:not(:last-child) {
  margin-bottom: 9px;
}

#home .service_icon {
  margin-top: 48px;
  margin-bottom: 20px;
  height: 73px;
}

#isMobileHome #home .service_icon {
  margin: 0;
  height: auto;
}

#isMobileHome #home .service_icon img {
  width: 40px;
}

#home .service_column h3 {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: -0.65px;
  margin: 22px 0;
  text-align: center;
}

#isMobileHome #home .service_column h3 {
  font-size: 16px;
  margin: 0;
  margin-bottom: 4px;
}

#home .service_column .service_desc {
  font-size: 18px;
  line-height: 1.4;
  letter-spacing: -0.45px;
  text-align: center;
}

#isMobileHome #home .service_column .service_desc {
  font-size: 13px;
  text-align: left;
}

#home .service_column .go_btn,
#home .notice_column .go_btn,
.recent_notice .go_btn {
  width: 140px;
  height: 40px;
  padding: 0 16px 0 24px;
  border-radius: 20px;
  border: solid 1px #dddddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 23px;
  margin-bottom: 39px;
}

#isMobileHome #home .service_column .go_btn,
#isMobileHome .recent_notice .go_btn {
  width: auto;
  border: none;
  margin: 0;
}

#isMobileHome #home .service_column .go_btn span,
#isMobileHome #home .notice_column .go_btn span,
#isMobileHome .recent_notice .go_btn span {
  display: none;
}

#home .event_column {
  width: 100%;
  height: 240px;
  background: url("../../images/event_bg.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 40px;
  padding: 40px 70px 42px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

#isMobileHome #home .event_column {
  height: 120px;
  padding: 18px 20px 10px;
  margin-top: 20px;
}

#home .event_column h3 {
  font-family: "NanumSquare";
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: -1.2px;
  color: #ffffff;
}

#isMobileHome #home .event_column h3 {
  font-size: 12px;
}

#home .event_column h2 {
  font-family: "NanumSquare";
  font-size: 40px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: -2px;
  color: #ffffff;
}

#isMobileHome #home .event_column h2 {
  font-size: 16px;
}

#home .event_column .go_btn {
  background-color: #ffffff;
  color: #000000;
}

#home .event_column .page_column {
  bottom: 18px;
  background-color: #0e0b08;
}

#home .notice_column {
  margin-top: 49px;
}

#home .notice_left_wrapper {
  display: flex;
  justify-content: space-between;
}

#isMobileHome #home .notice_column {
  padding: 0 20px;
  margin-top: 21px;
}

#home .notice_column .go_btn {
  margin: 0;
}

#isMobileHome #home .notice_column .go_btn {
  margin: 0;
  width: 80px;
  height: 26px;
  font-size: 12px;
  padding: 0 8px 0 10px;
}

#isMobileHome #home .notice_column .go_btn img {
  width: 6px;
}

#home .notice_column .notice_title {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.8;
  letter-spacing: -0.5px;
  color: #999999;
}

#isMobileHome #home .notice_column .notice_title {
  font-size: 14px;
}

#home .notice_column .notice_title img {
  margin-right: 6px;
}

#isMobileHome #home .notice_column .notice_title img {
  width: 18px;
}

#home .notice_info {
  font-size: 18px;
  line-height: 2;
  letter-spacing: -0.45px;
  margin-top: 7px;
}

#home .notice_info span {
  color: #797979;
}

#home .notice_info .btn-pointer {
  cursor: pointer;
}

#isMobileHome #home .notice_info {
  font-size: 14px;
  line-height: 1;
}

#isMobileHome #home .notice_info span {
  display: block;
  color: #797979;
}

.condition_column {
  min-width: 200px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #056fb8;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  position: relative;
  cursor: pointer;
}

#isMobile .condition_column {
  min-width: 86px;
  height: 40px;
}

.condition_column.show {
  border-bottom-left-radius: 0;
}

.condition_column .select {
  font-size: 20px;
  line-height: 3;
  letter-spacing: -0.5px;
  color: #ffffff;
  width: 100%;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#isMobile .condition_column .select {
  font-size: 13px;
  padding-left: 4px;
  padding: 0 0 0 10px;
}

.condition_column .condition_lists {
  position: absolute;
  background-color: #056fb8;
  top: 70px;
  left: 0;
  width: 200px;
  display: none;
}

#isMobile .condition_column .condition_lists {
  width: 86px;
  top: 40px;
  padding-left: 10px;
}

.condition_column .condition_lists.show {
  display: block;
}

.condition_column .condition_lists li {
  font-size: 20px;
  line-height: 2.5;
  letter-spacing: -0.5px;
  color: #ffffff;
  padding-left: 32px;
}

#isMobile .condition_column .condition_lists li {
  font-size: 14px;
  padding-left: 4px;
}

.condition_column .condition_lists li:hover {
  background-color: #0b5a90;
}

.condition_column img {
  margin-left: 26px;
}

#isMobile .condition_column img {
  margin-left: 6px;
  width: 12px;
}

#searchResult .search .search_column {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

#isMobile #searchResult .search .search_column img {
  width: 18px;
}

#searchResult .search_word_column {
  display: flex;
  align-items: center;
}

#searchResult .search_word_title {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.9px;
  font-weight: bold;
}

#isMobile #searchResult .search_word_title,
#isMobile #searchResult .search_word_desc {
  font-size: 11px;
}

#searchResult .search_word_desc {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.9px;
}

#searchResult .search_word_column .bar {
  font-size: 18px;
  line-height: 1.67;
  letter-spacing: -0.9px;
  font-weight: bold;
  color: #999999;
}

.tab_column {
  width: 100%;
  height: 80px;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  margin: 39px 0 43px;
}

#isMobile .tab_column {
  border: none;
  margin-top: 24px;
  margin: 24px 0 30px;
}

.tab_column ul {
  width: 62.4%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

#isMobile .tab_column ul {
  width: 100%;
  flex-wrap: wrap;
}

.tab_column ul li {
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 6px solid transparent;
  font-size: 20px;
  line-height: 1.75;
  letter-spacing: -1px;
  color: #999999;
  cursor: pointer;
}

#isMobile .tab_column ul li {
  font-size: 14px;
  width: 33.33vw;
  max-width: 150px;
  height: 50%;
  justify-content: center;
  border: 1px solid #ffffff;
  background-color: #eeeeee;
}

.tab_column ul li.active {
  border-bottom: 6px solid #056fb8;
  color: #056fb8;
}

#isMobile .tab_column ul li.active {
  background-color: #0d6fb8;
  color: #ffffff;
}

.search_result_column {
  width: 62.4%;
}

#isMobile .search_result_column {
  width: 100%;
  padding: 0 20px;
}

.total_search_coumn {
  font-size: 18px;
  letter-spacing: -0.45px;
  color: #000000;
}

#isMobile .total_search_coumn {
  font-size: 13px;
}

.total_search_coumn strong {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.6px;
}

#isMobile .total_search_coumn strong {
  font-size: 16px;
}

.sub_result_column h2 {
  font-size: 24px;
  line-height: 1.75;
  letter-spacing: -0.6px;
  display: flex;
}

#isMobile .sub_result_column h2 {
  font-size: 13px;
}

.sub_result_column {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  padding: 40px 0 42px;
  margin: 20px 0 41px;
}

#isMobile .sub_result_column {
  margin: 10px 0 41px;
  padding: 24px 0 24px;
}

.sub_result_column .count {
  font-weight: 500;
}

#searchResult .pagenation {
  margin-bottom: 160px;
}

.sub_result_column li {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 24px 0;
}

#isMobile .sub_result_column li {
  flex-direction: column;
  align-items: flex-start;
  margin: 10px 0;
}

.sub_result_column .link {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.45px;
  color: #056fb8;
  text-decoration: underline;
  margin-right: 18px;
}

#isMobile .sub_result_column .link {
  font-size: 14px;
}

.sub_result_column .link_info {
  display: flex;
}

.sub_result_column .link_info div {
  font-size: 16px;
  letter-spacing: -0.4px;
  color: #666666;
}

#isMobile .sub_result_column .link_info div {
  font-size: 11px;
  margin-top: 8px;
}

.sub_result_column .link_info .bar {
  height: 16px;
  border-right: solid 1px #707070;
  margin: 0 8px;
}

.login_section_2 {
  width: 100%;
  background: linear-gradient(to top, #fafafa 70%, #ffffff 30%);
}

#isMobileHome .login_section_2 {
  padding: 0 20px;
}

.slide_section {
  width: 62.4%;
  margin: auto;
  border: solid 1px #dddddd;
  padding: 49px 20px 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
}

#isMobileHome .slide_section {
  width: 100%;
  max-width: 450px;
  padding: 15px 20px 8px;
}

#isMobileHome .isMobile_prev,
#isMobileHome .isMobile_next {
  display: none;
}

.slide_content {
  width: 100%;
  margin: 0 46px 50px;
}

#isMobileHome .slide_content {
  margin: 0 0 30px;
}

.slide_top_content {
  display: flex;
  justify-content: space-between;
}

#isMobileHome .slide_top_content {
  flex-direction: column;
}

.login_section_2 .slide_top_content .left {
  height: 340px;
  display: flex;
  flex-direction: column;
}

#isMobileHome .login_section_2 .slide_top_content .left {
  height: auto;
}

.login_section_2 .slide_top_content .right .slider_map {
  max-width: 560px;
  width: 30vw;
  min-width: 455px;
  height: 360px;
}

#isMobileHome .login_section_2 .slide_top_content .right .slider_map {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  height: 200px;
}

.slide_top_content .title {
  font-size: 30px;
  letter-spacing: -0.75px;
  text-align: left;
  margin: 21px 0 25px;
}

#isMobileHome .slide_top_content .title {
  font-size: 18px;
  text-align: left;
  margin: 16px 0 8px;
}

#isMobileHome .slide_top_content .title img {
  width: 12px;
}

.slide_top_content strong {
  font-weight: bold;
  margin-left: 11px;
}

#isMobileHome .slide_top_content .isMobile_strong {
  margin-left: 6px;
}

.address_name {
  display: flex;
  align-items: center;
}

.address_name span {
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -1.2px;
  background: linear-gradient(to top, #8eebef 50%, transparent 50%);
}

#isMobileHome .address_name span {
  font-size: 18px;
}

.admin_state {
  width: 110px;
  height: 24px;
  border-radius: 12px;
  background-color: #999999;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.35px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;
}

#isMobileHome .admin_state {
  width: 70px;
  height: 15px;
  font-size: 10px;
  -webkit-transform: scale(0.9);
}

.slide_top_content .address {
  font-size: 24px;
  line-height: 1.5;
  text-align: left;
  letter-spacing: -1.2px;
  margin: 7px 0 13px;
}

#isMobileHome .slide_top_content .address {
  font-size: 14px;
  margin: 5px 0 0;
}

#isMobileHome .slide_top_content .address div {
  display: inline-block;
  margin-right: 4px;
}

.serial_number {
  font-size: 20px;
  line-height: 1.75;
  letter-spacing: -1px;
  color: #999999;
  text-align: left;
}

#isMobileHome .serial_number {
  font-size: 12px;
}

.slide_top_content .btn_column {
  display: flex;
  margin-top: auto;
}

#isMobileHome .slide_top_content .btn_column {
  margin: 8px 0 16px;
}

.slide_top_content .set_btn,
.slide_top_content .add_btn {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  border: solid 1px #dddddd;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

#isMobileHome .slide_top_content .set_btn,
#isMobileHome .slide_top_content .add_btn {
  width: 66px;
  height: 30px;
  font-size: 12px;
}

#isMobileHome .slide_top_content .add_btn {
  width: 80px;
}

.slide_top_content .add_btn {
  width: 120px;
  margin-left: 10px;
}

.slide_top_content .set_btn img,
.slide_top_content .add_btn img {
  margin-right: 10px;
}

#isMobileHome .slide_top_content .set_btn img,
#isMobileHome .slide_top_content .add_btn img {
  width: 13px;
  margin-right: 6px;
}

.slide_pagenation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 59px;
}

#isMobileHome .slide_pagenation {
  margin-top: 24px;
}

.slide_pagenation div {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #dddddd;
}

#isMobileHome .slide_pagenation div {
  width: 4px;
  height: 4px;
}

.slide_pagenation div:not(:last-child) {
  margin-right: 20px;
}

#isMobileHome .slide_pagenation div:not(:last-child) {
  margin-right: 10px;
}

.slide_pagenation .active {
  width: 60px;
  border-radius: 4px;
  background-color: #056fb8;
}

#isMobileHome .slide_pagenation .active {
  width: 30px;
  height: 4px;
}

.day_column {
  width: 100%;
  margin-top: 20px;
}

#isMobileHome .day_column {
  padding: 0 20px;
}

.day_column .day_text {
  font-size: 24px;
  line-height: 1.5;
  letter-spacing: -1.2px;
}

#isMobileHome .day_column .day_text {
  font-size: 15px;
}

.day_column .day_text strong {
  font-weight: bold;
  margin-left: 0;
  word-break: normal;
}

.day_column .total_per {
  max-width: 500px;
  width: 26vw;
  height: 50px;
  border-radius: 25px;
  background-color: #dddddd;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#isMobileHome .day_column .total_per {
  max-width: 100%;
  width: 100%;
  height: 26px;
  margin-top: 20px;
}

.day_column .remain_per {
  position: absolute;
  top: 0;
  right: 0;
  width: 45%;
  height: 50px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -1.2px;
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day_column .total_per span {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.9px;
  color: #999999;
}

.day_column .pass_per {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 30px;
  height: 50px;
  border-radius: 25px;
  background-image: linear-gradient(to right, #1c8ee7, #056fb8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: -1.2px;
  color: #ffffff;
}

#isMobileHome .day_column .pass_per {
  min-width: 20px;
}

#isMobileHome .day_column .pass_per,
#isMobileHome .day_column .remain_per {
  height: 26px;
  font-size: 16px;
}

.day_column .pass_per span {
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.9px;
  color: #ffffff;
}

#isMobileHome .day_column .pass_per span,
#isMobileHome .day_column .total_per span {
  font-size: 12px;
}

.isMobil_recent_notice {
  width: 100%;
}

#isMobileHome .isMobil_recent_notice {
  padding: 0 20px;
}

.recent_notice {
  width: 62.4%;
  background-color: #ffffff;
  border: solid 1px #dddddd;
  margin: 20px auto 0;
  padding: 32px 40px 44px 53px;
}

#isMobileHome .recent_notice {
  width: 100%;
  max-width: 450px;
  padding: 20px 12px 20px 20px;
}

.recent_notice .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recent_notice .header h2 {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: -0.6px;
}

#isMobileHome .recent_notice .header h2 {
  font-size: 17px;
}

.recent_notice .go_btn {
  margin: 0;
}

#isMobileHome .recent_notice .go_btn {
  display: none;
}

.recent_notice_lists {
  width: 100%;
  height: 180px;
  overflow-y: auto;
  margin-top: 38px;
}

#isMobileHome .recent_notice_lists {
  height: 125px;
  margin-top: 24px;
}

.recent_notice_lists .list_column {
  display: flex;
  align-items: center;
}

.recent_notice_lists .list_dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #dddddd;
  margin-right: 18px;
}

#isMobileHome .recent_notice_lists .list_dot {
  width: 8px;
  height: 8px;
}

.recent_notice_lists .active .list_dot {
  background-color: #056fb8;
}

.recent_notice_lists .list_desc {
  font-size: 18px;

  letter-spacing: -0.45px;
  font-weight: normal;
  color: #cccccc;
}

#isMobileHome .recent_notice_lists .list_desc {
  font-size: 13px;
}

.recent_notice_lists .active .list_desc {
  font-weight: 500;
  color: #000000;
}

.recent_notice_lists .bar {
  width: 2px;
  height: 18px;
  background-color: #dddddd;
  margin: 3px 0 3px 5px;
}

#isMobileHome .recent_notice_lists .bar {
  height: 13px;
  margin: 1px 0 1px 3px;
}

.recent_notice_lists .bar.active {
  background-color: #056fb8;
}

/* 스크롤바 */
.recent_notice ::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

#isMobileHome .recent_notice ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.recent_notice ::-webkit-scrollbar-track {
  background-color: #dddddd;
}
.recent_notice ::-webkit-scrollbar-thumb {
  background: #999999;
}
.recent_notice ::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

#isMobileHome .search.btn {
  width: 18px;
}

#isMobileHome .isMobile_service_column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 16px;
}

.result_bg {
  width: 100%;
  height: 100%;
  position: fixed;
}

.service_title_inline_block {
  display: inline-block;
  padding: 0px !important;
}

.service_title_float_right {
  float: right;
  line-height: 50px;
}

.service_title_float_right .go_btn {
  color: #797979;
  text-decoration: underline;
  text-decoration-color: #c6c6c6;
  text-underline-position: under;
}

.row-link {
  width: 100%;
  margin: 10px 0;
}

.row-link img {
  width: 100%;
}

.ps-10 {
  padding-left: 10px;
}

.pe-10 {
  padding-right: 10px;
}

.pe-15 {
  padding-right: 15px;
}

.px-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.mb-30 {
  margin-bottom: 30px;
}

.top-white-space {
  margin-top: 50px;
}

.bottom-white-space {
  margin-bottom: 100px;
}

.service_row {
  width: 100%;
}

.service_row iframe {
  width: 100%;
}

.service_row img {
  width: 100%;
}

.dp-flex {
  display: flex;
}